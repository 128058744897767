import { useNavigate, useSearchParams } from "react-router-dom";
import { Stack, TextField, Box, Typography, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { ErrorMsg } from "components";
import { forgotSchema } from "./testSchema";
import { ApiCaller } from "utils";
import { useToaster } from "hooks";
import { useState } from "react";

export default function ForgotForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const triggerToaster = useToaster();
  const isVerification = searchParams.get("title") === "Verify email";

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(forgotSchema),
  });

  const handleSendOtp = () => {
    setLoading(true);
    const body = {
      email: searchParams.get("email") || getValues("email"),
      name: searchParams.get("name") || "",
      isForgotPassword: isVerification ? false : true,
    };
    ApiCaller("/user/otp/email", "post", body).then((response) => {
      if (response?.success) {
        triggerToaster(response.data?.message, "success");
        const { data } = response;
        if (isVerification) {
          navigate(
            `/verifyOtp?email=${data.email}&otpId=${data._id}&userId=${
              data.userId
            }&title=${searchParams.get("title")}`
          );
        } else {
          navigate(
            `/resetPassword?email=${data.email}&otpId=${data._id}&userId=${
              data.userId
            }&title=${searchParams.get("title")}`
          );
        }
        setLoading(false);
      } else {
        triggerToaster(response.data || "Invalid data", "error");
        setLoading(false);
      }
    });
  };

  const handleLogin = () => {
    navigate("/login");
  };

  return (
    <Box as="form" onSubmit={handleSubmit(handleSendOtp)}>
      <Stack spacing={3} mt={2} mb={4}>
        <Box>
          <Controller
            control={control}
            name="email"
            defaultValue={searchParams.get("email") || ""}
            render={({ field: { onChange, value } }) => (
              <TextField
                sx={{ width: "100%" }}
                label="Email *"
                onChange={onChange}
                value={value}
              />
            )}
          />
          <ErrorMsg msg={errors?.email?.message} />
        </Box>
      </Stack>
      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={loading}
        disabled={!searchParams.get("email") && !isDirty}
      >
        Send Otp
      </LoadingButton>
      <Typography variant="body2" sx={{ mt: 1 }}>
        Already have an account?
        <Button
          variant="text"
          sx={{ textDecoration: "underline" }}
          onClick={handleLogin}
        >
          Login
        </Button>
      </Typography>
    </Box>
  );
}
