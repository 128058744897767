import { useState } from "react";
import { Info, OpenWith } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import {
  Typography,
  Box,
  useTheme,
  Grid,
  IconButton,
  TableContainer,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHead,
} from "@mui/material";

import { CustomeModal, Tooltip } from "components";
import { moneyFormat } from "utils";

const ViewOnly = ({
  label = "-",
  value = "-",
  breakUpData,
  isPopUpView = false,
  isModalView = false,
  headers,
  columnsKeys = [
    "createdAt",
    "paidAmount",
    "discountAmount",
    "paymentMode",
    "receivedFrom",
  ],
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  const open = Boolean(anchorEl);

  const columns = [
    t("submissionDate"),
    t("paidAmount"),
    t("discountAmount"),
    t("paymentMode"),
    t("receivedFrom"),
  ];

  const headersData = headers ? headers : columns;
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const theme = useTheme();

  return (
    <Box>
      <Typography sx={{ fontSize: 14, color: theme.palette.grey[600] }}>
        {label}
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Typography sx={{ fontWeight: "bold", fontSize: 15 }}>
          {value?.length ? value : "-"}
        </Typography>
        {isPopUpView && (
          <IconButton
            aria-label="information"
            onMouseEnter={handlePopoverOpen}
            sx={{ marginTop: -1 }}
          >
            <Info />
          </IconButton>
        )}
        {isModalView && (
          <IconButton
            aria-label="information"
            onClick={() => setIsModalOpen(true)}
            sx={{ marginTop: -1 }}
          >
            <OpenWith />
          </IconButton>
        )}
      </Box>
      <Tooltip
        open={open}
        anchorEl={anchorEl}
        handlePopoverClose={handlePopoverClose}
      >
        {breakUpData?.map((row) => (
          <Grid
            container
            spacing={1}
            width={250}
            p={1}
            borderBottom={`1px solid ${theme.palette.grey[1000]}`}
          >
            <Grid item xs={8}>
              {row?.key}
            </Grid>
            <Grid item xs={4} fontWeight={"bold"}>
              {moneyFormat(row?.value)}
            </Grid>
          </Grid>
        ))}
      </Tooltip>
      <CustomeModal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <TableContainer
          component={Paper}
          style={{ maxHeight: 400, overflow: "auto" }}
        >
          <Table aria-label="simple table" stickyHeader>
            <TableHead>
              <TableRow>
                {headersData?.map((header) => (
                  <TableCell key={header}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {breakUpData?.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {columnsKeys?.map((column) => (
                    <TableCell align="left">
                      <Typography
                        sx={{ fontSize: 14, color: theme.palette.grey[600] }}
                      >
                        {row[column]}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CustomeModal>
    </Box>
  );
};

export default ViewOnly;
