import { useSelector } from "react-redux";
import { useToaster } from "./useToaster";
import AWS from "aws-sdk";
import { useState } from "react";

export const useUploadFile = () => {
  const triggerToaster = useToaster();
  const [progress, setProgress] = useState(0);
  const [uploadedFileUrl, setUploadedFileUrl] = useState("");

  const currentuser = useSelector((state) => state.currentUser);

  const allowedMaxSize = 1; // 1MB
  const {
    REACT_APP_S3_BUCKET,
    REACT_APP_REGION,
    REACT_APP_ACCESS_KEY,
    REACT_APP_SECRET_ACCESS_KEY,
    REACT_APP_AWS_BUCKET_URL,
  } = process.env;

  // Configure AWS once
  AWS.config.update({
    accessKeyId: REACT_APP_ACCESS_KEY,
    secretAccessKey: REACT_APP_SECRET_ACCESS_KEY,
  });

  const s3 = new AWS.S3({
    params: { Bucket: REACT_APP_S3_BUCKET },
    region: REACT_APP_REGION,
  });

  const handleUploadFile = async (file, folder = "profile") => {
    if (!file) {
      triggerToaster("Please select a file to upload", "error");
      return;
    }

    const fileSize = file.size / 1024 / 1024; // Convert to MB
    if (fileSize.toFixed(2) > allowedMaxSize) {
      triggerToaster("Allowed max file size is 1MB", "error");
      return;
    }

    const filePathName = `${currentuser?.data?.instituteId}/${folder}/${file.name}`;
    const params = {
      Bucket: REACT_APP_S3_BUCKET,
      Key: filePathName,
      Body: file,
    };

    try {
      const upload = s3.upload(params);

      upload.on("httpUploadProgress", (evt) => {
        setProgress(Math.round((evt.loaded / evt.total) * 100));
      });

      const data = await upload.promise();
      if (data) {
        const fileUrl = `${REACT_APP_AWS_BUCKET_URL}/${filePathName}`;
        setUploadedFileUrl(fileUrl);
        triggerToaster("File uploaded successfully.", "success");
      }
    } catch (err) {
      triggerToaster(JSON.stringify(err), "error");
    }
  };

  return { handleUploadFile, uploadedFileUrl, progress };
};
