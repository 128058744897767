import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function MultiSelectDropdown({
  list = [],
  style,
  getSelectedItem,
  label = "Select option",
  placeholder = "Select options",
  displayKey = "name",
}) {
  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={list}
      disableCloseOnSelect
      onChange={(_, value) => getSelectedItem(value)}
      getOptionLabel={(option) => option[displayKey]}
      renderOption={(props, option, { selected }) => {
        const { key, ...optionProps } = props;
        return (
          <li key={key} {...optionProps}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option[displayKey]}
          </li>
        );
      }}
      style={style}
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder={placeholder} />
      )}
    />
  );
}
