import { createSlice } from "@reduxjs/toolkit";

export const currentUser = createSlice({
  name: "currentUser",
  initialState: {
    value: false,
    data: {},
  },
  reducers: {
    setCurrentUserAction: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setCurrentUserAction } = currentUser.actions;

export default currentUser.reducer;
