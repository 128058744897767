import { Box, useTheme, Typography, Divider, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import {
  ApiCaller,
  formatDate,
  getMonthYear,
  getSlicedArray,
  moneyFormat,
} from "utils";
import { BarChart } from "@mui/x-charts/BarChart";
import { useSearchParams } from "react-router-dom";
import { DEFAULT_USER_PROFILE } from "utils/constants";
import { ViewOnly } from "components";
import {
  getFormattedAdditionalPaidBreakUp,
  getFormattedSalaryBreakUp,
} from "pages/AddSalary/makeData";
import useResponsive from "hooks/useResponsive";

const StaffDetail = () => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const staffId = searchParams.get("staffId");
  const [selectedStaff, setSelectedstaff] = useState({});
  const [salaryDetails, setSalaryDetails] = useState({});
  const mdUp = useResponsive("up", "md");

  useEffect(() => {
    ApiCaller(`/staffs?key=_id&value=${staffId}`, "get").then((response) => {
      if (response.success) {
        const staffData = response?.data?.[0];
        setSelectedstaff(staffData);
      }
    });

    const date = new Date();
    const fromDate = date.getTime() - 365 * 24 * 60 * 60 * 1000; // To fetch last one year details

    ApiCaller(
      `/get-staff-salary-details/${staffId}?fromDate=${fromDate}&toDate=${new Date()}`,
      "get"
    ).then((response) => {
      if (response.success) {
        setSalaryDetails(response?.data);
      }
    });
  }, []);

  const formattedRemainingSalary = salaryDetails.salaries?.map((item) => {
    return item.remainingSalary;
  });
  const formattedPaidSalary = salaryDetails.salaries?.map((item) => {
    return item.paid;
  });

  const formattedDueSalary = salaryDetails?.salaries?.map((item) => {
    const calculatedDue = item?.totalDueBreakup?.reduce(
      (total, item) => total + item?.value,
      0
    );
    return calculatedDue;
  });

  const months = salaryDetails?.salaries?.map((item) => {
    const fromMonth =
      getMonthYear(item?.toDate) !== getMonthYear(item?.fromDate)
        ? getMonthYear(item?.toDate)
        : "";

    return `${getMonthYear(item?.fromDate)} ${
      fromMonth ? "- " : ""
    }${fromMonth}`;
  });

  return (
    <>
      <Typography variant="h3" style={{ marginLeft: 12 }}>
        Teacher Salary Graph
      </Typography>
      <Box
        style={{
          display: "flex",
          flexDirection: mdUp ? "row" : "column-reverse",
        }}
      >
        <Box
          style={{
            width: mdUp ? "75%" : "100%",
            padding: 10,
            backgroundColor: theme.palette.common.white,
            borderRadius: 15,
            marginRight: 10,
            border: `1px solid ${theme.palette.grey[300]}`,
          }}
        >
          <BarChart
            width={mdUp ? window.innerWidth / 1.8 : window.innerWidth}
            height={300}
            series={[
              {
                data: getSlicedArray(formattedDueSalary ?? []),
                label: "Due Salary",
                id: "DueSalaryId",
              },

              {
                data: getSlicedArray(formattedPaidSalary ?? []),
                label: "Paid Salary",
                id: "PaidSalaryId",
              },
              {
                data: getSlicedArray(formattedRemainingSalary ?? []),
                label: "Remaining Salary",
                id: "RemainingSalaryId",
              },
            ]}
            xAxis={[{ data: getSlicedArray(months ?? []), scaleType: "band" }]}
            colors={["#1890FF", "#8cbcac", "#ff575c"]}
          />
          <Box>
            <Grid
              container
              direction={{ xs: "column", md: "row" }}
              spacing={2}
              mt={0}
              ml={mdUp ? 0 : 2}
              sx={{ width: "100%" }}
            >
              <Grid item xs={3}>
                <ViewOnly
                  label={"Salary paid history"}
                  value={moneyFormat(salaryDetails?.totalPaidSalary)}
                  isModalView={true}
                  breakUpData={getFormattedSalaryBreakUp(
                    salaryDetails?.salaries
                  )}
                  headers={["Date", "Amount", "Deductions", "Additional/Bonus"]}
                  columnsKeys={[
                    "createdAt",
                    "paidAmount",
                    "deduction",
                    "bonus",
                  ]}
                />
              </Grid>
              <Grid item xs={3}>
                <ViewOnly
                  label={"Additional/Bonus history"}
                  value={moneyFormat(salaryDetails?.totalBonus)}
                  breakUpData={getFormattedAdditionalPaidBreakUp(
                    salaryDetails?.addtionalBonusBreakUp
                  )}
                  headers={["Reason", "Amount"]}
                  columnsKeys={["reason", "amount"]}
                  isModalView={true}
                />
              </Grid>
              <Grid item xs={3}>
                <ViewOnly
                  label={"Deducation"}
                  value={moneyFormat(salaryDetails?.totalDeduction)}
                  breakUpData={getFormattedAdditionalPaidBreakUp(
                    salaryDetails?.totalDeductionBreakUp
                  )}
                  headers={["Reason", "Amount"]}
                  columnsKeys={["reason", "amount"]}
                  isModalView={true}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box
          style={{
            width: mdUp ? "25%" : "100%",
            padding: 10,
            backgroundColor: theme.palette.common.white,
            borderRadius: 15,
            marginRight: 10,
            marginBottom: mdUp ? 0 : 12,
            border: `1px solid ${theme.palette.grey[300]}`,
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {selectedStaff?.profileUrl ? (
              <img
                style={{
                  height: 100,
                  width: 100,
                  borderRadius: 50,
                  marginTop: 10,
                }}
                alt="Staff profile"
                src={selectedStaff?.profileUrl}
              ></img>
            ) : (
              <img
                style={{
                  height: 100,
                  width: 100,
                  borderRadius: 50,
                  marginTop: 10,
                }}
                alt="Staff profile"
                src={DEFAULT_USER_PROFILE}
              />
            )}
          </Box>
          <Typography
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 10,
              fontWeight: "bold",
            }}
          >
            {selectedStaff?.name}
          </Typography>
          <Typography
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {selectedStaff?.staffNo}
          </Typography>
          <Divider
            style={{
              marginTop: 10,
              marginBottom: 10,
            }}
          />
          <Box style={{ paddingLeft: 10 }}>
            <Typography style={{ fontSize: 14 }}>
              <b>Joining Date: </b>
              {formatDate(selectedStaff?.joiningDate)}
            </Typography>
            <Typography style={{ fontSize: 14 }}>
              <b>Salary: </b>
              {selectedStaff?.salaryAmount}
            </Typography>
            <Typography style={{ fontSize: 14 }}>
              <b>Adhar No: </b>
              {selectedStaff?.adharNo}
            </Typography>
          </Box>
          <Divider
            style={{
              marginTop: 10,
              marginBottom: 10,
            }}
          />
          <Box style={{ paddingLeft: 10 }}>
            <Typography style={{ fontSize: 14 }}>
              <b>Mobile No: </b>
              {selectedStaff?.mobile}
            </Typography>
            <Typography style={{ fontSize: 14 }}>
              <b>Email: </b>
              {selectedStaff?.email}
            </Typography>
            <Typography style={{ fontSize: 14 }}>
              <b>Address: </b>
              {selectedStaff?.address}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default StaffDetail;
