import { createSlice } from "@reduxjs/toolkit";

export const snackbar = createSlice({
  name: "snackbar",
  initialState: { value: false, message: "", variant: "" },
  reducers: {
    toasterAction: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { toasterAction } = snackbar.actions;
export default snackbar.reducer;
