import { takeEvery, put } from "redux-saga/effects";
import { verifyUserLoginAction } from "./authentication";

function* cleanLoginData() {
  console.log("cleanLoginData called");
  // yield put(setLoginDataAction({ data: "SUNIL" }));
}

export default function* rootSaga() {
  yield takeEvery(verifyUserLoginAction, cleanLoginData);
}
