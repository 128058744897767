import {
  AccordionSummary,
  AccordionDetails,
  Accordion,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PropTypes from "prop-types";

export default function CollapseView({ summary, details }) {
  const theme = useTheme();
  return (
    <Accordion
      sx={{
        border: `1px solid ${theme.palette.grey[200]}`,
        backgroundColor: theme.palette.primary.lightBlue,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{ alignItems: "center" }}
      >
        {summary}
      </AccordionSummary>
      <AccordionDetails>{details}</AccordionDetails>
    </Accordion>
  );
}

CollapseView.propTypes = {
  summary: PropTypes.element.isRequired,
  details: PropTypes.element,
};
