import * as Yup from "yup";

const addInstituteSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .test(
      "len",
      "Name should be greater than 5 letters",
      (val) => val.length >= 6
    ),
  address: Yup.string()
    .required("Address is required")
    .test(
      "len",
      "Address should be greater than 10 letters",
      (val) => val.length >= 10
    ),
  city: Yup.string()
    .required("City is required")
    .test(
      "len",
      "City should be greater than 3 letters",
      (val) => val.length >= 3
    ),
  state: Yup.string().required("State is required"),
  country: Yup.string().required("Country is required"),
  pinCode: Yup.string()
    .required("Pin Code is required")
    .typeError("Invalid Pin Code")
    .test(
      "len",
      "Pin Code should be greater than 5 letters",
      (val) => val.length >= 6
    ),
  schoolMobile: Yup.string()
    .required("School mobile is required")
    .matches(/^[0-9]{10}$/, "School mobile must be exactly 10 digits"),
  schoolEmail: Yup.string()
    .required("School email is required")
    .email("Must be a valid email"),
  schoolRegistrationNumber: Yup.string(),
});

export { addInstituteSchema };
