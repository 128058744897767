import React from "react";
import { Typography } from "@mui/material";
import { CustomeModal, GridSkeleton } from "components";
import useResponsive from "hooks/useResponsive";
import { LoadingButton } from "@mui/lab";
import { moneyFormat } from "utils";

const SellSchoolKitConfirmation = ({
  open,
  onClose,
  loading,
  onSubmit,
  itemDetails,
  sellDetails,
  studentDetails,
}) => {
  const mdUp = useResponsive("up", "md");
  const { totalQuantity, totalDiscount, totalAmount } = sellDetails;
  return (
    <CustomeModal
      open={open}
      onClose={onClose}
      style={{ width: mdUp ? "40%" : "80%" }}
    >
      <Typography sx={{ fontWeight: "bold", fontSize: 16, marginBottom: 2 }}>
        Sell school kit confirmation
      </Typography>
      <Typography sx={{ fontSize: 16, marginBottom: 1 }}>
        Do you want to submit sell?
      </Typography>
      <GridSkeleton label={"Student name"} value={studentDetails?.name || ""} />
      <GridSkeleton label={"Roll No"} value={studentDetails?.rollNo || ""} />
      <GridSkeleton label={"Total quantity"} value={+totalQuantity || 0} />
      <GridSkeleton
        label={"Total discount"}
        value={moneyFormat(+totalDiscount || 0)}
      />
      <GridSkeleton
        label={"Total amount"}
        value={moneyFormat(+totalAmount || 0)}
      />
      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={loading}
        sx={{ marginTop: 2 }}
        onClick={onSubmit}
      >
        Submit
      </LoadingButton>
    </CustomeModal>
  );
};

export default SellSchoolKitConfirmation;
