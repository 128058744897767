import {
  TextField,
  Typography,
  Autocomplete,
  Box,
  useTheme,
} from "@mui/material";
import { useDelay } from "hooks";
import { useCallback, useEffect, useState } from "react";
import { ApiCaller, moneyFormat } from "utils";
import { useTranslation } from "react-i18next";

export default function AutoCompleteSearch({
  label,
  passSelectedValue,
  sx,
  searchType,
}) {
  const [search, setSearchValue] = useState("");
  const [listOption, setListOption] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const { t } = useTranslation();

  const newLabel = label ? label : t("searchHere");
  const theme = useTheme();
  const handleSearchChange = useCallback((event) => {
    setSearchValue(event.target.value);
  }, []);

  const getRenderValue = (listData) => {
    switch (searchType) {
      case "student":
        return `${listData.name} (${listData?.rollNo})`;
      case "staff":
        return `${listData.name} (${listData?.staffNo})`;
      case "schoolKit":
        return `${listData.name} (Avl quantity: ${
          listData?.availableQuantity || "0"
        }, Price: ${moneyFormat(listData?.amount)})`;
      default:
        return `${listData.name} (${listData?.rollNo})`;
    }
  };

  const getSearchApiEndPoint = (searchedValue) => {
    switch (searchType) {
      case "student":
        return `/students?pageNo=1&size=10&search=${searchedValue}`;
      case "staff":
        return `/staffs?pageNo=1&size=10&search=${searchedValue}`;
      case "schoolKit":
        return `/school-kit?pageNo=1&size=10&search=${searchedValue}`;
      default:
        return `/students?pageNo=1&size=10&search=${searchedValue}`;
    }
  };

  const searchedValue = useDelay(search, 500);
  useEffect(() => {
    if (searchedValue) {
      ApiCaller(getSearchApiEndPoint(searchedValue), "get").then((response) => {
        if (response.success) {
          setListOption(response?.data);
        }
      });
    }
  }, [searchedValue]);

  useEffect(() => {
    const selectedData = listOption?.find(
      (option) => getRenderValue(option) === inputValue
    );
    passSelectedValue(selectedData);
  }, [inputValue, listOption]);

  return (
    <Autocomplete
      sx={sx}
      freeSolo
      id="autocomplete"
      disableClearable
      options={listOption.map((option) => getRenderValue(option))}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={handleSearchChange}
          label={newLabel}
          InputProps={{
            ...params.InputProps,
            type: "search",
          }}
        />
      )}
      renderOption={(props, option) => (
        <Box
          sx={{
            borderBottom: `1px solid ${theme.palette.grey[100]}`,
          }}
        >
          <Typography {...props}>{option}</Typography>
        </Box>
      )}
    />
  );
}
