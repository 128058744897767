import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { ApiCaller, validateError } from "utils";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  CustomButton,
  CustomLoader,
  DatePicker,
  Dropdown,
  ErrorMsg,
} from "components";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { addStudentSchema } from "pages/AddStudent/testSchema";
import { DEFAULT_USER_PROFILE } from "utils/constants";
import useResponsive from "hooks/useResponsive";
import { useToaster } from "hooks";

const StudentAttendence = () => {
  const [students, setStudents] = useState([]);
  const [attendance, setAttendance] = useState([]); // State to store attendance
  const [loading, setLoading] = useState(false);
  const [attendanceLoading, setAttendanceLoading] = useState(false);
  const studentLists = students?.data || [];
  const userData = useSelector((state) => state?.loginData);
  const triggerToaster = useToaster();

  const { t } = useTranslation();
  const mdUp = useResponsive("up", "md");
  const theme = useTheme();

  const masterData = useSelector((state) => state?.masterData);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const batchList = masterData?.data?.batches || [];

  const {
    control,
    watch,
    getValues,
    resetField,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(addStudentSchema),
  });

  const selectedBatch = watch("batch");
  const selectedBatchData = batchList?.find(
    (batch) => batch.name === selectedBatch
  );

  useEffect(() => {
    if (selectedBatch) {
      setLoading(true);

      ApiCaller(
        `/students?batchId=${selectedBatchData?._id}&pageNo=1&size=500`,
        "get"
      ).then((response) => {
        if (response.success) {
          setStudents(response);
          const formattedRes = response.data.map((student) => ({
            studentId: student._id,
            status: "", // Initialize attendance with no status
          }));
          setAttendance(formattedRes);
          fetchPreviouslySavedAttendances(selectedBatchData?._id, formattedRes);
          setLoading(false);
        }
      });
    }
  }, [selectedBatch, batchList, selectedBatchData?._id, watch("date")]);

  const findStatus = (previouslySavedAttendance, studentId) => {
    const matchedAttendance = previouslySavedAttendance.find(
      (item) => item?.studentId?._id === studentId
    );
    return matchedAttendance?.status;
  };

  const fetchPreviouslySavedAttendances = (
    batchId,
    formattedStudentAttendance
  ) => {
    const date = new Date(watch("date"))?.toISOString();
    ApiCaller(
      `/student-attendance?batchId=${batchId}&date=${date}`,
      "get"
    ).then((response) => {
      if (response.success) {
        const attendanceClone = [...formattedStudentAttendance];
        const newAttendance = attendanceClone.map((item, index) => {
          return {
            studentId: item.studentId,
            status: findStatus(response?.data, item.studentId),
          };
        });
        setAttendance(newAttendance);
      }
    });
  };

  // Function to handle attendance status update
  const handleAttendance = (studentId, status) => {
    setAttendance((prevAttendance) =>
      prevAttendance.map((item) =>
        item.studentId === studentId ? { ...item, status } : item
      )
    );
  };
  const handleReset = () => {
    resetField("batch", "");
    setAttendance(
      students.data.map((students) => ({
        studentId: students._id,
        status: "", // Reset attendance
      }))
    );
  };

  const handleSubmit = () => {
    const formattedData = attendance?.filter((item) => !!item?.status);
    setAttendanceLoading(true);
    try {
      ApiCaller("/student-attendance", "post", {
        attendances: formattedData,
        batchId: selectedBatchData?._id,
        date: getValues("date"),
      }).then((response) => {
        if (response?.success) {
          triggerToaster("Attendance records added successfully.", "success");
        } else {
          triggerToaster(validateError(response?.data), "error");
        }
        setAttendanceLoading(false);
      });
    } catch (error) {
      console.log("error", error);
      triggerToaster("Something went wrong!", "error");
      setAttendanceLoading(false);
    }
  };

  return (
    <Box sx={{ padding: "16px", backgroundColor: "#fff", borderRadius: 2 }}>
      <Typography variant="h4" sx={{ mb: 2 }}>
        Student Attendance
      </Typography>

      {/* Batch Dropdown */}
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={mdUp ? 1.3 : 12}>
          <Typography variant="h6" style={{ width: 120 }}>
            Select Batch:
          </Typography>
        </Grid>
        <Grid item xs={mdUp ? 2 : 12} md={2}>
          <Controller
            control={control}
            name="batch"
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <Dropdown
                itemList={batchList}
                label={t("batch")}
                onChange={onChange}
                value={value}
                valueKey={"name"}
                displayKey={"name"}
                type="arrayOfObjects"
                style={{ marginLeft: mdUp ? 4 : 0 }}
              />
            )}
          />
          <ErrorMsg msg={errors?.batch?.message} />
        </Grid>
        <Grid item xs={mdUp ? 2 : 12} md={2}>
          <Controller
            control={control}
            name="date"
            defaultValue={new Date()}
            render={({ field: { onChange, value } }) => (
              <DatePicker
                label="Select Date"
                value={value}
                style={{ marginTop: !mdUp ? 10 : 0 }}
                onChange={onChange}
                disabled={userData?.data?.role === "admin" ? false : true}
                renderInput={(params) => <TextField {...params} />}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={mdUp ? 0.9 : 12}
          md={mdUp ? 0 : 2}
          marginLeft={mdUp ? 1 : 0}
          style={{ height: "45px" }}
        >
          <CustomButton
            variant="contained"
            fullWidth={mdUp ? false : true}
            onClick={handleSubmit}
            sx={{ mb: { xs: 2, md: 2 } }}
            title={"Submit"}
            size="medium"
            disabled={!selectedBatch}
            loading={attendanceLoading}
          />
        </Grid>
        <Grid item xs={mdUp ? 0.9 : 12} md={mdUp ? 0 : 2}>
          <Button
            variant="contained"
            color="error"
            fullWidth={mdUp ? false : true}
            onClick={handleReset}
            disabled={!selectedBatch}
            style={{ marginLeft: mdUp ? 8 : 0 }}
          >
            Reset
          </Button>
        </Grid>
      </Grid>

      {/* Students Grid */}
      {selectedBatch ? (
        <Grid container spacing={2} sx={{ mt: 3 }}>
          {studentLists.map((row) => {
            const studentAttendance = attendance.find(
              (a) => a.studentId === row._id
            );

            return (
              <Grid item xs={12} sm={6} md={4} lg={2.3} key={row._id}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "10px",
                    marginLeft: mdUp ? "0px" : "20px",
                    border: `1px solid ${theme.palette.grey[200]}`,
                    borderRadius: "10%",
                    height: "100%",
                    width: mdUp ? "100%" : "90%",
                    boxShadow: theme.customShadows.z8,
                  }}
                >
                  <Avatar
                    sx={{
                      width: mdUp ? "100px" : "185px",
                      height: mdUp ? "100px" : "185px",
                      border: "2px solid #fff",
                      mb: 1,
                    }}
                    src={row.profileUrl || DEFAULT_USER_PROFILE}
                    alt="profile"
                  />
                  <Typography>{row.name}</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      gap: 2,
                      mt: 1,
                    }}
                  >
                    <Avatar
                      sx={{
                        backgroundColor:
                          studentAttendance?.status === "A" ? "red" : "#fff",
                      }}
                    >
                      <Button
                        variant="text"
                        onClick={() => handleAttendance(row._id, "A")}
                        sx={{
                          color:
                            studentAttendance?.status === "A"
                              ? "#fff"
                              : "black",
                        }}
                      >
                        A
                      </Button>
                    </Avatar>
                    <Avatar
                      sx={{
                        backgroundColor:
                          studentAttendance?.status === "P" ? "green" : "#fff",
                      }}
                    >
                      <Button
                        variant="text"
                        onClick={() => handleAttendance(row._id, "P")}
                        sx={{
                          color:
                            studentAttendance?.status === "P"
                              ? "#fff"
                              : "black",
                        }}
                      >
                        P
                      </Button>
                    </Avatar>
                    <Avatar
                      sx={{
                        backgroundColor:
                          studentAttendance?.status === "L"
                            ? "#FFC72C"
                            : "#fff",
                      }}
                    >
                      <Button
                        variant="text"
                        onClick={() => handleAttendance(row._id, "L")}
                        sx={{
                          color:
                            studentAttendance?.status === "L"
                              ? "#fff"
                              : "black",
                        }}
                      >
                        L
                      </Button>
                    </Avatar>
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Box>
          {loading ? (
            <CustomLoader loading={loading} rest={{ marginTop: 30 }} />
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 400,
              }}
            >
              <Box>
                <img
                  src="/assets/images/study.png"
                  alt="institite"
                  width={"100%"}
                  style={{ marginTop: 50 }}
                />
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default StudentAttendence;
