import { Helmet } from "react-helmet-async";
import { Box, Container, Typography } from "@mui/material";
import LoginForm from "./LoginForm";

import { StyledContent, StyledRoot, ImageView, Logo } from "components";

export default function LoginPage() {
  return (
    <>
      <Helmet>
        <title> Login | GurukulGuru.com </title>
      </Helmet>

      <StyledRoot>
        <ImageView title={"Hi, Welcome Back"} />
        <Container sx={{ padding: 0 }}>
          <StyledContent>
            <Box
              display={"flex"}
              textAlign={"center"}
              justifyContent={"center"}
              marginTop={-4}
              marginBottom={5}
              marginRight={4}
            >
              <Logo height={100} width={100} />
            </Box>
            <Typography variant="h4" gutterBottom>
              Sign in to Gurukul Guru
            </Typography>
            <LoginForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
