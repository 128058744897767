import { Helmet } from "react-helmet-async";
import { Container, Typography } from "@mui/material";
import { ImageView, StyledRoot, StyledContent } from "components";
import ResetPasswordForm from "./ResetPasswordForm";

export default function ResetPassword() {
  return (
    <>
      <Helmet>
        <title> Reset Password | GurukulGuru.com </title>
      </Helmet>
      <StyledRoot>
        <ImageView title={"Hi, Welcome"} />
        <Container sx={{ padding: 0 }}>
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              Reset your new password
            </Typography>
            <ResetPasswordForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
