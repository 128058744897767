import { Helmet } from "react-helmet-async";
import { Container, Typography } from "@mui/material";
import RegisterForm from "./RegisterForm";
import { useDispatch } from "react-redux";
import { toasterAction } from "redux/snackbar";
import { useToaster } from "hooks";
import { setLoginDataAction } from "redux/authentication";
import { useNavigate } from "react-router-dom";
import { ImageView, StyledRoot, StyledContent } from "components";

export default function Register() {
  const triggerToaster = useToaster();
  const dispatch = useDispatch();

  return (
    <>
      <Helmet>
        <title> Register | GurukulGuru.com </title>
      </Helmet>

      <StyledRoot>
        <ImageView title={"Hi, Welcome"} />
        <Container sx={{ padding: 0 }}>
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              Sign Up to Gurukul Guru
            </Typography>
            <RegisterForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
