import { useNavigate, useSearchParams } from "react-router-dom";
import { Stack, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useState } from "react";
import { useSelector } from "react-redux";

import { ErrorMsg } from "components";
import { VerifyOtpSchema } from "./testSchema";
import { ApiCaller } from "utils";
import { useToaster } from "hooks";

export default function VerifyOtpForm() {
  const navigate = useNavigate();
  const triggerToaster = useToaster();
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const userData = useSelector((state) => state?.loginData);

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(VerifyOtpSchema),
  });

  const handleVerifyOtp = () => {
    setLoading(true);
    const body = {
      email: searchParams.get("email"),
      id: searchParams.get("otpId"),
      otp: +getValues("otp"),
      userId: searchParams.get("userId"),
    };
    ApiCaller("/user/otp/email/verify", "post", body).then((response) => {
      if (response?.success) {
        triggerToaster(response.data, "success");
        if (searchParams.get("title") === "Verify email") {
          if (userData?.data?.role === "admin") {
            navigate("/dashboard/addInstitute");
          } else {
            navigate("/dashboard/linkInstitute");
          }
        }
      } else {
        triggerToaster(response.data || "Invalid data", "error");
      }
      setLoading(false);
    });
  };

  return (
    <Box as="form" onSubmit={handleSubmit(handleVerifyOtp)}>
      <Stack spacing={3} pb={4} pt={2}>
        <Box>
          <Controller
            control={control}
            name="otp"
            render={({ field: { onChange, value } }) => (
              <MuiOtpInput value={value} onChange={onChange} />
            )}
          />
          <ErrorMsg msg={errors?.otp?.message} />
        </Box>
      </Stack>
      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        disabled={!isDirty}
        loading={loading}
      >
        Verify Otp
      </LoadingButton>
    </Box>
  );
}
