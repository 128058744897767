import React from "react";
import { Typography, } from "@mui/material";
import { CustomeModal, GridSkeleton } from "components";
import useResponsive from "hooks/useResponsive";
import { LoadingButton } from "@mui/lab";
import { formatDate, } from "utils";
import { useTranslation } from "react-i18next";


const BatchConfirmation = ({
  open,
  onClose,
  loading,
  onSubmit,
  batchDetails,
}) => {
  const mdUp = useResponsive("up", "md");
  const { t } = useTranslation();
  const  {name, startDate, endDate, batchTeacher, defaultFee} =
  batchDetails;

  return (
    <CustomeModal
      open={open}
      onClose={onClose}
      style={{ width: mdUp ? "40%" : "80%" }}
    >
      <Typography sx={{ fontWeight: "bold", fontSize: 16, marginBottom: 2 }}>
        {t("batchConfirmation")}
      </Typography>
      <Typography sx={{ fontSize: 16, marginBottom: 1 }}>
       {t("doYouWantToAddBatch")}
      </Typography>
      <GridSkeleton label={t("batchName")} value={name} />
      <GridSkeleton label={t("batchTeacher")} value={batchTeacher} />
       <GridSkeleton label={t("startDate")} value={formatDate(startDate)} />
      <GridSkeleton label={t("endDate")} value={formatDate(endDate)} />
       <GridSkeleton label={t("defaultFee")} value={defaultFee} />
      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={loading}
        sx={{ marginTop: 2 }}
        onClick={onSubmit}
      >
        {t("submit")}
      </LoadingButton>
    </CustomeModal>
  );
};

export default BatchConfirmation;
