import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const userData = useSelector((state) => state?.loginData);
  if (!userData?.data?.email) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

export default ProtectedRoute;
