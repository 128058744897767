import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  useTheme,
  CircularProgress,
} from "@mui/material";
import CustomButton from "components/CustomButton";
import { useTranslation } from "react-i18next";

const Upload = ({
  maxSize = 2,
  acceptedFileType = "json",
  onFileUpload,
  message,
  padding = 5,
  loading = false, // Added loading prop
  children,
}) => {
  const theme = useTheme();
  const [error, setError] = useState(null);
  const [file, setFile] = useState(null);
  const [fileContent, setFileContent] = useState(null);

  const handleFileDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    handleFileValidation(files[0]);
    setFile(files[0]);
  };

  const { t } = useTranslation();

  const handleFileValidation = (file) => {
    const fileExtension = file.name.split(".").pop().toLowerCase();
    const acceptedTypes = acceptedFileType === "csv" ? ["csv"] : ["json"];

    if (!acceptedTypes.includes(fileExtension)) {
      setError(`Only ${acceptedTypes.join(", ")} files are allowed.`);
      return;
    }

    if (file.size / 1024 / 1024 > maxSize) {
      setError(`File size exceeds ${maxSize}MB limit.`);
      return;
    }

    setError(null);
    readFile(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleIconClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
      handleFileValidation(file);

      // Reset the file input value so the same file can be picked again
      event.target.value = null;
    }
  };

  const readFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const fileData = e.target.result;
      setFileContent(fileData);
    };
    reader.readAsText(file);
  };

  const handleRemove = () => {
    setFile(null);
  };

  const handleFileUpload = () => {
    if (fileContent) {
      onFileUpload(fileContent);
      // Reset file after upload
      setFile(null);
      setFileContent(null);
    }
  };

  return (
    <Box
      onDrop={handleFileDrop}
      onDragOver={handleDragOver}
      sx={{
        border: `1px dashed ${theme.palette.primary.main}`,
        borderRadius: "8px",
        backgroundColor: "#ffffff",
        padding: padding,
        textAlign: "center",
        cursor: "pointer",
        margin: "auto",
      }}
    >
      <input
        type="file"
        id="fileInput"
        style={{ display: "none" }}
        accept={acceptedFileType === "csv" ? ".csv" : ".json"}
        onChange={handleFileChange}
      />
      <IconButton
        sx={{ color: "#90CAF9", fontSize: "48px" }}
        onClick={handleIconClick}
      >
        <img
          src={`/assets/icons/upload1.png`}
          alt={"Backup"}
          height={60}
          width={60}
        />
      </IconButton>

      {file?.name ? (
        <Typography variant="h6" color="textPrimary" gutterBottom>
          {file?.name}
        </Typography>
      ) : loading ? (
        // Show loader when loading is true
        <Box>
          <CircularProgress color="primary" />
          <Typography variant="body2" color="textSecondary" mt={2}>
            {t("uploading")}
          </Typography>
        </Box>
      ) : (
        // Show upload instructions when loading is false
        <Box>
          <Typography variant="h6" color="textPrimary" gutterBottom>
            {t("clickOrDragFileToThisAreaToUpload")}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {`Maximum file size ${maxSize}MB.`}{" "}
            {`File type should be ${acceptedFileType}.`}
          </Typography>
          {/* <Typography variant="body2" color="textSecondary">
            {`File type should be ${acceptedFileType}.`}
          </Typography> */}
          <Typography variant="body2" color="error">
            {message}
          </Typography>
          {children}
        </Box>
      )}

      {error && (
        <Typography variant="body2" color="error">
          {error}
        </Typography>
      )}
      <Box mt={2}>
        <CustomButton
          title={t("upload")}
          size="medium"
          onClick={handleFileUpload}
          disabled={!file} // Disable the button when loading
        />
        <CustomButton
          title={t("cancel")}
          color="error"
          size="medium"
          style={{ marginLeft: 10 }}
          onClick={handleRemove}
          disabled={!file} // Disable the button when loading
        />
      </Box>
    </Box>
  );
};

export default Upload;
