import * as Yup from "yup";

const addStudentSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .test(
      "len",
      "Student name should be atleast 5 letter",
      (val) => val?.length >= 5
    ),
  fatherName: Yup.string()
    .required("Father name is required")
    .test(
      "len",
      "Father name should be atleast 5 letter",
      (val) => val?.length >= 5
    ),
  motherName: Yup.string()
    .required("Mother name is required")
    .test(
      "len",
      "Mother name should be atleast 5 letter",
      (val) => val?.length >= 5
    ),
  email: Yup.string()
    .email("Please enter valid email")
    .required("Email is required")
    .test("Validate Email", "Please enter valid email", (value) => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(value).toLowerCase());
    }),
  mobile: Yup.number()
    .required("Mobile is required")
    .typeError("Enter a valid mobile number")
    .test(
      "len",
      "Enter a valid mobile number",
      (val) => val?.toString().length >= 10
    ),
  caste: Yup.string().required("Caste is required"),
  batch: Yup.string().required("Batch is required"),
  dob: Yup.date()
    .typeError("Invalid date")
    .required("Date of birth is required"),
  admissionDate: Yup.date()
    .typeError("Invalid date")
    .required("Admission date is required"),
  pinCode: Yup.number()
    .required("Pin code is required")
    .typeError("Enter a valid pin code")
    .test(
      "len",
      "Enter a valid pin code",
      (val) => val?.toString().length === 6
    ),
  address: Yup.string().required("Address is required"),
});

export { addStudentSchema };
