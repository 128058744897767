import { formatDate, moneyFormat } from "utils";

export const getFormattedPaidBreakUp = (breakUpData) => {
  const formattedData = breakUpData?.map((item) => {
    return {
      createdAt: formatDate(item.createdAt),
      paidAmount: moneyFormat(item.paidAmount),
      discountAmount: moneyFormat(item.discountAmount),
      paymentMode: item.paymentMode,
      receivedFrom: item.receivedFrom,
    };
  });
  return formattedData;
};

export const getFormattedAdditionalPaidBreakUp = (addtionalFeesBreakUp) => {
  const formattedData = addtionalFeesBreakUp?.map((item) => {
    return {
      reason: item?.key,
      amount: moneyFormat(item.value),
    };
  });
  return formattedData;
};

export const getCourseDueBalance = (studentDetails) => {
  const {
    totalCourseFees,
    totalAdditionFees,
    totalPaidAmounts,
    totalDiscounts,
  } = studentDetails ?? {
    totalCourseFees: 0,
    totalAdditionFees: 0,
    totalPaidAmounts: 0,
    totalDiscounts: 0,
  };
  const courseDue =
    +totalCourseFees + +totalAdditionFees - +totalPaidAmounts - +totalDiscounts;
  return courseDue;
};
