import { Typography, Box, IconButton, useTheme, Link } from "@mui/material";
import {
  Attachment,
  CloudUpload,
  CloudDownload,
  Close,
} from "@mui/icons-material";

import AWS from "aws-sdk";
import { useEffect, useState } from "react";
import { useToaster } from "hooks";
import ErrorMsg from "components/ErrorMsg";
import { getSubString } from "utils";
import { useSelector } from "react-redux";

function DocumentUploader({
  title,
  isPreviewFile = false,
  passUploadedUrl,
  uploadedUrl,
  sx,
  folder = "files",
}) {
  const [file, setFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploadedFileUrl, setUploadedFileUrl] = useState("");
  const currentuser = useSelector((state) => state.currentUser);

  useEffect(() => {
    if (uploadedUrl) {
      const splitArr = uploadedUrl?.split("/");
      const fileClone = {};
      fileClone.name = splitArr[splitArr?.length - 1];
      setFile(fileClone);
      setUploadedFileUrl(uploadedUrl);
    }
  }, [uploadedUrl]);

  const theme = useTheme();

  const triggerToaster = useToaster();

  const allowedMaxSize = 0.5; //MB
  const {
    REACT_APP_S3_BUCKET,
    REACT_APP_REGION,
    REACT_APP_ACCESS_KEY,
    REACT_APP_SECRET_ACCESS_KEY,
    REACT_APP_AWS_BUCKET_URL,
  } = process.env;

  // Function to upload file to s3
  const handleUploadFile = async () => {
    if (file) {
      AWS.config.update({
        accessKeyId: REACT_APP_ACCESS_KEY,
        secretAccessKey: REACT_APP_SECRET_ACCESS_KEY,
      });
      const s3 = new AWS.S3({
        params: { Bucket: REACT_APP_S3_BUCKET },
        region: REACT_APP_REGION,
      });

      const filePathName = `${currentuser?.data?.instituteId}/${folder}/${file?.name}`;
      const params = {
        Bucket: REACT_APP_S3_BUCKET,
        Key: filePathName,
        Body: file,
      };

      const upload = s3
        .putObject(params)
        .on("httpUploadProgress", (evt) => {
          setProgress(Math.round((evt.loaded / evt.total) * 100));
        })
        .promise();

      await upload
        .then((data) => {
          if (data) {
            passUploadedUrl(`${REACT_APP_AWS_BUCKET_URL}/${filePathName}`);
            setUploadedFileUrl(`${REACT_APP_AWS_BUCKET_URL}/${filePathName}`);
            triggerToaster("File uploaded successfully.", "success");
          }
        })
        .catch((err) => {
          if (err) {
            triggerToaster(JSON.stringify(err), "error");
          }
        });
    } else {
      triggerToaster("Please select file to upload", "error");
    }
  };

  const validateSize = (evt) => {
    setUploadedFileUrl("");
    const tempFile = evt?.target?.files?.[0];

    const fileSize = tempFile?.size / 1024 / 1024; //convert to MB
    if (fileSize.toFixed(2) > allowedMaxSize) {
      setErrorMessage("Allowed max file size is 500kb");
      return;
    }
    setErrorMessage(null);
    setFile(tempFile);
  };

  const handleDeleteFile = () => {
    setUploadedFileUrl("");
    setFile(null);
    setProgress(0);
    passUploadedUrl("");
  };

  return (
    <Box width={"100%"}>
      <Box
        px={1}
        mt={1}
        sx={{ paddingY: 2 }}
        style={{
          border: `1px solid ${theme.palette.grey["400"]}`,
          borderRadius: 10,
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          ...sx,
        }}
      >
        {!uploadedFileUrl && (
          <Box
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <IconButton sx={{ p: 0 }} disableRipple={true} component="label">
              <Attachment style={{ color: theme.palette.grey["700"] }} />
              <input
                aria-label="file"
                type="file"
                accept="image/*"
                hidden
                onChange={(e) => validateSize(e)}
              />
              <Typography color={theme.palette.grey["700"]} ml={1}>
                {file?.name ? getSubString(file?.name, 10) : title}
              </Typography>
              {progress > 0 && (
                <Typography color={theme.palette.grey["700"]} ml={1}>
                  {`${progress}%`}
                </Typography>
              )}
            </IconButton>
            <IconButton onClick={handleUploadFile}>
              <CloudUpload />
            </IconButton>
          </Box>
        )}
        <Box
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "self-start",
          }}
        >
          {!!uploadedFileUrl &&
            (!isPreviewFile ? (
              <Link
                href={uploadedFileUrl}
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 6,
                }}
              >
                <CloudDownload />
                <Typography style={{ paddingLeft: 6 }}>
                  {getSubString(file?.name, 16)}
                </Typography>
              </Link>
            ) : (
              <img src={uploadedFileUrl} height={100} width={100} alt="file" />
            ))}
          {!!uploadedFileUrl && (
            <IconButton onClick={handleDeleteFile}>
              <Close />
            </IconButton>
          )}
        </Box>
      </Box>
      <ErrorMsg msg={errorMessage} />
    </Box>
  );
}

export default DocumentUploader;
