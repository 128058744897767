import * as Yup from "yup";

const addBatchSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  feeType: Yup.string().required("Fee type is required"),
  defaultFee: Yup.number()
    .typeError("Invalid default fee")
    .required("Default fee is required"),
  startDate: Yup.date()
    .typeError("Invalid date")
    .required("Start date is required"),
  endDate: Yup.date()
    .typeError("Invalid date")
    .required("End date is required")
    .test(
      "date validation",
      "End date should be greater than start date",
      (val, form) => {
        return (
          new Date(form?.parent?.startDate).getTime() <
            new Date(val).getTime() || form?.parent?.startDate === val
        );
      }
    ),
});

export { addBatchSchema };
