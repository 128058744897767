import { Typography, useTheme } from "@mui/material";

const ErrorMsg = ({ msg }) => {
  const theme = useTheme();
  return (
    msg && (
      <Typography
        style={{
          fontSize: 12,
          position: "absolute",
          color: theme.palette.error.dark,
          visibility: msg ? "visible" : "hidden",
          height: 12,
        }}
      >
        {msg}
      </Typography>
    )
  );
};

export default ErrorMsg;
