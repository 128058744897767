import * as Yup from "yup";

const VerifyOtpSchema = Yup.object().shape({
  otp: Yup.string()
    .required("Otp is required")
    .test(
      "len",
      "Password should be equal to 4 length",
      (val) => val.length === 4
    ),
});

export { VerifyOtpSchema };
