import * as faceapi from "face-api.js";
import React, { useEffect, useRef, useState } from "react";

function FaceRecognition() {
  const [modelsLoaded, setModelsLoaded] = useState(false);
  const [captureVideo, setCaptureVideo] = useState(false);
  const [detectedName, setDetectedName] = useState("");
  const videoRef = useRef();
  const canvasRef = useRef();

  const videoHeight = 480;
  const videoWidth = 640;

  useEffect(() => {
    const value = new SpeechSynthesisUtterance(detectedName);
    window.speechSynthesis.speak(value);
  }, [detectedName]);

  useEffect(() => {
    const loadModels = async () => {
      const MODEL_URL = process.env.PUBLIC_URL + "/models";
      await Promise.all([
        faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
        faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
        faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
        faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
      ]);
      setModelsLoaded(true);
    };
    loadModels();
  }, []);

  const startVideo = () => {
    setCaptureVideo(true);
    navigator.mediaDevices
      .getUserMedia({ video: { width: 300 } })
      .then((stream) => {
        let video = videoRef.current;
        video.srcObject = stream;
        video.play();
      })
      .catch((err) => {
        console.error("error:", err);
      });
  };

  const handleVideoOnPlay = () => {
    setInterval(async () => {
      if (canvasRef && canvasRef.current) {
        canvasRef.current.innerHTML = faceapi.createCanvasFromMedia(
          videoRef.current
        );
        const displaySize = {
          width: videoWidth,
          height: videoHeight,
        };

        faceapi.matchDimensions(canvasRef.current, displaySize);

        const detections = await faceapi
          .detectAllFaces(
            videoRef.current,
            new faceapi.TinyFaceDetectorOptions()
          )
          .withFaceLandmarks()
          .withFaceDescriptors();
        const resizedDetections = faceapi.resizeResults(
          detections,
          displaySize
        );

        canvasRef.current
          ?.getContext("2d")
          ?.clearRect(0, 0, videoWidth, videoHeight);
        faceapi.draw.drawDetections(canvasRef.current, resizedDetections);
        faceapi.draw.drawFaceLandmarks(canvasRef.current, resizedDetections);

        if (detections.length > 0) {
          const descriptor = detections[0].descriptor;
          handleFaceDescriptor(descriptor);
        }
      }
    }, 1000);
  };

  const handleFaceDescriptor = (descriptor) => {
    const savedDescriptors =
      JSON.parse(localStorage.getItem("faceDescriptors")) || [];
    const labeledFaceDescriptors = savedDescriptors.map(
      (fd) =>
        new faceapi.LabeledFaceDescriptors(fd.label, [
          new Float32Array(fd.descriptor),
        ])
    );

    if (labeledFaceDescriptors.length > 0) {
      const faceMatcher = new faceapi.FaceMatcher(labeledFaceDescriptors);
      const bestMatch = faceMatcher.findBestMatch(descriptor);
      if (bestMatch.label !== "unknown") {
        setDetectedName(bestMatch.label);
      }
    }
  };

  const closeWebcam = () => {
    videoRef.current.pause();
    videoRef.current.srcObject.getTracks()[0].stop();
    setCaptureVideo(false);
  };

  return (
    <div>
      <div style={{ textAlign: "center", padding: "10px" }}>
        {captureVideo && modelsLoaded ? (
          <button
            onClick={closeWebcam}
            style={{
              cursor: "pointer",
              backgroundColor: "green",
              color: "white",
              padding: "15px",
              fontSize: "25px",
              border: "none",
              borderRadius: "10px",
            }}
          >
            Close Webcam
          </button>
        ) : (
          <button
            onClick={startVideo}
            style={{
              cursor: "pointer",
              backgroundColor: "green",
              color: "white",
              padding: "15px",
              fontSize: "25px",
              border: "none",
              borderRadius: "10px",
            }}
          >
            Open Webcam
          </button>
        )}
      </div>
      {captureVideo && modelsLoaded ? (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <video
              ref={videoRef}
              height={videoHeight}
              width={videoWidth}
              onPlay={handleVideoOnPlay}
              style={{ borderRadius: "10px" }}
            />
            <canvas ref={canvasRef} style={{ position: "absolute" }} />
          </div>
          {detectedName && <h2>Detected: {detectedName}</h2>}
        </div>
      ) : null}
    </div>
  );
}

export default FaceRecognition;
