import { useDispatch } from "react-redux";
import { setMasterDataAction } from "redux/masterData";
import { ApiCaller } from "utils";

export const useMasterData = () => {
  const dispatch = useDispatch();

  const triggerMasterData = () => {
    ApiCaller(`/institute-data`, "get").then((response) => {
      if (response.success) {
        dispatch(setMasterDataAction({ data: response?.data, value: true }));
      }
    });
  };
  return triggerMasterData;
};
