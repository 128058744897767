import * as Yup from "yup";

const addSalarySchema = Yup.object().shape({
  paidAmount: Yup.number()
    .typeError("Amount must be a number")
    .positive("Amount must be positive")
    .required("Amount is required"),
});

export { addSalarySchema };
