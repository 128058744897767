import { Box, useTheme } from "@mui/material";
import { useSelector } from "react-redux";

const Ribbon = ({ message, isVisible = false }) => {
  const currentUser = useSelector((state) => state?.currentUser);
  const theme = useTheme();
  const isRibbonVisible = isVisible
    ? isVisible
    : !currentUser?.data?.instituteId;
  return isRibbonVisible ? (
    <Box
      style={{
        color: theme.palette.error.main,
        padding: "10px",
        marginLeft: 20,
        marginRight: 20,
        textAlign: "center",
        backgroundColor: "white",
      }}
    >
      <marquee style={{ fontWeight: "bold" }}>{message}</marquee>
    </Box>
  ) : null;
};

export default Ribbon;
