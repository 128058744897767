import React from "react";
import { Typography } from "@mui/material";
import { CustomeModal, GridSkeleton } from "components";
import useResponsive from "hooks/useResponsive";
import { LoadingButton } from "@mui/lab";
import { moneyFormat } from "utils";

const SalaryConfirmation = ({
  open,
  onClose,
  loading,
  onSubmit,
  salaryDetails,
  bonus,
}) => {
  const mdUp = useResponsive("up", "md");
  const {
    totalCurrentSalaryDue,
    paidAmount,
    totalDeductionAmount,
    remainingAmount,
  } = salaryDetails;

  const totalBonus = bonus.reduce((sum, item) => sum + item?.value, 0);

  return (
    <CustomeModal
      open={open}
      onClose={onClose}
      style={{ width: mdUp ? "40%" : "80%" }}
    >
      <Typography sx={{ fontWeight: "bold", fontSize: 16, marginBottom: 2 }}>
        Salary confirmation
      </Typography>
      <Typography sx={{ fontSize: 16, marginBottom: 1 }}>
        Do you want to submit salary?
      </Typography>
      <GridSkeleton
        label={"Total current salary"}
        value={moneyFormat(+totalCurrentSalaryDue || 0)}
      />
      <GridSkeleton
        label={"Total paid"}
        value={moneyFormat(+paidAmount || 0)}
      />
      <GridSkeleton
        label={"Total deduction amount"}
        value={moneyFormat(+totalDeductionAmount || 0)}
      />
      <GridSkeleton
        label={"Total Bonus"}
        value={moneyFormat(+totalBonus || 0)}
      />
      <GridSkeleton
        label={"Remaining balance"}
        subLabel={`(Total current salary + Total Bonus -Total paid -Total deduction amount)`}
        value={moneyFormat(+remainingAmount || 0)}
      />
      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={loading}
        sx={{ marginTop: 2 }}
        onClick={onSubmit}
      >
        Submit
      </LoadingButton>
    </CustomeModal>
  );
};

export default SalaryConfirmation;
