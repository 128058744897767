import { Helmet } from "react-helmet-async";
import { useCallback, useEffect, useState } from "react";
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  useTheme,
} from "@mui/material";
import {
  ListHead,
  ListToolbar,
  LoaderWrapper,
  Ribbon,
  TablePaginationActions,
} from "components";
import Iconify from "components/Iconify";
import {
  ApiCaller,
  formatDate,
  handleCheckBox,
  validateData,
  moneyFormat,
  downloadReceipt,
} from "utils";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConfirmationBox from "components/ConfirmationBox";
import { Receipt } from "@mui/icons-material";
import { useTranslation } from "react-i18next";



export default function Fees() {
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [deletedToken, setDeletedToken] = useState("");
  const [isBulkDelete, setIsBulkDelete] = useState(false);
  const [deletedIds, setDeletedIds] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [salary, setSalary] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState({});
  const currentUser = useSelector((state) => state?.currentUser);
  const navigation = useNavigate();
  const theme = useTheme();
  const salaryList = salary?.data || [];
  const { t } = useTranslation();

  const TABLE_HEAD = [
    { id: "staff", label: t("staff") },
    { id: "staffId", label: t("staffNo") },
    { id: "mobile", label: t("mobile") },
    { id: "paid", label: t("paid") },
    { id: "bonus", label: t("bonus") },
    { id: "deduction", label: t("deduction") },
    { id: "paymentDate", label: t("paymentDate") },
    { id: "from", label: t("from") },
    { id: "to", label: t("to") },
    { id: "receipt", label: t("receipt") },
    { id: "", label: t("action") },
  ];

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = salaryList.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const isNotFound = !salaryList.length;

  useEffect(() => {
    setLoading(true);
    ApiCaller(
      `/salary?pageNo=${page + 1}&size=${rowsPerPage}&key=staffId&value=${
        selectedStaff?._id
      }`,
      "get"
    ).then((response) => {
      if (response.success) {
        setSalary(response);
        setLoading(false);
      }
    });
  }, [page, rowsPerPage, deletedToken, selectedStaff]);

  const handlePaySalary = () => {
    navigation("/dashboard/addSalary");
  };

  const handleDelete = () => {
    onDeleteSalary(selected);
  };

  const onDeleteSalary = (ids) => {
    setDeletedIds(ids);
    setIsBulkDelete(true);
    ApiCaller(`/salary`, "delete", { ids }).then((response) => {
      if (response.success) {
        setTimeout(() => {
          setIsBulkDelete(false);
          setDeletedToken(new Date().getTime().toString());
          setSelected([]);
          setIsModalOpen(false);
        }, 2000);
      }
    });
  };

  const isLoading = (id) => deletedIds.includes(id) && isBulkDelete;

  const handleSelectedData = useCallback((data) => {
    setSelectedStaff(data);
  }, []);

  const getBonus = (additionalBonus) => {
    if (additionalBonus?.length) {
      return additionalBonus?.reduce((total, bonus) => total + bonus?.value, 0);
    }
    return 0;
  };

  return (
    <>
      <Helmet>
        <title> Salary | GurukulGuru.com </title>
      </Helmet>
      <Ribbon message={"Please add institute first to pay salary"} />

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
        >
          <Typography variant="h4" gutterBottom>
            {t("paySalary")}
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={handlePaySalary}
            disabled={!currentUser?.data?.instituteId}
          >
            {t("paySalary")}
          </Button>
        </Stack>

        <Card>
          <ListToolbar
            numSelected={selected.length}
            handleDelete={() => {
              setIsModalOpen(true);
            }}
            isSearchBar={false}
            isBulkDelete={isBulkDelete}
            handleSelectedData={handleSelectedData}
            searchType="staff"
          />
          <TableContainer sx={{ overflow: "auto" }}>
            <Table>
              <ListHead
                headLabel={TABLE_HEAD}
                rowCount={salaryList.length}
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {salaryList.map((row, i) => {
                  const {
                    updatedAt,
                    additionalBonus,
                    deduction,
                    fromDate,
                    toDate,
                    _id,
                    paid,
                    staffId,
                  } = row;

                  const selectedUser = selected.indexOf(_id) !== -1;

                  return (
                    <TableRow
                      hover
                      key={i}
                      tabIndex={-1}
                      role="checkbox"
                      selected={selectedUser}
                    >
                      <TableCell padding="checkbox" p={0.5}>
                        <Checkbox
                          checked={selectedUser}
                          onChange={(event) =>
                            handleCheckBox(event, _id, selected, setSelected)
                          }
                        />
                      </TableCell>
                      <TableCell align="left" p={0.2}>
                        {validateData(staffId?.name)}
                      </TableCell>
                      <TableCell align="left" p={0.2}>
                        {validateData(staffId?.staffNo)}
                      </TableCell>
                      <TableCell align="left" p={0.2}>
                        {validateData(staffId?.mobile)}
                      </TableCell>
                      <TableCell align="left" p={0.2}>
                        {moneyFormat(paid)}
                      </TableCell>
                      <TableCell align="left" p={0.2}>
                        {moneyFormat(getBonus(additionalBonus))}
                      </TableCell>
                      <TableCell align="left" p={0.2}>
                        {moneyFormat(deduction)}
                      </TableCell>
                      <TableCell align="left" p={0.1}>
                        {formatDate(updatedAt)}
                      </TableCell>
                      <TableCell align="left">{formatDate(fromDate)}</TableCell>
                      <TableCell align="left">{formatDate(toDate)}</TableCell>
                      <TableCell align="left">
                        {row?.receipt ? (
                          <IconButton
                            onClick={() =>
                              downloadReceipt(
                                row?.receipt,
                                "salary_receipt.pdf"
                              )
                            }
                          >
                            <Receipt color="primary" />
                          </IconButton>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                      <TableCell>
                        <Box sx={{ width: 70 }}>
                          {/* <IconButton
                            size="large"
                            color="inherit"
                            sx={{ padding: 0.6 }}
                            onClick={() => handleEdit(row?._id)}
                          >
                            <Iconify icon={"eva:edit-fill"} />
                          </IconButton> */}
                          {!isLoading(row?._id) ? (
                            <IconButton
                              size="large"
                              color="inherit"
                              sx={{ padding: 0.6 }}
                              onClick={() => {
                                setIsModalOpen(true);
                                setSelected([row?._id]);
                              }}
                            >
                              <Iconify
                                icon={"eva:trash-2-outline"}
                                sx={{ color: theme.palette.error.dark }}
                              />
                            </IconButton>
                          ) : (
                            <IconButton size="large" color="inherit">
                              <Iconify icon="eos-icons:bubble-loading" />
                            </IconButton>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              {isNotFound && !loading && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          No result found
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              <LoaderWrapper loading={loading} />
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={rowsPerPage}
            component="div"
            count={salary.total ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Card>
        <ConfirmationBox
          handleClose={() => setIsModalOpen(false)}
          handleConfirm={() => {
            handleDelete();
          }}
          loading={isBulkDelete}
          open={isModalOpen}
        />
      </Container>
    </>
  );
}
