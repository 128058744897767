import { useDispatch } from "react-redux";
import { toasterAction } from "redux/snackbar";

export const useToaster = () => {
  const dispatch = useDispatch();

  const triggerToaster = (message, variant) => {
    dispatch(
      toasterAction({
        value: true,
        message,
        variant,
      })
    );
  };
  return triggerToaster;
};
