import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Input,
  IconButton,
  useTheme,
  CircularProgress,
  Avatar,
  Tooltip,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ContentCopyIcon from "@mui/icons-material/ContentCopy"; // For the copy icon
import useResponsive from "hooks/useResponsive";
import { ApiCaller } from "utils";
import { CustomButton } from "components";
import { useToaster } from "hooks";

const ChatGPTIntegration = () => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [chatId, setChatId] = useState(null); // For tracking the chat session
  const [loading, setLoading] = useState(false); // Loader state
  const theme = useTheme();
  const chatEndRef = useRef(null); // Reference for auto-scrolling
  const mdUp = useResponsive("up", "md");
  const triggerToaster = useToaster();

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    e.target.value = "";
  };

  const formatMessage = (message) => {
    if (message) {
      const formattedText = message
        .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>") // Bold text
        .replace(/```([\s\S]*?)```/g, (match, p1) => {
          const codeWithLineBreaks = p1.replace(/<br\s*\/?>/g, "\n");
          return `<pre style="white-space: pre-wrap; background-color: #000; color: #fff; padding: 10px; border-radius: 5px;"><code>${codeWithLineBreaks
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")}</code></pre>`;
        })
        .replace(/##\s*(.*?)(\n|$)/g, "<h3>$1</h3>$2") // Heading (## -> <h2>)
        .replace(/\n/g, "<br />"); // Normal text line breaks

      return { __html: formattedText };
    }
    return { __html: message };
  };

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    triggerToaster("Copied to clipboard!", "success");
  };

  const sendMessage = async () => {
    if (!inputMessage && !selectedFile) return;
    setLoading(true);

    const userMessage = { role: "user", content: inputMessage };
    setMessages([...messages, userMessage]);

    if (selectedFile) {
      const imageMessage = {
        role: "user",
        content: URL.createObjectURL(selectedFile),
        type: "image",
      };
      setMessages((prev) => [...prev, imageMessage]);
    }
    setInputMessage("");

    try {
      const formData = new FormData();
      if (selectedFile) formData.append("file", selectedFile);
      formData.append("message", inputMessage);
      formData.append("chatId", chatId);

      const response = await ApiCaller("/chat-bot", "post", formData, true);

      if (response?.message) {
        setMessages((prev) => [
          ...prev,
          { role: "assistant", content: response.message },
        ]);
      } else {
        setMessages((prev) => [
          ...prev,
          { role: "assistant", content: "Opps, Something went wrong!" },
        ]);
      }

      if (!chatId) setChatId(response.chatId);
    } catch (error) {
      console.error("Error:", error);
    }

    setSelectedFile(null);
    setLoading(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: mdUp ? "83vh" : "90vh",
        width: mdUp ? "80%" : "100%", // Ensure the input is pinned to the bottom
        position: "fixed",
      }}
    >
      {/* Chat Window */}
      <Box
        sx={{
          flex: 1,
          overflowY: "auto",
          backgroundColor: "#fff",
          border: `1px dashed ${theme.palette.grey[400]}`,
          padding: 2,
          borderRadius: 2,
          width: !mdUp ? "95%" : "98%",
          marginLeft: !mdUp && 1,
          marginBottom: !mdUp && 4,
        }}
      >
        {messages.map((message, index) => (
          <Box
            key={index}
            style={{
              display: "flex",
              justifyContent:
                message.role === "user" ? "flex-end" : "flex-start",
            }}
          >
            <Paper
              sx={{
                marginBottom: 2,
                padding: 2,
                backgroundColor:
                  message.role === "user" ? "#d1e7ff" : "#f1f1f1",
                alignSelf: message.role === "user" ? "flex-end" : "flex-start",
                maxWidth: "90%",
                display: "flex",
                position: "relative",
              }}
            >
              {/* Copy Icon */}
              {message.role !== "user" && (
                <Tooltip title="Copy to clipboard">
                  <IconButton
                    size="small"
                    onClick={() => copyToClipboard(message.content)}
                    sx={{ position: "absolute", top: 4, right: 4 }}
                  >
                    <ContentCopyIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}

              {message.type === "image" ? (
                <Avatar
                  variant="square"
                  src={message.content}
                  alt="User Uploaded"
                  sx={{ width: 100, height: 100 }}
                />
              ) : (
                <Typography
                  dangerouslySetInnerHTML={formatMessage(message.content)}
                  maxWidth={"90%"}
                  sx={{
                    whiteSpace: "normal", // Ensures proper text wrapping
                  }}
                />
              )}
            </Paper>
          </Box>
        ))}
        <div ref={chatEndRef} />
      </Box>

      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", margin: 1 }}>
          <CircularProgress size={24} loading={loading} />
        </Box>
      )}

      {/* Input Area */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          paddingTop: 2,
          flexDirection: mdUp ? "row" : "column",
          width: "98%",
          position: "sticky",
          bottom: 0, // Keep it at the bottom
        }}
      >
        <TextField
          fullWidth
          multiline
          maxRows={4}
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Type your message..."
          variant="outlined"
          size="small"
          style={{ backgroundColor: "white", width: !mdUp && "97%" }}
          sx={{ flexGrow: 1 }}
          InputProps={{
            endAdornment: (
              <>
                {selectedFile && (
                  <Avatar
                    variant="square"
                    src={URL.createObjectURL(selectedFile)}
                    alt="Preview"
                    sx={{ width: 50, height: 50, marginRight: 1 }}
                  />
                )}
                <IconButton color="primary" component="label">
                  <UploadFileIcon />
                  <Input
                    type="file"
                    onChange={handleFileChange}
                    sx={{ display: "none" }}
                  />
                </IconButton>
              </>
            ),
          }}
        />

        <CustomButton
          variant="contained"
          color="primary"
          onClick={sendMessage}
          endIcon={<SendIcon />}
          style={{
            marginTop: !mdUp && 10,
            width: !mdUp ? "97%" : "10%",
            marginLeft: mdUp && 8,
          }}
          title={"Send"}
          size="medium"
          loading={loading}
        />

        <Button
          variant="outlined"
          color="secondary"
          onClick={() => setMessages([])}
          style={{ marginLeft: 4, minWidth: 100 }}
          sx={{
            marginTop: !mdUp && 1,
            width: !mdUp && "97%",
            marginBottom: !mdUp && 1,
          }}
        >
          New Chat
        </Button>
      </Box>
    </Box>
  );
};

export default ChatGPTIntegration;
