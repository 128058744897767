import { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  InputAdornment,
  Slide,
  Typography,
  IconButton,
  ClickAwayListener,
  Autocomplete,
  TextField,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { bgBlur } from "utils/cssStyles";
import Iconify from "components/Iconify";
import useResponsive from "hooks/useResponsive";
import { useTranslation } from "react-i18next";

const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 92;

const ROUTES = [
  { title: "Dashboard", path: "/dashboard/app", icon: "home" },
  { title: "Institute", path: "/dashboard/addInstitute", icon: "institute" },
  { title: "Staff", path: "/dashboard/staff", icon: "staff" },
  { title: "New Staff", path: "/dashboard/addStaff", icon: "add_staff" },
  { title: "Batch", path: "/dashboard/batch", icon: "batch" },
  { title: "New Batch", path: "/dashboard/addBatch", icon: "batch1" },
  { title: "Student", path: "/dashboard/student", icon: "student" },
  { title: "New Student", path: "/dashboard/addStudent", icon: "add_student" },
  { title: "Fees", path: "/dashboard/fees", icon: "fee" },
  { title: "New Fees", path: "/dashboard/addFees", icon: "fees" },
  { title: "Salary", path: "/dashboard/salary", icon: "salary" },
  { title: "New Salary", path: "/dashboard/addSalary", icon: "salary1" },
  { title: "Expenses", path: "/dashboard/expenses", icon: "expense1" },
  { title: "Add expense", path: "/dashboard/add-expense", icon: "expense" },
  { title: "Institute Kit", path: "/dashboard/school-kit", icon: "school_kit" },
  {
    title: "Institute Kit Sales",
    path: "/dashboard/sell-school-kit",
    icon: "sale",
  },
  {
    title: "New Sell Institute Kit",
    path: "/dashboard/add-sell-school-kit",
    icon: "sell",
  },
  { title: "Setting", path: "/dashboard/setting", icon: "settings" },
  { title: "Profile", path: "/dashboard/edit-profile", icon: "profile" },
];

const StyledSearchbar = styled("div")(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  top: 0,
  left: 0,
  zIndex: 99,
  width: "100%",
  display: "flex",
  position: "absolute",
  alignItems: "center",
  height: HEADER_MOBILE,
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up("md")]: {
    height: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

export default function Searchbar() {
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const mdUp = useResponsive("up", "md");

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
    setSearchTerm("");
  };

  const handleSelect = (event, value) => {
    if (value) {
      navigate(value.path);
      handleClose();
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!open && (
          <IconButton onClick={handleOpen}>
            <Iconify icon="eva:search-fill" />
          </IconButton>
        )}

        <Slide direction="down" in={open} mountOnEnter unmountOnExit>
          <StyledSearchbar>
            <Autocomplete
              freeSolo
              disableClearable
              options={ROUTES}
              getOptionLabel={(option) => option.title}
              onInputChange={(event, value) => setSearchTerm(value)}
              onChange={handleSelect}
              renderOption={(props, option, { index }) => (
                <li
                  {...props}
                  style={{
                    backgroundColor: index % 2 === 0 ? "#fff" : "#fdfdff", // White for even, light grey for odd
                  }}
                >
                  <Stack direction={"row"}>
                    <img
                      src={`/assets/icons/${option?.icon}.png`}
                      alt={option.title}
                      height={25}
                      width={25}
                    />
                    <Typography mt={0.2} ml={2}>
                      {option.title}
                    </Typography>
                  </Stack>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoFocus
                  fullWidth
                  placeholder="Search…"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify
                          icon="eva:search-fill"
                          sx={{ color: "text.disabled", width: 20, height: 20 }}
                        />
                      </InputAdornment>
                    ),
                    endAdornment: params.InputProps.endAdornment,
                  }}
                  sx={{
                    mr: 1,
                    fontWeight: "fontWeightBold",
                    width: mdUp ? "70vw" : "90vw",
                  }}
                />
              )}
            />
          </StyledSearchbar>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}
