import * as Yup from "yup";

const addSchoolKitSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  eventEndDate: Yup.date()
  .typeError("Invalid date")
  .required("Event end date is required")
  .test(
    "date validation",
    "Event end date should be greater than start date",
    (val, form) => {
      return (
        new Date(form?.parent?.eventStartDate).getTime() <=
          new Date(val).getTime() || form?.parent?.startDate === val
      );
    }
  ),
});

export { addSchoolKitSchema };
