import { useEffect, useState } from "react";
import {
  Stack,
  TextField,
  Box,
  Typography,
  Grid,
  useTheme,
  IconButton,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";

import { useToaster, useUploadFile } from "hooks";
import { FileUpload, ErrorMsg, Dropdown } from "components";
import { addInstituteSchema } from "./testSchema";
import { ApiCaller } from "utils";
import { AWS_FOLDERS, DEFAULT_USER_PROFILE, STATES } from "utils/constants";
import useResponsive from "hooks/useResponsive";
import { setCurrentUserAction } from "redux/currentUser";
import { CloudUpload } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export default function AddInstitute() {
  const [logoUrl, setLogoUrl] = useState("");
  const [signatureUrl, setSignatureUrl] = useState("");
  const [instituteId, setInstituteId] = useState("");
  const [loading, setLoading] = useState(false);
  const [instituteData, setInstituteData] = useState({});
  const [isDirtyFile, setIsDirtyFile] = useState(false);
  const userData = useSelector((state) => state?.loginData);
  const mdUp = useResponsive("up", "md");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const triggerToaster = useToaster();
  const theme = useTheme();
  const { t } = useTranslation();
  const { handleUploadFile, uploadedFileUrl } = useUploadFile();
  const {
    handleUploadFile: handleSignUpload,
    uploadedFileUrl: uploadedSignatureUrl,
  } = useUploadFile();

  const isUpdate = !!instituteId;

  const {
    handleSubmit,
    control,
    getValues,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(addInstituteSchema),
  });

  useEffect(() => {
    ApiCaller("/currentuser", "get").then((user) => {
      dispatch(setCurrentUserAction({ data: user, value: true }));
      setInstituteId(user?.instituteId);
      if (user?.instituteId) {
        ApiCaller(`/institute/${user?.instituteId}`, "get").then((response) => {
          if (response.success) {
            setInstituteData(response.data);
            setValue("name", response.data?.name);
            setValue("address", response.data?.address);
            setValue("city", response.data?.city);
            setValue("state", response.data?.state);
            setValue("country", response.data?.country);
            setValue("pinCode", response.data?.pinCode);
            setValue("schoolMobile", response.data?.schoolMobile || "");
            setValue("schoolEmail", response.data?.schoolEmail || "");
            setValue(
              "schoolRegistrationNumber",
              response.data?.schoolRegistrationNumber || ""
            );
            setLogoUrl(response?.data?.logo);
            setSignatureUrl(response?.data?.sign);
          }
        });
      }
    });
  }, []);

  const handleAddUpdateInstitute = () => {
    setLoading(true);
    const body = {
      ...getValues(),
      sign: uploadedSignatureUrl ? uploadedSignatureUrl : signatureUrl,
      logo: uploadedFileUrl ? uploadedFileUrl : logoUrl,
      userId: userData?.data?._id,
    };

    if (isUpdate) {
      // Update institute API call
      ApiCaller(`/institute/${instituteId}`, "put", body).then((response) => {
        if (response?.success) {
          triggerToaster("Institute is updated successfully", "success");
          navigate("/dashboard/app", { replace: true });
        } else {
          triggerToaster(response.data || "Invalid data", "error");
        }
        setLoading(false);
      });
    } else {
      // Add Institute API call
      ApiCaller("/institute", "post", body).then((response) => {
        if (response?.success) {
          triggerToaster("Institute is added successfully.", "success");
          navigate("/dashboard/app", { replace: true });
        } else {
          triggerToaster(response.data || "Invalid data", "error");
        }
        setLoading(false);
      });
    }
  };

  const handleProfileImageChange = (e, folder, isLogo) => {
    const file = e.target.files[0];
    if (file) {
      if (isLogo) {
        handleUploadFile(file, folder);
      } else {
        handleSignUpload(file, folder);
      }
    }
  };

  return (
    <Stack
      spacing={3}
      direction={"row"}
      style={{ display: "flex", justifyContent: "center" }}
    >
      <Box
        as="form"
        onSubmit={handleSubmit(handleAddUpdateInstitute)}
        p={4}
        pt={0}
        sx={{
          backgroundColor: theme.palette.common.white,
        }}
      >
        <Typography variant="h3" mt={2}>
          {`${isUpdate ? t("updateInstituteDetails") : t("addYourInstitute")}`}
        </Typography>
        <Box
          style={{
            display: "flex",
            flexDirection: mdUp ? "row" : "column",
          }}
        >
          <Stack pb={4} width={mdUp ? "50%" : "100%"}>
            {/* Row 1: Institute name, School registration number */}
            <Grid container spacing={2} mt={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="name"
                  defaultValue={instituteData?.name || ""}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      size="small"
                      sx={{ width: "100%" }}
                      label={`${t("instituteName")} *`}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                <ErrorMsg msg={errors?.name?.message} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="schoolRegistrationNumber"
                  defaultValue={instituteData?.schoolRegistrationNumber || ""}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      sx={{ width: "100%" }}
                      label={t("instituteRegistrationNo")}
                      size="small"
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                <ErrorMsg msg={errors?.schoolRegistrationNumber?.message} />
              </Grid>
            </Grid>

            {/* Row 2: School Mobile, School Email */}
            <Grid container spacing={2} mt={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="schoolMobile"
                  defaultValue={instituteData?.schoolMobile || ""}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      sx={{ width: "100%" }}
                      label={`${t("instituteMobile")} *`}
                      size="small"
                      type="number"
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                <ErrorMsg msg={errors?.schoolMobile?.message} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="schoolEmail"
                  defaultValue={instituteData?.schoolEmail || ""}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      sx={{ width: "100%" }}
                      label={`${t("instituteEmail")} *`}
                      size="small"
                      type="email"
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                <ErrorMsg msg={errors?.schoolEmail?.message} />
              </Grid>
            </Grid>

            {/* Row 3: City, Pin Code */}
            <Grid container spacing={2} mt={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="city"
                  defaultValue={instituteData?.city || ""}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      sx={{ width: "100%" }}
                      label={`${t("city")} *`}
                      size="small"
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                <ErrorMsg msg={errors?.city?.message} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="pinCode"
                  defaultValue={instituteData?.pinCode || ""}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      sx={{ width: "100%" }}
                      label={`${t("pinCode")} *`}
                      size="small"
                      type="number"
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                <ErrorMsg msg={errors?.pinCode?.message} />
              </Grid>
            </Grid>

            {/* Row 4: State, Country */}
            <Grid container spacing={2} mt={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="state"
                  render={({ field: { onChange, value } }) => (
                    <Dropdown
                      itemList={
                        watch("country") === "India" ? STATES : ["Other"]
                      }
                      label={`${t("state")} *`}
                      size="small"
                      sx={{ width: "100%" }}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                <ErrorMsg msg={errors?.state?.message} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="country"
                  defaultValue={instituteData?.country || ""}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      sx={{ width: "100%" }}
                      label={`${t("country")} *`}
                      size="small"
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                <ErrorMsg msg={errors?.country?.message} />
              </Grid>
            </Grid>

            {/* Row 5: Address */}
            <Grid container spacing={2} mt={2}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="address"
                  defaultValue={instituteData?.address || ""}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      sx={{ width: "100%" }}
                      label={`${t("address")} *`}
                      size="small"
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                <ErrorMsg msg={errors?.address?.message} />
              </Grid>
            </Grid>
          </Stack>

          {/* Images section on the right */}
          <Box
            sx={{ p: 4 }}
            width={mdUp ? "50%" : "100%"}
            backgroundColor="#fff"
          >
            <Stack spacing={0} direction={mdUp ? "row" : "column"}>
              {/* Logo Upload */}
              <Box
                textAlign="center"
                style={{
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: mdUp ? "80%" : "100%",
                  padding: 20,
                  border: `1px solid ${theme.palette.grey[200]}`,
                }}
              >
                <Typography variant="subtitle1" mb={2}>
                 {t("instituteLogo")}
                </Typography>
                <Box
                  component="img"
                  src={
                    uploadedFileUrl
                      ? uploadedFileUrl
                      : logoUrl || DEFAULT_USER_PROFILE
                  }
                  sx={{
                    width: "120px",
                    height: "120px",
                    borderRadius: 2,
                    objectFit: "cover",
                  }}
                />
                <Button
                  component="label"
                  role={undefined}
                  variant="outlined"
                  style={{ marginTop: 10 }}
                  startIcon={<CloudUpload />}
                >
                  Upload file
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={(e) =>
                      handleProfileImageChange(e, AWS_FOLDERS.INSTITUTES, true)
                    }
                  />
                </Button>
              </Box>

              {/* Signature Upload */}
              <Box
                textAlign="center"
                style={{
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: mdUp ? "80%" : "100%",
                  border: `1px solid ${theme.palette.grey[200]}`,
                  marginLeft: mdUp ? 10 : 0,
                  padding: 20,
                  marginTop: mdUp ? 0 : 10,
                }}
              >
                <Typography variant="subtitle1" mb={1}>
                 {t("authorisedSignature")}
                </Typography>
                <Box
                  component="img"
                  src={
                    uploadedSignatureUrl
                      ? uploadedSignatureUrl
                      : signatureUrl || DEFAULT_USER_PROFILE
                  }
                  sx={{
                    width: "120px",
                    height: "120px",
                    borderRadius: 2,
                    objectFit: "cover",
                  }}
                />
                <Button
                  component="label"
                  variant="outlined"
                  style={{ marginTop: 10 }}
                  startIcon={<CloudUpload />}
                >
                  Upload file
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={(e) =>
                      handleProfileImageChange(e, AWS_FOLDERS.INSTITUTES, false)
                    }
                  />
                </Button>
              </Box>

              {/* Disclaimer */}
            </Stack>
            <Typography
              variant="body2"
              color="text.secondary"
              textAlign="center"
              fontWeight={"bold"}
              sx={{ mt: 4 }}
            >
              {t("note")}
            </Typography>
          </Box>
        </Box>

        <LoadingButton
          variant="contained"
          sx={{
            mt: 2,
            width: "100%",
          }}
          size="large"
          loading={loading}
          type="submit"
        >
          {isUpdate ? t("updateInstitute") : t("addInstitute")}
        </LoadingButton>
      </Box>
    </Stack>
  );
}
