// import * as Yup from "yup";

// const sellSchoolKitSchema = Yup.object().shape({
//   quantity: Yup.number()
//     .typeError("Invalid amount")
//     .required("Quantity is required"),
//   discountAmount: Yup.string().test({
//     name: "validate discount amount",
//     message: "Discount amount must be less than total amount",
//     test(value, ctx) {
//       const { pricePerUnit, quantity } = ctx?.parent;
//       return +value < +pricePerUnit * +quantity;
//     },
//   }),
// });

// export { sellSchoolKitSchema };

import * as Yup from "yup";

const sellSchoolKitSchema = Yup.object().shape({
  schoolKitList: Yup.array().of(
    Yup.object().shape({
      quantity: Yup.number()
        .typeError("Invalid amount")
        .required("Quantity is required")
        .min(1, "Quantity must be at least 1"),
      discountAmount: Yup.number()
        .typeError("Invalid amount")
        .min(0, "Discount amount cannot be negative")
        .test({
          name: "validate discount amount",
          message: "Discount amount must be less than total amount",
          test(value, ctx) {
            const { pricePerUnit, quantity } = ctx.parent;
            return value < pricePerUnit * quantity;
          },
        }),
    })
  ),
});

export { sellSchoolKitSchema };
