import { useEffect, useState } from "react";
import {
  Stack,
  TextField,
  Box,
  Typography,
  Grid,
  useTheme,
  FormControlLabel,
  RadioGroup,
  FormLabel,
  Radio,
  FormControl,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useToaster } from "hooks";
import { ErrorMsg, Dropdown } from "components";
import { addExpenseSchema } from "./testSchema";
import { ApiCaller, validateError } from "utils";
import useResponsive from "hooks/useResponsive";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

export default function AddBatch() {
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const masterData = useSelector((state) => state?.masterData);

  const staffList = masterData?.data?.staffs || [];

  const expenseId = searchParams.get("id");
  const mdUp = useResponsive("up", "md");
  const navigate = useNavigate();
  const triggerToaster = useToaster();
  const theme = useTheme();
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(addExpenseSchema),
  });

  useEffect(() => {
    if (expenseId) {
      ApiCaller(`/expenses?key=_id&value=${expenseId}`, "get").then(
        (response) => {
          if (response.success && response?.data?.length) {
            const expenseDetails = response?.data?.[0];
            setValue("title", expenseDetails?.title);
            setValue("detail", expenseDetails.detail);
            setValue("amount", expenseDetails.amount);
            setValue("usedBy", expenseDetails.usedBy);
            setValue("usedByName", expenseDetails.usedByName);
          }
        }
      );
    }
  }, []);

  const handleAddUpdateExpense = () => {
    setLoading(true);
    const body = {
      ...getValues(),
    };

    if (expenseId) {
      // Update Batch API call
      ApiCaller(`/expenses/${expenseId}`, "put", body).then((response) => {
        if (response?.success) {
          triggerToaster(t("expenseIsUpdatedSuccessfully"), t("success"));
          navigate("/dashboard/expenses");
        } else {
          triggerToaster(response.data || t("invalidData"), t("error"));
        }
        setLoading(false);
      });
    } else {
      // Add expense API call
      ApiCaller("/expenses", "post", body).then((response) => {
        if (!!response?.success) {
          triggerToaster(t("expenseIsAddedSuccessfully"), t("success"));
          navigate("/dashboard/expenses");
        } else {
          triggerToaster(validateError(response?.data), t("error"));
        }
        setLoading(false);
      });
    }
  };

  return (
    <Stack spacing={4} direction={"row"}>
      <Box
        as="form"
        onSubmit={handleSubmit(handleAddUpdateExpense)}
        p={4}
        pt={0}
        width={mdUp ? "70%" : "100%"}
        sx={{
          backgroundColor: theme.palette.common.white,
        }}
      >
        <Typography variant="h3" mt={2}>
          {`${expenseId ? t("updateExpenseDetails") : t("addExpense")}`}
        </Typography>
        <Stack pb={4}>
          <Grid
            container
            spacing={2}
            mt={2}
            direction={{ xs: "column", md: "row" }}
          >
            <Grid item xs={6}>
              <Controller
                control={control}
                name="title"
                defaultValue={""}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    size="small"
                    sx={{ width: "100%" }}
                    label={`${t("expenseTitle")} *`}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              <ErrorMsg msg={errors?.title?.message} />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="amount"
                defaultValue={""}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    sx={{ width: "100%" }}
                    label={`${t("amount")} *`}
                    size="small"
                    type="number"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              <ErrorMsg msg={errors?.amount?.message} />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            mt={2}
            direction={{ xs: "column", md: "row" }}
          >
            <Grid item xs={6}>
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "0px 5px",
                  borderRadius: 1,
                  marginBottom: 3,
                }}
              >
                <FormLabel sx={{ alignSelf: "center", marginRight: 2 }}>
                  {t("usedBy")}
                </FormLabel>
                <Controller
                  control={control}
                  name="usedBy"
                  defaultValue={"staff"}
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                      name="controlled-radio-buttons-group"
                      value={value}
                      size="small"
                      defaultValue={"staff"}
                      onChange={onChange}
                      sx={{ display: "flex", flexDirection: "row" }}
                    >
                      <FormControlLabel
                        value={"staff"}
                        control={<Radio />}
                        label={t("staff")}
                      />
                      <FormControlLabel
                        value={"others"}
                        control={<Radio />}
                        label={t("others")}
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              {watch("usedBy") !== "others" ? (
                <Controller
                  control={control}
                  name="usedByName"
                  render={({ field: { onChange, value } }) => (
                    <Dropdown
                      itemList={staffList}
                      type="obejctArray"
                      label={t("selectStaff")}
                      onChange={onChange}
                      displayKey={"name"}
                      valueKey={"name"}
                      value={value}
                    />
                  )}
                />
              ) : (
                <Controller
                  control={control}
                  name="usedByName"
                  defaultValue={""}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      sx={{ width: "100%" }}
                      label={`${t("name")} *`}
                      size="small"
                      type="text"
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              )}
              <ErrorMsg msg={errors?.usedByName?.message} />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            mt={2}
            direction={{ xs: "column", md: "row" }}
          >
            <Grid item xs={6}>
              <Controller
                control={control}
                name="detail"
                size="small"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    sx={{ width: "100%" }}
                    label={t("expenseDetail")}
                    size="small"
                    multiline={true}
                    rows={2}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              <ErrorMsg msg={errors?.address?.message} />
            </Grid>
          </Grid>
        </Stack>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          disabled={!isDirty}
          loading={loading}
        >
          {`${expenseId ? t("updateExpense") : t("addExpense")}`}
        </LoadingButton>
      </Box>
      {mdUp && (
        <Box>
          <img
            src="/assets/images/study.png"
            alt="institite"
            width={"100%"}
            style={{ marginTop: 50 }}
          />
        </Box>
      )}
    </Stack>
  );
}
