import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

export default function DatePicker({
  size = "small",
  fullWidth = true,
  style,
  error,
  ...rest
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        format="dd/MM/yyyy"
        slotProps={{ textField: { size, fullWidth } }}
        renderInput={(params) => (
          <TextField
            {...params}
            error={error}
            sx={{ ...style }} // Customize width here
          />
        )}
        {...rest}
      />
    </LocalizationProvider>
  );
}
