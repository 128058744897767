import { Helmet } from "react-helmet-async";
import { useCallback, useEffect, useState } from "react";
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  useTheme,
} from "@mui/material";
import {
  CustomButton,
  Dropdown,
  ListHead,
  ListToolbar,
  LoaderWrapper,
  Ribbon,
  TablePaginationActions,
} from "components";
import Iconify from "components/Iconify";
import {
  ApiCaller,
  formatDate,
  handleCheckBox,
  validateData,
  moneyFormat,
  downloadReceipt,
} from "utils";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConfirmationBox from "components/ConfirmationBox";
import { Receipt } from "@mui/icons-material";
import { useTranslation } from "react-i18next";



export default function Fees() {
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [deletedToken, setDeletedToken] = useState("");
  const [isBulkDelete, setIsBulkDelete] = useState(false);
  const [deletedIds, setDeletedIds] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedBatch, setSelectedBatch] = useState("");
  const [fees, setFees] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState({});
  const userData = useSelector((state) => state?.loginData);
  const currentUser = useSelector((state) => state?.currentUser);
  const navigation = useNavigate();
  const theme = useTheme();
  const feesList = fees?.data || [];
  const { t } = useTranslation();

  const masterData = useSelector((state) => state?.masterData);
  const batchList = masterData?.data?.batches || [];

  const TABLE_HEAD = [
    { id: "student", label: t("student") },
    { id: "rollNo", label: t("rollNo") },
    { id: "batch", label: t("batch") },
    { id: "paid", label: t("paid") },
    { id: "discount", label: t("discount") },
    { id: "remaining", label: t("remaining") },
    { id: "paymentDate", label: t("paymentDate") },
    { id: "from", label: t("from") },
    { id: "to", label: t("to") },
    { id: "receipt", label: t("receipt") },
    { id: "", label: t("action") },
  ];

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = feesList.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const isNotFound = !feesList.length;

  useEffect(() => {
    setLoading(true);
    const selectedBatchData = batchList?.find(
      (batch) => batch.name === selectedBatch
    );
    ApiCaller(
      `/fees?pageNo=${page + 1}&size=${rowsPerPage}&batchId=${
        selectedBatchData?._id
      }&key=studentId&value=${selectedStudent?._id}`,
      "get"
    ).then((response) => {
      if (response.success) {
        setFees(response);
        setLoading(false);
      }
    });
  }, [
    deletedToken,
    page,
    rowsPerPage,
    userData?.data?.instituteId,
    selectedBatch,
    batchList,
    selectedStudent,
  ]);

  const handleAddFees = () => {
    navigation("/dashboard/addFees");
  };

  const handleDelete = () => {
    onDeleteFees(selected);
  };

  const onDeleteFees = (ids) => {
    setDeletedIds(ids);
    setIsBulkDelete(true);
    ApiCaller(`/fees`, "delete", { ids }).then((response) => {
      if (response.success) {
        setTimeout(() => {
          setIsBulkDelete(false);
          setDeletedToken(new Date().getTime().toString());
          setSelected([]);
          setIsModalOpen(false);
        }, 2000);
      }
    });
  };

  // const handleEdit = (id) => {
  //   navigation(`/dashboard/addStudent?studentId=${id}`);
  // };

  const isLoading = (id) => deletedIds.includes(id) && isBulkDelete;

  const onDropdownChange = (event) => {
    setSelectedBatch(event?.target?.value);
  };

  const handleReset = () => {
    setSelectedBatch("");
  };

  const handleSelectedData = useCallback((data) => {
    setSelectedStudent(data);
  }, []);

  return (
    <>
      <Helmet>
        <title> Batch | GurukulGuru.com </title>
      </Helmet>
      <Ribbon message={t("pleaseAddInstituteFirstToAddFees")} />

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
        >
          <Typography variant="h4" gutterBottom>
            {t("receivedFeesList")}
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={handleAddFees}
            disabled={!currentUser?.data?.instituteId}
          >
            {t("submitFees")}
          </Button>
        </Stack>

        <Card>
          <ListToolbar
            numSelected={selected.length}
            handleDelete={() => {
              setIsModalOpen(true);
            }}
            isSearchBar={false}
            isBulkDelete={isBulkDelete}
            handleSelectedData={handleSelectedData}
          >
            <Dropdown
              itemList={batchList}
              label={t("selectBatch")}
              onChange={onDropdownChange}
              value={selectedBatch}
              valueKey={"name"}
              displayKey={"name"}
              type="arrayOfObjects"
              sx={{ maxWidth: 250 }}
              style={{ padding: 8 }}
            />
            <CustomButton
              title={t("reset")}
              onClick={handleReset}
              variant="outlined"
              color="error"
              style={{ color: theme.palette.error.main }}
            />
          </ListToolbar>

          <TableContainer sx={{ overflow: "auto" }}>
            <Table>
              <ListHead
                headLabel={TABLE_HEAD}
                rowCount={feesList.length}
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {feesList.map((row, i) => {
                  const {
                    studentId,
                    updatedAt,
                    discountAmount,
                    remainingAmount,
                    paidAmount,
                    fromDate,
                    toDate,
                    _id,
                    batchId,
                  } = row;
                  const selectedUser = selected.indexOf(_id) !== -1;

                  return (
                    <TableRow
                      hover
                      key={i}
                      tabIndex={-1}
                      role="checkbox"
                      selected={selectedUser}
                    >
                      <TableCell padding="checkbox" p={0.5}>
                        <Checkbox
                          checked={selectedUser}
                          onChange={(event) =>
                            handleCheckBox(event, _id, selected, setSelected)
                          }
                        />
                      </TableCell>
                      <TableCell align="left" p={0.2}>
                        {validateData(studentId?.name)}
                      </TableCell>
                      <TableCell align="left" p={0.2}>
                        {validateData(studentId?.rollNo)}
                      </TableCell>
                      <TableCell align="left" p={0.2}>
                        {validateData(batchId?.name)}
                      </TableCell>
                      <TableCell align="left" p={0.2}>
                        {moneyFormat(paidAmount)}
                      </TableCell>
                      <TableCell align="left" p={0.2}>
                        {moneyFormat(discountAmount)}
                      </TableCell>
                      <TableCell align="left" p={0.2}>
                        {moneyFormat(remainingAmount)}
                      </TableCell>
                      <TableCell align="left" p={0.1}>
                        {formatDate(updatedAt)}
                      </TableCell>
                      <TableCell align="left">{formatDate(fromDate)}</TableCell>
                      <TableCell align="left">{formatDate(toDate)}</TableCell>
                      <TableCell align="left">
                        {row?.receipt ? (
                          <IconButton
                            onClick={() =>
                              downloadReceipt(row?.receipt, "fee_receipt.pdf")
                            }
                          >
                            <Receipt color="primary" />
                          </IconButton>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                      <TableCell>
                        <Box sx={{ width: 70 }}>
                          {/* <IconButton
                            size="large"
                            color="inherit"
                            sx={{ padding: 0.6 }}
                            onClick={() => handleEdit(row?._id)}
                          >
                            <Iconify icon={"eva:edit-fill"} />
                          </IconButton> */}
                          {!isLoading(row?._id) ? (
                            <IconButton
                              size="large"
                              color="inherit"
                              sx={{ padding: 0.6 }}
                              onClick={() => {
                                setIsModalOpen(true);
                                setSelected([row?._id]);
                              }}
                            >
                              <Iconify
                                icon={"eva:trash-2-outline"}
                                sx={{ color: theme.palette.error.dark }}
                              />
                            </IconButton>
                          ) : (
                            <IconButton size="large" color="inherit">
                              <Iconify icon="eos-icons:bubble-loading" />
                            </IconButton>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              {isNotFound && !loading && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          No result found
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              <LoaderWrapper loading={loading} />
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={rowsPerPage}
            component="div"
            count={fees.total ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Card>
        <ConfirmationBox
          handleClose={() => setIsModalOpen(false)}
          handleConfirm={() => {
            handleDelete();
          }}
          loading={isBulkDelete}
          open={isModalOpen}
        />
      </Container>
    </>
  );
}
