const { useState, useEffect } = require("react");

export const useDelay = (value, time) => {
  const [data, setData] = useState(value);

  useEffect(() => {
    const timerData = setTimeout(() => {
      setData(value);
    }, time);
    return () => {
      clearTimeout(timerData);
    };
  }, [value, time]);

  return data;
};
