import { combineReducers } from "redux";
import loginData from "./authentication";
import snackbar from "./snackbar";
import masterData from "./masterData";
import currentUser from "./currentUser";
import userSetting from "./userSetting";

const reducer = combineReducers({
  loginData,
  snackbar,
  masterData,
  currentUser,
  userSetting,
});

export default reducer;
