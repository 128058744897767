import { Box } from "@mui/material";
import React from "react";
import FaceRecognition from "./FaceRecognition";

const Attendance = () => {
  return (
    <Box>
      <FaceRecognition />
    </Box>
  );
};

export default Attendance;
