import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Button,
} from "@mui/material";
import ReactToPrint from "react-to-print";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import AWS from "aws-sdk";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { ApiCaller, formatDate, moneyFormat } from "utils";
import { AWS_FOLDERS } from "utils/constants";
import useResponsive from "hooks/useResponsive";
import { useToaster } from "hooks";

const {
  REACT_APP_S3_BUCKET,
  REACT_APP_REGION,
  REACT_APP_ACCESS_KEY,
  REACT_APP_SECRET_ACCESS_KEY,
} = process.env;

const CustomTableRow = ({ label, value }) => (
  <TableRow>
    <TableCell>
      <Typography>{label}</Typography>
    </TableCell>
    <TableCell>
      <Typography sx={{ fontWeight: "bold" }}>{moneyFormat(value)}</Typography>
    </TableCell>
  </TableRow>
);

const SaleReceipt = () => {
  const userData = useSelector((state) => state?.loginData);
  const [searchParams] = useSearchParams();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);
  const componentRef = useRef();
  const triggerToaster = useToaster();

  const mdUp = useResponsive("up", "md");

  useEffect(() => {
    setTimeout(() => {
      generatePdfAndUpload();
    }, 1000);
  }, []);

  const saleDetails = JSON.parse(searchParams.get("sale"));
  const studentDetails = JSON.parse(searchParams.get("student"));
  const institute = JSON.parse(searchParams.get("institute"));
  const selectedItems = JSON.parse(searchParams.get("selectedItems"));

  const getName = (id) => {
    const kit = selectedItems?.find((item) => item?._id === id);
    return kit.name;
  };

  const generatePdfAndUpload = () => {
    const input = componentRef.current;
    const pdf = new jsPDF("p", "mm", "a4", true); // Enable compression
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    html2canvas(input, {
      useCORS: true,
      scale: 2, // Increase the scale to improve quality
      logging: true,
      width: input.clientWidth,
      height: input.clientHeight,
      imageTimeout: 15000,
      allowTaint: true,
    }).then((canvas) => {
      const imgWidth = pdfWidth;
      const pageHeight = pdfHeight;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
      const imgData = canvas.toDataURL("image/jpeg", 0.7); // Use JPEG format with 70% quality
      pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      const pdfBlob = pdf.output("blob");
      AWS.config.update({
        accessKeyId: REACT_APP_ACCESS_KEY,
        secretAccessKey: REACT_APP_SECRET_ACCESS_KEY,
        region: REACT_APP_REGION,
      });
      const s3 = new AWS.S3();
      const params = {
        Bucket: REACT_APP_S3_BUCKET,
        Key: `${userData?.data?.instituteId}/${
          AWS_FOLDERS.SALES
        }/receipt-${Date.now()}.pdf`,
        Body: pdfBlob,
        ContentType: "application/pdf",
      };
      s3.upload(params, (err, data) => {
        if (err) {
          console.error("Error uploading to S3", err);
          setUploadStatus("fail");
        } else {
          ApiCaller(`/school-sales/${saleDetails?._id}`, "put", {
            receipt: data?.Location,
          }).then((response) => {
            if (response?.success) {
              triggerToaster("Sale receipt generated successfully", "success");
              setUploadStatus("success");
            } else {
              triggerToaster(
                response.data || "Something went wrong, Please retry.",
                "error"
              );
              setUploadStatus("fail");
            }
          });
        }
      });
    });
  };

  return (
    <Box p={3}>
      <Box
        ref={componentRef}
        sx={{ padding: 3, border: "1px solid #ccc", backgroundColor: "#fff" }}
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <img
              src={institute?.logo}
              alt={"Institute logo"}
              height={60}
              width={60}
              onLoad={() => setImageLoaded(true)}
              style={{ marginBottom: 2 }}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h4" align="center" marginTop={0}>
              SALE RECEIPT
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Box textAlign="right" marginRight={2}>
              <Typography variant="body1">
                Date:<b> {formatDate(new Date())}</b>
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} direction={{ xs: "column", md: "row" }}>
          <Grid item xs={4}>
            <Typography sx={{ fontWeight: "bold", fontSize: 18 }}>
              {institute?.name ?? ""}
            </Typography>
            <Typography variant="body1">{institute?.address ?? ""}</Typography>
            <Typography variant="body1">
              {`${institute?.city}, ${institute?.state}` ?? ""}
            </Typography>
            <Typography variant="body1">
              {`${institute?.country}, ${institute?.pinCode}` ?? ""}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1">
              <b>Receipt No</b>
            </Typography>
            <Typography variant="body1">{saleDetails?._id}</Typography>
          </Grid>
          <Grid
            item
            xs={4}
            pr={2}
            justifyContent={mdUp ? "right" : "left"}
            display={"flex"}
          >
            <Box>
              <Typography variant="h6">Bill To</Typography>
              <Typography>
                Name: <b>{studentDetails?.name}</b>
              </Typography>
              <Typography>
                Father Name: <b>{studentDetails?.fatherName}</b>
              </Typography>
              <Typography>
                Roll No: <b>{studentDetails?.rollNo}</b>
              </Typography>
              <Typography>
                Batch: <b>{studentDetails?.batchId?.name}</b>
              </Typography>
              <Typography>
                Mobile: <b>{studentDetails?.mobile}</b>
              </Typography>
              <Typography>
                Email: <b>{studentDetails?.email}</b>
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ marginTop: 2 }}></Box>
        <TableContainer component={Paper} sx={{ marginTop: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography>Description</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Quantity</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Total</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Discount</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {saleDetails?.schoolKits.map((item) => {
                return (
                  <TableRow>
                    <TableCell>
                      <Typography>{getName(item?.schoolKitId)}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{item?.quantitySold}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {moneyFormat(item?.itemAmount)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {moneyFormat(item?.itemDiscount)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box textAlign="right" sx={{ marginTop: 3, marginRight: 10 }}>
          <Typography variant="h6">
            <b>Total paid: {moneyFormat(+saleDetails?.sumOfTotal)}</b>
          </Typography>
          <Typography variant="h6">
            <b>Total discount: {moneyFormat(+saleDetails?.sumOfDiscount)}</b>
          </Typography>
        </Box>
        <Box>
          <img
            src={institute?.sign}
            alt={"Institute logo"}
            height={50}
            width={100}
            onLoad={() => setImageLoaded(true)}
          />
          <Typography>Institute Signature</Typography>
        </Box>
        <Box sx={{ marginTop: 3, marginBottom: -2, textAlign: "center " }}>
          <Typography variant="body1">
            {`Gurukulguru.com © 2024 - ${new Date().getFullYear()}. All rights reserved`}
          </Typography>
        </Box>
      </Box>
      <Box textAlign="center" sx={{ marginTop: 3 }}>
        {uploadStatus === "success" ? (
          <ReactToPrint
            trigger={() => (
              <Button variant="contained" color="primary">
                Print
              </Button>
            )}
            content={() => componentRef.current}
          />
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={generatePdfAndUpload}
            disabled={!imageLoaded}
          >
            Generate PDF to print
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default SaleReceipt;
