import { useEffect, useState } from "react";
import {
  Stack,
  TextField,
  Box,
  Typography,
  Grid,
  useTheme,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useToaster } from "hooks";
import StudentConfirmation from "./StudentConfirmation";
import { DatePicker, ErrorMsg, Dropdown, FileUpload } from "components";
import { addStudentSchema } from "./testSchema";
import { ApiCaller, validateError } from "utils";
import useResponsive from "hooks/useResponsive";
import { AWS_FOLDERS } from "utils/constants";

export default function AddStudent() {
  const [loading, setLoading] = useState(false);
  const [photoUrl, setPhotoUrl] = useState("");
  const [isDirtyFile, setIsDirtyFile] = useState(false);
  const [studentData, setStudentData] = useState({});
  const userData = useSelector((state) => state?.loginData);
  const masterData = useSelector((state) => state?.masterData);
  const [institute, setInstitute] = useState({});
  const batchList = masterData?.data?.batches || [];
  const [searchParams] = useSearchParams();
  const [isStudentConfirmation, setIsStudentConfirmation] = useState(false);
  const { t } = useTranslation();

  const studentId = searchParams.get("studentId");
  const mdUp = useResponsive("up", "md");
  const navigate = useNavigate();
  const triggerToaster = useToaster();
  const theme = useTheme();

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(addStudentSchema),
  });

  useEffect(() => {
    if (studentId) {
      ApiCaller(`/students?key=_id&value=${studentId}`, "get").then(
        (response) => {
          if (response.success && response?.data?.length) {
            const studentDetails = response?.data?.[0];
            setStudentData(studentDetails);
            setValue("name", studentDetails?.name);
            setValue("fatherName", studentDetails?.fatherName);
            setValue("motherName", studentDetails?.motherName);
            setValue("caste", studentDetails?.caste);
            setValue("mobile", studentDetails?.mobile);
            setValue("email", studentDetails?.email);
            setValue("adharNo", studentDetails?.adharNo);
            setValue("batch", studentDetails?.batchId?.name);
            setValue("pinCode", studentDetails?.pinCode);
            setValue("dob", new Date(studentDetails?.dob));
            setValue("gender", studentDetails?.gender);
            setValue("address", studentDetails?.address);
            setValue("admissionDate", new Date(studentDetails?.admissionDate));
            setPhotoUrl(studentDetails?.profileUrl);
          }
        }
      );
    }
  }, []);

  useEffect(() => {
    ApiCaller(`/institute/${userData?.data?.instituteId}`, "get").then(
      (response) => {
        setInstitute(response?.data);
      }
    );
  }, [userData]);

  const handleAddUpdateStudent = () => {
    setLoading(true);
    const selectedBatch = batchList.find(
      (batch) => batch.name === getValues("batch")
    );

    const body = {
      ...getValues(),
      instituteId: userData?.data?.instituteId,
      profileUrl: photoUrl,
      batchNo: selectedBatch?.batchNo,
      batchId: selectedBatch?._id,
    };
    if (studentId) {
      // Update Student API call
      ApiCaller(`/student/${studentId}`, "put", body).then((response) => {
        if (response?.success) {
          triggerToaster("Student is updated successfully", "success");
          navigate("/dashboard/student");
        } else {
          triggerToaster(response.data || "Invalid data", "error");
        }
        setLoading(false);
      });
    } else {
      ApiCaller("/student", "post", {
        ...body,
        receiptNumber: new Date().getTime(),
      }).then((response) => {
        if (response?.success) {
          triggerToaster("Student is added successfully", "success");
          navigate(
            `/dashboard/student-receipt?student=${JSON.stringify(
              response?.data
            )}&institute=${JSON.stringify(institute)}`
          );
        } else {
          triggerToaster(validateError(response?.data), "error");
        }
        setLoading(false);
      });
    }
  };

  // const handleCaptureFace = () => {
  //   navigate("/dashboard/faceCapture");
  // };

  const handleConfirmation = () => {
    setIsStudentConfirmation(true);
  };

  return (
    <Stack
      spacing={4}
      direction={"row"}
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box
        as="form"
        onSubmit={handleSubmit(handleConfirmation)}
        p={4}
        pt={0}
        width={mdUp ? "70%" : "100%"}
        sx={{
          backgroundColor: theme.palette.common.white,
          // boxShadow: `1px 2px 9px ${theme.palette.grey[500]}`,
          // borderRadius: 3,
        }}
      >
        <Stack
          direction={"row"}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h3" mt={2}>
            {`${studentId ? t("updateStudentDetails") : t("addStudent")}`}
          </Typography>
          {/* <Button
            variant="contained"
            size="small"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={handleCaptureFace}
            style={{ paddingY: 0, height: 40 }}
          >
            Capture Face
          </Button> */}
        </Stack>
        <Stack pb={4}>
          <Grid
            container
            spacing={2}
            mt={1}
            direction={{ xs: "column", md: "row" }}
          >
            <Grid item xs={4}>
              <Controller
                control={control}
                name="name"
                defaultValue={studentData?.name || ""}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    size="small"
                    sx={{ width: "100%" }}
                    label={`${t("name")} *`}
                    onChange={onChange}
                    value={value}
                    // error={errors?.name?.message}
                  />
                )}
              />
              <ErrorMsg msg={errors?.name?.message} />
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="fatherName"
                defaultValue={studentData?.fatherName || ""}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    size="small"
                    sx={{ width: "100%" }}
                    label={`${t("father")} *`}
                    onChange={onChange}
                    value={value}
                    // error={errors?.mobile?.message}
                  />
                )}
              />
              <ErrorMsg msg={errors?.fatherName?.message} />
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="motherName"
                defaultValue={studentData?.motherName || ""}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    size="small"
                    sx={{ width: "100%" }}
                    label={`${t("mother")} *`}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              <ErrorMsg msg={errors?.motherName?.message} />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            mt={1}
            direction={{ xs: "column", md: "row" }}
          >
            <Grid item xs={4}>
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "0px 5px",
                  borderRadius: 1,
                  marginBottom: 3,
                }}
              >
                <Controller
                  control={control}
                  name="gender"
                  defaultValue={studentData?.gender || "Male"}
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                      name="controlled-radio-buttons-group"
                      value={value}
                      size="small"
                      onChange={onChange}
                      sx={{ display: "flex", flexDirection: "row" }}
                    >
                      <FormControlLabel
                        value={"Male"}
                        control={<Radio />}
                        label={t("male")}
                      />
                      <FormControlLabel
                        value={"Female"}
                        control={<Radio />}
                        label={t("female")}
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="dob"
                defaultValue={studentData?.dob || null}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    label={`${t("dob")} *`}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              <ErrorMsg msg={errors?.dob?.message} />
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="admissionDate"
                defaultValue={studentData?.admissionDate || new Date()}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    label={`${t("admissionDate")} *`}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              <ErrorMsg msg={errors?.admissionDate?.message} />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            direction={{ xs: "column", md: "row" }}
            mt={mdUp ? 0 : 1}
          >
            <Grid item xs={6}>
              <Controller
                control={control}
                name="email"
                defaultValue={studentData?.email || ""}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    sx={{ width: "100%" }}
                    label={`${t("email")} *`}
                    size="small"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              <ErrorMsg msg={errors?.email?.message} />
            </Grid>
            {/* salaryAmount */}
            <Grid item xs={6}>
              <Controller
                control={control}
                name="mobile"
                defaultValue={studentData?.mobile || ""}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    sx={{ width: "100%" }}
                    label={`${t("mobile")} *`}
                    size="small"
                    type="number"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              <ErrorMsg msg={errors?.mobile?.message} />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            mt={1}
            direction={{ xs: "column", md: "row" }}
          >
            <Grid item xs={6}>
              <Controller
                control={control}
                name="caste"
                defaultValue={studentData?.caste || ""}
                render={({ field: { onChange, value } }) => (
                  <Dropdown
                    itemList={[t("general"), t("obc"), t("sc"), t("st")]}
                    label={`${t("caste")} *`}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              <ErrorMsg msg={errors?.caste?.message} />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="adharNo"
                defaultValue={studentData?.adharNo || ""}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    sx={{ width: "100%" }}
                    label={t("adharNumber")}
                    size="small"
                    type="number"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            mt={1}
            direction={{ xs: "column", md: "row" }}
          >
            <Grid item xs={6}>
              <Controller
                control={control}
                name="batch"
                defaultValue={studentData?.batch || ""}
                render={({ field: { onChange, value } }) => (
                  <Dropdown
                    itemList={batchList}
                    label={`${t("batch")} *`}
                    onChange={onChange}
                    value={value}
                    valueKey={"name"}
                    displayKey={"name"}
                    type="arrayOfObjects"
                  />
                )}
              />
              <ErrorMsg msg={errors?.batch?.message} />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="pinCode"
                defaultValue={studentData?.pinCode || ""}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    sx={{ width: "100%" }}
                    label={`${t("pinCode")} *`}
                    size="small"
                    type="number"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              <ErrorMsg msg={errors?.pinCode?.message} />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            mt={2}
            direction={{ xs: "column", md: "row" }}
          >
            <Grid item xs={6}>
              <Controller
                control={control}
                name="address"
                defaultValue={studentData?.address || ""}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    sx={{ width: "100%" }}
                    label={`${t("address")} *`}
                    size="small"
                    type="number"
                    onChange={onChange}
                    value={value}
                    multiline={true}
                    rows={2}
                  />
                )}
              />
              <ErrorMsg msg={errors?.address?.message} />
            </Grid>
            <Grid item xs={6}>
              <Box style={{ marginTop: -10 }}>
                <FileUpload
                  title={t("attachPhoto")}
                  uploadedUrl={photoUrl}
                  passUploadedUrl={(url) => {
                    setIsDirtyFile(true);
                    setPhotoUrl(url);
                  }}
                  sx={{ paddingTop: 11, paddingBottom: 11 }}
                  folder={AWS_FOLDERS?.STUDENTS}
                />
              </Box>
            </Grid>
          </Grid>
        </Stack>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          disabled={!isDirty && !isDirtyFile}
          loading={loading}
        >
          {`${studentId ? t("updateStudent") : t("addStudent")}`}
        </LoadingButton>
        <StudentConfirmation
          open={isStudentConfirmation}
          onClose={() => setIsStudentConfirmation(false)}
          loading={loading}
          onSubmit={handleAddUpdateStudent}
          studentsDetails={getValues()}
        />
      </Box>
      {mdUp && (
        <Box>
          <img
            src="/assets/images/study.png"
            alt="institite"
            width={"100%"}
            style={{ marginTop: 50 }}
          />
        </Box>
      )}
    </Stack>
  );
}
