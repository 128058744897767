import { useEffect, useState } from "react";
import {
  Stack,
  TextField,
  Box,
  Typography,
  Grid,
  useTheme,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useMasterData, useToaster } from "hooks";
import { DatePicker, ErrorMsg, Dropdown } from "components";
import { addBatchSchema } from "./testSchema";
import { useTranslation } from "react-i18next";
import { ApiCaller, validateError } from "utils";
import useResponsive from "hooks/useResponsive";
import BatchConfirmation from "./BatchConfirmation";

export default function AddBatch() {
  const [loading, setLoading] = useState(false);
  const [batchData, setBatchData] = useState({});
  const [isBatchConfirmation, setIsBatchConfirmation] = useState(false);
  const userData = useSelector((state) => state?.loginData);
  const [searchParams] = useSearchParams();
  const masterData = useSelector((state) => state?.masterData);

  const staffList = masterData?.data?.staffs || [];

  const batchId = searchParams.get("id");
  const mdUp = useResponsive("up", "md");
  const navigate = useNavigate();
  const triggerToaster = useToaster();
  const theme = useTheme();
  const triggerMasterData = useMasterData();

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(addBatchSchema),
  });

  useEffect(() => {
    if (batchId) {
      ApiCaller(`/batches?key=_id&value=${batchId}`, "get").then((response) => {
        if (response.success && response?.data?.length) {
          const batchDetails = response?.data?.[0];
          setBatchData(batchDetails);
          setValue("name", batchDetails.name);
          setValue("feeType", batchDetails.feeType);
          setValue("defaultFee", batchDetails.defaultFee);
          setValue("batchTeacher", batchDetails.batchTeacher?.name);
          setValue("startDate", new Date(batchDetails.startDate));
          setValue("endDate", new Date(batchDetails.endDate));
        }
      });
    }
  }, []);

  const handleAddUpdateBatch = () => {
     setLoading(true);
    const batchTeacher = staffList.filter(
      (teacher) => teacher.name === getValues("batchTeacher")
    );

    const body = {
      ...getValues(),
      instituteId: userData?.data?.instituteId,
      batchTeacher: batchTeacher[0]?._id,
    };

    if (batchId) {
      // Update Batch API call
      ApiCaller(`/batch/${batchId}`, "put", body).then((response) => {
        if (response?.success) {
          triggerToaster("Batch is updated successfully", "success");
          triggerMasterData();
          navigate("/dashboard/batch");
        } else {
          triggerToaster(response.data || "Invalid data", "error");
        }
        setLoading(false);
      });
    } else {
      // Add Batch API call
      ApiCaller("/batch", "post", body).then((response) => {
        if (response?.success) {
          triggerToaster("Batch is added successfully", "success");
          triggerMasterData();
          navigate("/dashboard/batch");
        } else {
          triggerToaster(validateError(response?.data), "error");
        }
        setLoading(false);
      });
    }
  };
  const handleBatch = () => {
    setIsBatchConfirmation(true);
  };
  const { t } = useTranslation();

  return (
    <Stack spacing={4} direction={"row"}>
      <Box
        as="form"
        onSubmit={handleSubmit(handleBatch)}
        p={4}
        pt={0}
        width={mdUp ? "70%" : "100%"}
        sx={{
          backgroundColor: theme.palette.common.white,
          // boxShadow: `1px 2px 9px ${theme.palette.grey[500]}`,
          // borderRadius: 3,
        }}
      >
        <Typography variant="h3" mt={2}>
          {`${batchId ? t("updateBatchDetails") : t("addBatch")}`}
        </Typography>
        <Stack pb={4}>
          <Grid
            container
            spacing={2}
            mt={2}
            direction={{ xs: "column", md: "row" }}
          >
            <Grid item xs={6}>
              <Controller
                control={control}
                name="name"
                defaultValue={batchData?.name || ""}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    size="small"
                    sx={{ width: "100%" }}
                    label={`${t("batchName")} *`}
                    onChange={onChange}
                    value={value}
                    // error={!!errors?.name?.message}
                  />
                )}
              />
              <ErrorMsg msg={errors?.name?.message} />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="batchTeacher"
                render={({ field: { onChange, value } }) => (
                  <Dropdown
                    itemList={staffList}
                    type="obejctArray"
                    label={t("batchTeacher")}
                    onChange={onChange}
                    displayKey={"name"}
                    valueKey={"name"}
                    value={value}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            mt={2}
            direction={{ xs: "column", md: "row" }}
          >
            <Grid item xs={6}>
              <Controller
                control={control}
                name="feeType"
                defaultValue={batchData?.feeType || "Monthly"}
                render={({ field: { onChange, value } }) => (
                  <Dropdown
                    itemList={[
                      // "Daily",
                      // "Weekly",
                      "Monthly",
                      "Course Wise",
                      // "Quarterly",
                      // "Half Yearly",
                      // "Yearly",
                    ]}
                    label={`${t("feeType")} *`}
                    onChange={onChange}
                    value={value}
                    // error={!!errors?.feeType?.message}
                  />
                )}
              />
              <ErrorMsg msg={errors?.feeType?.message} />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="defaultFee"
                defaultValue={batchData?.defaultFee || ""}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    sx={{ width: "100%" }}
                    label={`${t("defaultFee")} *`}
                    size="small"
                    type="number"
                    onChange={onChange}
                    value={value}
                    // error={!!errors?.defaultFee?.message}
                  />
                )}
              />
              <ErrorMsg msg={errors?.defaultFee?.message} />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            mt={2}
            direction={{ xs: "column", md: "row" }}
          >
            <Grid item xs={6}>
              <Controller
                control={control}
                name="startDate"
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    label={t("startDate")}
                    value={value}
                    onChange={onChange}
                    // error={!!errors?.startDate?.message}
                  />
                )}
              />
              <ErrorMsg msg={errors?.startDate?.message} />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="endDate"
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    label={t("endDate")}
                    value={value}
                    onChange={onChange}
                    // error={!!errors?.endDate?.message}
                  />
                )}
              />
              <ErrorMsg msg={errors?.endDate?.message} />
            </Grid>
          </Grid>
        </Stack>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          disabled={!isDirty}
          loading={loading}
        >
          {`${batchId ? t("UpdateBatch") : t("addBatch")}`}
        </LoadingButton>
        <BatchConfirmation
          open={isBatchConfirmation}
          onClose={() => setIsBatchConfirmation(false)}
          loading={loading}
          onSubmit={handleAddUpdateBatch}
          batchDetails={getValues()}
          />
      </Box>
      {mdUp && (
        <Box>
          <img
            src="/assets/images/study.png"
            alt="institite"
            width={"100%"}
            style={{ marginTop: 50 }}
          />
        </Box>
      )}
    </Stack>
  );
}
