import { Button, CircularProgress } from "@mui/material";

const CustomButton = ({
  variant = "contained",
  color = "primary",
  size = "large",
  loading = false,
  title,
  style,
  disabled,
  onClick,
  endIcon,
  fullWidth = false,
}) => {
  return (
    <Button
      onClick={onClick}
      size={size}
      color={color}
      variant={variant}
      fullWidth={fullWidth}
      endIcon={endIcon}
      disabled={disabled || loading}
      style={{ color: "white", ...(style && { ...style }) }}
    >
      {loading && (
        <CircularProgress
          size="small"
          style={{ height: 24, width: 24, marginRight: 8 }}
        />
      )}

      {title}
    </Button>
  );
};

export default CustomButton;
