import { Box, useTheme } from "@mui/material";
import "./loader.css";
import Loader from "../Loader";

const CustomLoader = ({ isNewLoader = true, loading = false, rest }) => {
  const theme = useTheme();
  return isNewLoader ? (
    loading && (
      <Box
        sx={{ display: "flex", justifyContent: "center", padding: 1 }}
        style={rest}
      >
        <Box className="loader-circle"></Box>
      </Box>
    )
  ) : (
    <Box
      sx={{ display: "flex", justifyContent: "center", padding: 2 }}
      style={rest}
    >
      <Loader
        loading={loading}
        style={{
          color: theme.palette.primary.main,
          height: 40,
          width: 40,
        }}
      />
    </Box>
  );
};

export default CustomLoader;
