import { useState } from "react";
import {
  Box,
  useTheme,
  LinearProgress,
  Typography,
  IconButton,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useTransition } from "react";
import { useToaster } from "hooks";
import { ApiCaller } from "utils";
import { Upload } from "components";

import ConfirmationBox from "components/ConfirmationBox";
import { useTranslation } from "react-i18next";

const BackUp = () => {
  const [backupLoading, setBackUpLoading] = useState(false);
  const [reStoreLoading, setReStoreLoading] = useState(false);
  const [fileContent, setFileContent] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const triggerToaster = useToaster();
  const userData = useSelector((state) => state?.loginData);

  const theme = useTheme();
  const { t } = useTranslation();

  const handleBackup = () => {
    try {
      setBackUpLoading(true);
      ApiCaller(`/backup`, "get").then((response) => {
        if (response?.success) {
          triggerToaster(response?.data, "success");
        } else {
          triggerToaster(response?.data, "error");
        }
        setBackUpLoading(false);
      });
    } catch (error) {
      triggerToaster("Something went wrong!", "error");
      setBackUpLoading(false);
    }
  };

  const handleFileUpload = async (fileData) => {
    setFileContent(fileData);
    setIsModalOpen(true);
  };

  const handleRestore = async () => {
    setIsModalOpen(false);

    try {
      setReStoreLoading(true);
      const response = await ApiCaller("/restore-backup", "post", {
        encryptedJsonData: fileContent,
      });
      if (response.success) {
        triggerToaster(response?.data, "success");
      } else {
        const error = await response.json();
        triggerToaster(`Error restoring backup: ${error.data}`, "error");
      }
      setReStoreLoading(false);
    } catch (error) {
      triggerToaster(
        "Invalid file format or error in restoring backup.",
        "error"
      );
      setReStoreLoading(false);
    }
  };

  return (
    <Box p={2}>
      <Typography variant={"h6"} mb={1}>
        {t("backup")} 
      </Typography>
      {backupLoading && <LinearProgress style={{ marginBottom: 10 }} />}
      <Box
        sx={{
          border: `1px dashed ${theme.palette.primary.main}`, // Light blue color for the dashed border
          borderRadius: "8px", // Rounded corners
          backgroundColor: "#ffffff", // Light background color
          padding: "20px", // Spacing inside the box
          textAlign: "center",
          margin: "auto", // Center horizontally
        }}
      >
        <IconButton sx={{ fontSize: "48px" }} onClick={handleBackup}>
          <img
            src={`/assets/icons/backup1.png`}
            alt={"Backup"}
            height={60}
            width={60}
          />
        </IconButton>
        <Typography variant="h6" color="textPrimary" gutterBottom>
          {t("clickToGenerateEncryptedBackup")}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {t("thisWillIncludeAllTheDataOfYourInstituteUpToThisTimeItWillBeSentToYourRegisteredEmail")}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {t("ifYouNeedToRestoreTheDataAtAnyPointSimplySendAnEmailTo")}
          <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>
            {" " + process.env.REACT_APP_SUPPORT_EMAIL + " "}
          </a>
          {t("toEnableTheRestorationOption")}
        </Typography>
      </Box>
      {userData?.data?.isRestoreBackupEnabled && (
        <>
          <Typography variant={"h6"} mb={1} mt={1}>
            {t("restoreData")}
          </Typography>
          {reStoreLoading && <LinearProgress style={{ marginBottom: 10 }} />}
          <Upload
            onFileUpload={handleFileUpload}
            loading={reStoreLoading}
            message={t("noteThisWillDeleteAllYourCurrentDataAndRestoreYourUploadedFileBackup")}
          />
          <ConfirmationBox
            handleClose={() => setIsModalOpen(false)}
            handleConfirm={handleRestore}
            title="Confirmation message"
            detail="Note: This will delete all your current data and restore your uploaded file backup."
            message="Are you sure, you want to restore backup?"
            open={isModalOpen}
          />
        </>
      )}
    </Box>
  );
};

export default BackUp;
