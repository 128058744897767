import { Typography } from "@mui/material";

const Label = ({ children, style, variant }) => {
  return (
    <Typography sx={{ color: "#fff" }} style={style} variant={variant}>
      {children}
    </Typography>
  );
};

export default Label;
