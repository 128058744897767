import { useEffect, useState } from "react";
import { alpha } from "@mui/material/styles";
import { Box, MenuItem, Stack, IconButton, Popover } from "@mui/material";
import i18n from "i18next"; // Import i18next
import { ApiCaller, validateError } from "utils";
import { useSelector } from "react-redux";
import { useToaster } from "hooks";
import { useSetting } from "hooks/useSetting";

const LANGS = [
  {
    value: "en",
    label: "English",
    icon: "/assets/icons/english1.png",
  },
  {
    value: "hi",
    label: "Hindi",
    icon: "/assets/icons/hindi.png",
  },
];

export default function LanguagePopover() {
  const [open, setOpen] = useState(null);
  const [currentLang, setCurrentLang] = useState(LANGS[0]);
  const userSetting = useSelector((state) => state?.userSetting);
  const currentUser = useSelector((state) => state?.currentUser);
  const triggerToaster = useToaster();
  const triggerSetting = useSetting();

  useEffect(() => {
    const userSelectedLanguage =
      userSetting?.data?.selectedLanguage || "English";
    const selectedLang = LANGS.find(
      (lan) => lan?.label === userSelectedLanguage
    );
    setCurrentLang(selectedLang);
    i18n.changeLanguage(selectedLang?.value); // Change the language using i18next
  }, [userSetting]);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleChangeLanguage = (lang) => {
    setCurrentLang(lang);
    i18n.changeLanguage(lang.value); // Change the language using i18next
    handleClose();
    updateLanguage(lang.label);
  };

  const updateLanguage = (lang) => {
    const body = {
      userId: currentUser?.data?._id,
      selectedLanguage: lang,
    };
    try {
      ApiCaller("/update-setting", "post", body).then((response) => {
        if (response?.success) {
          triggerToaster("Language is updated successfully", "success");
          triggerSetting(currentUser?.data?._id);
        } else {
          triggerToaster(validateError(response?.data), "error");
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <>
      <IconButton
        onClick={handleOpen}
        disableRipple={true}
        sx={{
          padding: 0,
          width: currentLang.label === "Hindi" ? 30 : 35,
          height: currentLang.label === "Hindi" ? 30 : 35,
          ...(open && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.focusOpacity
              ),
          }),
        }}
      >
        <img src={currentLang.icon} alt={currentLang.label} />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Stack spacing={0.75}>
          {LANGS.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === LANGS[0].value}
              onClick={() => handleChangeLanguage(option)}
            >
              <Box
                component="img"
                alt={option.label}
                src={option.icon}
                sx={{ width: 28, mr: 2 }}
              />

              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </Popover>
    </>
  );
}
