import * as Yup from "yup";

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .test(
      "len",
      "Password should be greater than 5 letter",
      (val) => val.length >= 6
    ),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .test(
      "len",
      "Password should be greater than 5 letter",
      (val) => val.length >= 6
    ),
  otp: Yup.string()
    .required("Otp is required")
    .test(
      "len",
      "Password should be equal to 4 length",
      (val) => val.length === 4
    ),
});

export { resetPasswordSchema };
