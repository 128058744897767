import * as Yup from "yup";

const addSchoolKitSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  amount: Yup.number()
    .typeError("Invalid amount")
    .required("Amount is required"),
});

export { addSchoolKitSchema };
