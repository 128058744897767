// import * as React from "react";
// import { Modal, IconButton, Button, Box } from "@mui/material";
// import { CloseOutlined } from "@mui/icons-material";

// import { THEME_COLOR } from "utils/constants";
// import useResponsive from "hooks/useResponsive";

// export default function CustomeModal({
//   open,
//   handleClose,
//   children,
//   width,
//   style,
// }) {
//   const mdUp = useResponsive("up", "md");

//   const customStyle = {
//     position: "absolute",
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     width: !mdUp ? "90%" : "50%",
//     bgcolor: "background.paper",
//     boxShadow: 24,
//     p: 4,
//   };

//   return (
//     <Modal
//       hideBackdrop
//       open={open}
//       onClose={handleClose}
//       aria-labelledby="child-modal-title"
//       aria-describedby="child-modal-description"
//     >
//       <Box sx={customStyle} style={style}>
//         <IconButton
//           aria-label="close-icon"
//           sx={iconStyle}
//           onClick={handleClose}
//         >
//           <CloseOutlined />
//         </IconButton>
//         {children}
//       </Box>
//     </Modal>
//   );
// }

import React from "react";
import { Modal, Box, IconButton, Typography } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import useResponsive from "hooks/useResponsive";

const iconStyle = {
  position: "absolute",
  top: "1%",
  right: "1%",
  color: "#000000",
};

const CustomeModal = ({ open, onClose, children, style }) => {
  const mdUp = useResponsive("up", "md");

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: !mdUp ? "90%" : "50%",
          bgcolor: "background.paper",
          mexHeight: "80%",
          p: 4,
        }}
        style={style}
      >
        <IconButton aria-label="close-icon" sx={iconStyle} onClick={onClose}>
          <CloseOutlined />
        </IconButton>
        {children}
      </Box>
    </Modal>
  );
};

export default CustomeModal;
