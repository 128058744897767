import { createSlice } from "@reduxjs/toolkit";

export const masterData = createSlice({
  name: "masterData",
  initialState: {
    value: false,
    data: {},
  },
  reducers: {
    setMasterDataAction: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setMasterDataAction } = masterData.actions;

export default masterData.reducer;
