import { Helmet } from "react-helmet-async";
import { Container, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { ImageView, StyledRoot, StyledContent } from "components";
import ForgotForm from "./ForgotForm";

export default function ForgotPassword() {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <>
      <Helmet>
        <title>
          {searchParams.get("title") || "Forgot Password"} | GurukulGuru.com
        </title>
      </Helmet>
      <StyledRoot>
        <ImageView title={"Hi, Welcome"} />
        <Container sx={{ padding: 0 }}>
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              {searchParams.get("title") || "Forgot Password"}
            </Typography>
            <ForgotForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
