/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import {
  Stack,
  TextField,
  Box,
  Typography,
  Grid,
  useTheme,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useForm, Controller, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useToaster } from "hooks";
import { ErrorMsg, AutoCompleteSearch, ViewOnly } from "components";

import { ApiCaller, getSubString, moneyFormat, validateError } from "utils";
import useResponsive from "hooks/useResponsive";

import { sellSchoolKitSchema } from "./testSchema";
import SellSchoolKitConfirmation from "./SellSchoolKitConfirmation";
import MultiSelectDropdown from "components/MultiSelectDropdown";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function AddSellSchoolKit() {
  const [loading, setLoading] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState({});
  // const [selectedSchoolKit, setSelectedSchoolKit] = useState({});
  const [isSellKitConfirmation, setIsSellKitConfirmation] = useState(false);
  const [schoolKits, setSchoolKits] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [institute, setInstitute] = useState({});

  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const userData = useSelector((state) => state?.loginData);

  const studentId = searchParams.get("studentId");
  const mdUp = useResponsive("up", "md");
  const navigate = useNavigate();
  const triggerToaster = useToaster();
  const theme = useTheme();

  const {
    handleSubmit,
    control,
    getValues,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(sellSchoolKitSchema),
    defaultValues: {
      schoolKitList: selectedItems.map((item) => ({
        pricePerUnit: item.amount,
        quantity: 0,
        discountAmount: 0,
        itemTotalAmount: 0,
      })),
    },
  });

  useEffect(() => {
    ApiCaller(`/institute/${userData?.data?.instituteId}`, "get").then(
      (response) => {
        setInstitute(response?.data);
      }
    );
  }, [userData]);

  const handleSelectedData = useCallback((data) => {
    setSelectedStudent(data);
  }, []);

  const handleSellToolKit = () => {
    if (selectedStudent?._id) {
      setIsSellKitConfirmation(true);
    } else {
      triggerToaster("Please select student", "error");
    }
  };

  // Watch for quantity and discountAmount changes
  const schoolKitList = watch("schoolKitList");
  const quantityFields = useWatch({
    control,
    name: schoolKitList?.map((_, index) => `schoolKitList.${index}.quantity`),
  });
  const discountFields = useWatch({
    control,
    name: schoolKitList?.map(
      (_, index) => `schoolKitList.${index}.discountAmount`
    ),
  });

  useEffect(() => {
    if (schoolKitList) {
      let totalQuantity = 0;
      let totalAmount = 0;
      let totalDiscount = 0;
      schoolKitList.forEach((item, index) => {
        const amount = selectedItems[index]?.amount || 0;
        const quantity = item?.quantity || 0;
        const discountAmount = item?.discountAmount || 0;
        const itemTotalAmount = quantity * amount - discountAmount;
        totalQuantity = +totalQuantity + +quantity;
        totalAmount = +totalAmount + +itemTotalAmount;
        totalDiscount = +totalDiscount + +discountAmount;
        setValue(`schoolKitList.${index}.itemTotalAmount`, itemTotalAmount);
      });

      setValue("totalQuantity", totalQuantity);
      setValue("totalAmount", totalAmount);
      setValue("totalDiscount", totalDiscount);
    }
  }, [quantityFields, discountFields, selectedItems, setValue]);

  useEffect(() => {
    ApiCaller(`/school-kit`, "get").then((response) => {
      if (response) {
        setSchoolKits(response?.data || []);
      }
    });
  }, []);

  const handleAddUpdateSellKit = () => {
    try {
      setLoading(true);
      const schoolKitList = getValues("schoolKitList") ?? [];
      const schoolKits = schoolKitList?.map((item) => {
        return {
          schoolKitId: item?.schoolKitId,
          quantitySold: +item?.quantity,
          itemAmount: +item?.itemTotalAmount,
          itemDiscount: +item?.discountAmount,
          remark: item?.remark,
          availableQuantity: item?.availableQuantity - +item?.quantity,
        };
      });
      const body = {
        studentId: selectedStudent?._id,
        sumOfTotal: getValues("totalAmount"),
        sumOfQuantity: getValues("totalQuantity"),
        sumOfDiscount: getValues("totalDiscount"),
        schoolKits,
      };

      ApiCaller("/school-sales", "post", body).then((response) => {
        if (response?.success) {
          triggerToaster("Sale is added successfully", "success");
          navigate("/dashboard/sell-school-kit");
          navigate(
            `/dashboard/sale-receipt?sale=${JSON.stringify(
              response?.data
            )}&student=${JSON.stringify(
              selectedStudent
            )}&institute=${JSON.stringify(
              institute
            )}&selectedItems=${JSON.stringify(selectedItems)}`
          );
        } else {
          triggerToaster(validateError(response?.data), "error");
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsSellKitConfirmation(false);
    }
  };

  const getSelectedItems = (items) => {
    setSelectedItems(items);
    items.forEach((item, i) => {
      setValue(`schoolKitList.${i}.pricePerUnit`, +(item?.amount || 0));
      setValue(`schoolKitList.${i}.schoolKitId`, item?._id || "");
      setValue(
        `schoolKitList.${i}.availableQuantity`,
        +(item?.availableQuantity || 0)
      );
    });
  };

  return (
    <Stack
      spacing={4}
      direction={"row"}
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box
        as="form"
        onSubmit={handleSubmit(handleSellToolKit)}
        p={4}
        pt={0}
        sx={{
          backgroundColor: theme.palette.common.white,
          width: "100%",
        }}
      >
        <Typography variant="h3" mt={1} mb={2}>
          {`${studentId ? t("updateInstituteKit") : t("sellInstituteKit")}`}
        </Typography>
        <Stack
          direction={mdUp ? "row" : "column"}
          justifyContent={"space-between"}
          gap={2}
          marginBottom={2}
        >
          <AutoCompleteSearch
            label={t("searchStudentByNameRollNo")}
            passSelectedValue={handleSelectedData}
            sx={{ width: mdUp && selectedStudent ? "50%" : "100%" }}
          />

          {!!selectedStudent?._id && (
            <MultiSelectDropdown
              list={schoolKits || []}
              getSelectedItem={getSelectedItems}
              label={t("selectInstituteKit")}
              placeholder={t("selectInstituteKit")}
              style={{ width: mdUp && selectedStudent ? "50%" : "100%" }}
            />
          )}
        </Stack>
        {!!selectedStudent?._id && (
          <Grid
            container
            sx={{
              width: "100%",
              marginTop: 0,
              padding: 1,
              backgroundColor: theme.palette.primary.lightBlue,
            }}
            direction={{ xs: "column", md: "row" }}
            spacing={mdUp ? 0 : 1}
          >
            <Grid item xs={3}>
              <ViewOnly label={t("name")} value={selectedStudent?.name} />
            </Grid>
            <Grid item xs={3}>
              <ViewOnly
                label={t("father")}
                value={selectedStudent?.fatherName}
              />
            </Grid>
            <Grid item xs={3}>
              <ViewOnly label={t("rollNo")} value={selectedStudent?.rollNo} />
            </Grid>
            <Grid item xs={3}>
              <ViewOnly
                label={t("batch")}
                value={selectedStudent?.batchId?.name}
              />
            </Grid>
          </Grid>
        )}
        {selectedItems?.map((selectedItem, i) => (
          <Box
            key={i}
            sx={{
              padding: 1,
              marginTop: 2,
              boxShadow: theme.customShadows.card,
              border: `0.5px solid ${theme.palette.grey[1000]}`,
            }}
          >
            <Grid
              container
              sx={{ width: "100%", padding: 1 }}
              direction={{ xs: "column", md: "row" }}
              spacing={mdUp ? 0 : 1}
            >
              <Grid item xs={3}>
                <ViewOnly label={t("itemName")} value={selectedItem?.name} />
              </Grid>
              <Grid item xs={3}>
                <ViewOnly
                  label={t("amountPerPeice")}
                  value={moneyFormat(selectedItem?.amount)}
                />
              </Grid>
              <Grid item xs={3}>
                <ViewOnly
                  label={t("availableQuantity")}
                  value={selectedItem?.availableQuantity?.toString() || 0}
                />
              </Grid>
              <Grid item xs={3}>
                <ViewOnly
                  label={t("detail")}
                  value={getSubString(selectedItem?.detail, 20)}
                />
              </Grid>
            </Grid>
            <Grid
              container
              sx={{ width: "100%", padding: 1, marginTop: 1 }}
              direction={{ xs: "column", md: "row" }}
              spacing={mdUp ? 1 : 2.5}
            >
              <Grid item xs={3}>
                <Controller
                  control={control}
                  name={`schoolKitList.${i}.quantity`}
                  defaultValue={0}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      sx={{ width: "100%" }}
                      label={`${t("quantity")} *`}
                      size="small"
                      type="number"
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                <ErrorMsg msg={errors?.schoolKitList?.[i]?.quantity?.message} />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  control={control}
                  name={`schoolKitList.${i}.itemTotalAmount`}
                  defaultValue={0}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      sx={{ width: "100%" }}
                      label={`${t("itemTotalAmount")} *`}
                      size="small"
                      type="number"
                      onChange={onChange}
                      value={value}
                      disabled={true}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  control={control}
                  name={`schoolKitList.${i}.discountAmount`}
                  defaultValue={0}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      sx={{ width: "100%" }}
                      label={t("itemDiscountAmount")}
                      size="small"
                      type="number"
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                <ErrorMsg
                  msg={errors?.schoolKitList?.[i]?.discountAmount?.message}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  control={control}
                  name={`schoolKitList.${i}.remark`}
                  defaultValue={""}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      sx={{ width: "100%" }}
                      label={t("remark")}
                      size="small"
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        ))}
        {!!selectedItems?.length && (
          <Grid
            container
            sx={{
              padding: 2,
              marginTop: 3,
              marginLeft: 0.1,
              boxShadow: theme.customShadows.card,
              border: `0.5px solid ${theme.palette.grey[1000]}`,
              backgroundColor: theme.palette.primary.lightBlue,
            }}
            direction={{ xs: "column", md: "row" }}
            spacing={mdUp ? 1 : 2.5}
          >
            <Grid item xs={3}>
              <Controller
                control={control}
                name={`totalQuantity`}
                defaultValue={0}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    sx={{ width: "100%" }}
                    label={`${t("totalQuantity")} *`}
                    size="small"
                    disabled={true}
                    type="number"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                control={control}
                name={`totalAmount`}
                defaultValue={0}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    sx={{ width: "100%" }}
                    label={`${t("totalAmount")} *`}
                    size="small"
                    type="number"
                    onChange={onChange}
                    value={value}
                    disabled={true}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                control={control}
                name={`totalDiscount`}
                defaultValue={0}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    sx={{ width: "100%" }}
                    label={t("totalDiscount")}
                    size="small"
                    type="number"
                    disabled={true}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                disabled={!isDirty}
              >
                {`${studentId ? t("update") : t("submit")}`}
              </LoadingButton>
            </Grid>
          </Grid>
        )}
      </Box>
      <SellSchoolKitConfirmation
        open={isSellKitConfirmation}
        onClose={() => setIsSellKitConfirmation(false)}
        loading={loading}
        onSubmit={handleAddUpdateSellKit}
        sellDetails={getValues()}
        studentDetails={selectedStudent}
      />
    </Stack>
  );
}
