import CustomLoader from "components/CustomLoader";

const { TableBody, TableRow, TableCell, Paper } = require("@mui/material");

const LoaderWrapper = ({ loading }) => {
  return (
    loading && (
      <TableBody>
        <TableRow>
          <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
            <Paper
              sx={{
                textAlign: "center",
              }}
            >
              <CustomLoader loading={loading} />
            </Paper>
          </TableCell>
        </TableRow>
      </TableBody>
    )
  );
};

export default LoaderWrapper;
