import { Typography } from "@mui/material";
import useResponsive from "hooks/useResponsive";
import { Logo, StyledSection } from "components";

export default function ImageView({ title }) {
  const mdUp = useResponsive("up", "md");

  return (
    <>
      {/* <Logo
        sx={{
          position: "fixed",
          top: { xs: 16, sm: 24, md: 40 },
          left: { xs: 16, sm: 24, md: 40 },
        }}
      /> */}

      {mdUp && (
        <StyledSection>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            {title}
          </Typography>
          <img src="/assets/illustrations/illustration_login.png" alt="login" />
        </StyledSection>
      )}
    </>
  );
}
