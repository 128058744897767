import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Typography,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";

import { useToaster } from "hooks";
import { Iconify, ErrorMsg } from "components";
import { loginSchema } from "./testSchema";
import { setLoginDataAction } from "redux/authentication";
import { ApiCaller } from "utils";

export default function RegisterForm() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const triggerToaster = useToaster();

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(loginSchema),
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = () => {
    navigate("/login");
  };

  const handleRegister = () => {
    setLoading(true);
    ApiCaller("/register", "post", getValues()).then((response) => {
      if (response?.success) {
        const res = { ...response.data, token: response.token };
        dispatch(setLoginDataAction({ value: true, data: res }));
        triggerToaster("Registration successful", "success");
        navigate(
          `/forgotPassword?title=Verify email&email=${getValues(
            "email"
          )}&name=${getValues("name")}`
        );
      } else {
        triggerToaster(response.data || "Invalid data", "error");
      }
      setLoading(false);
    });
  };

  return (
    <Box as="form" onSubmit={handleSubmit(handleRegister)}>
      <Stack spacing={3} pb={4} pt={2}>
        <FormControl
          sx={{
            display: "flex",
            flexDirection: "row",
            border: (theme) => `1px solid ${theme.palette.grey[1000]}`,
            padding: "4px 14px",
            borderRadius: 1,
            marginBottom: 3,
          }}
        >
          <FormLabel sx={{ alignSelf: "center", marginRight: 2 }}>
            Register as
          </FormLabel>
          <Controller
            control={control}
            name="role"
            defaultValue={"admin"}
            render={({ field: { onChange, value } }) => (
              <RadioGroup
                name="controlled-radio-buttons-group"
                value={value}
                onChange={onChange}
                sx={{ display: "flex", flexDirection: "row" }}
              >
                <FormControlLabel
                  value={"admin"}
                  control={<Radio />}
                  label="Institute"
                />
                <FormControlLabel
                  value={"student"}
                  control={<Radio />}
                  label="Student"
                />
                <FormControlLabel
                  value={"teacher"}
                  control={<Radio />}
                  label="Teacher"
                />
              </RadioGroup>
            )}
          />
        </FormControl>
        <Box>
          <Controller
            control={control}
            name="name"
            render={({ field: { onChange, value } }) => (
              <TextField
                sx={{ width: "100%" }}
                label="Name *"
                onChange={onChange}
                value={value}
              />
            )}
          />
          <ErrorMsg msg={errors?.name?.message} />
        </Box>
        <Box>
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, value } }) => (
              <TextField
                sx={{ width: "100%" }}
                label="Email *"
                onChange={onChange}
                value={value}
              />
            )}
          />
          <ErrorMsg msg={errors?.email?.message} />
        </Box>
        <Box>
          <Controller
            control={control}
            name="password"
            render={({ field: { onChange, value } }) => (
              <TextField
                label="Password *"
                sx={{ width: "100%" }}
                type={showPassword ? "text" : "password"}
                onChange={onChange}
                value={value}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        <Iconify
                          icon={
                            showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <ErrorMsg msg={errors?.password?.message} />
        </Box>
        <Box>
          <Controller
            control={control}
            name="mobile"
            render={({ field: { onChange, value } }) => (
              <TextField
                sx={{ width: "100%" }}
                label="Mobile *"
                onChange={onChange}
                value={value}
                type="number"
              />
            )}
          />
          <ErrorMsg msg={errors?.mobile?.message} />
        </Box>
      </Stack>
      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        disabled={!isDirty}
        loading={loading}
      >
        Register
      </LoadingButton>
      <Typography variant="body2" sx={{ mt: 1 }}>
        Already have an account?
        <Button
          variant="text"
          sx={{ textDecoration: "underline" }}
          onClick={handleLogin}
        >
          Login
        </Button>
      </Typography>
    </Box>
  );
}
