import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { ApiCaller, validateError } from "utils";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { CustomButton, CustomLoader, DatePicker } from "components";
import { useTranslation } from "react-i18next";
import useResponsive from "hooks/useResponsive";
import { DEFAULT_USER_PROFILE } from "utils/constants";
import { useToaster } from "hooks";

const StaffAttendence = () => {
  const [staff, setStaff] = useState([]);
  const [attendance, setAttendance] = useState([]); // State to store attendance
  const [loading, setLoading] = useState(false);
  const [attendanceLoading, setAttendanceLoading] = useState(false);
  const staffLists = staff?.data || [];
  const userData = useSelector((state) => state?.loginData);
  const triggerToaster = useToaster();

  const { t } = useTranslation();
  const mdUp = useResponsive("up", "md");
  const theme = useTheme();

  const { control, watch, getValues } = useForm({
    mode: "onTouched",
  });

  // Watch for role selection

  const date = watch("date");

  useEffect(() => {
    setLoading(true);
    ApiCaller(`/staffs?pageNo=1&size=500`, "get").then((response) => {
      if (response.success) {
        setStaff(response); // Store the staff data
        const formattedRes = response.data.map((staff) => ({
          staffId: staff._id,
          status: "", // Initialize attendance with no status
        }));

        setAttendance(formattedRes);
        fetchPreviouslySavedAttendances(formattedRes);
        setLoading(false);
      }
    });
  }, [date]);

  const findStatus = (previouslySavedAttendance, staffId) => {
    const matchedAttendance = previouslySavedAttendance.find(
      (item) => item?.staffId?._id === staffId
    );
    return matchedAttendance?.status;
  };

  const fetchPreviouslySavedAttendances = (formattedStudentAttendance) => {
    const date = new Date(watch("date")).toISOString();
    ApiCaller(`/staff-attendance?date=${date}`, "get").then((response) => {
      if (response.success) {
        const attendanceClone = [...formattedStudentAttendance];
        const newAttendance = attendanceClone.map((item, index) => {
          return {
            staffId: item.staffId,
            status: findStatus(response?.data, item.staffId),
          };
        });
        setAttendance(newAttendance);
      }
    });
  };

  // Function to handle attendance status update
  const handleAttendance = (staffId, status) => {
    setAttendance((prevAttendance) =>
      prevAttendance.map((item) =>
        item.staffId === staffId ? { ...item, status } : item
      )
    );
  };

  const handleReset = () => {
    setAttendance(
      staff.data.map((staff) => ({
        staffId: staff._id,
        status: "", // Reset attendance
      }))
    );
  };

  const handleSubmit = () => {
    const formattedData = attendance?.filter((item) => !!item?.status);
    setAttendanceLoading(true);
    try {
      ApiCaller("/staff-attendance", "post", {
        attendances: formattedData,
        date: getValues("date"),
      }).then((response) => {
        if (response?.success) {
          triggerToaster("Attendance records added successfully.", "success");
        } else {
          triggerToaster(validateError(response?.data), "error");
        }
        setAttendanceLoading(false);
      });
    } catch (error) {
      console.log("error", error);
      triggerToaster("Something went wrong!", "error");
      setAttendanceLoading(false);
    }
  };

  return (
    <Box sx={{ padding: "16px" }}>
      <Typography variant="h4" sx={{ mb: 2 }}>
        Staff Attendance
      </Typography>

      {/* Role Dropdown */}
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={mdUp ? 2 : 12} md={2}>
          <Controller
            control={control}
            name="date"
            defaultValue={new Date()}
            render={({ field: { onChange, value } }) => (
              <DatePicker
                label="Select Date"
                value={value}
                onChange={onChange}
                disabled={userData?.data?.role === "admin" ? false : true}
                renderInput={(params) => <TextField {...params} />}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={mdUp ? 0.9 : 12}
          md={mdUp ? 0 : 2}
          style={{ height: "45px" }}
        >
          <CustomButton
            variant="contained"
            fullWidth={mdUp ? false : true}
            onClick={handleSubmit}
            sx={{ mb: { xs: 2, md: 2 } }}
            title={"Submit"}
            size="medium"
            loading={attendanceLoading}
          />
        </Grid>
        <Grid item xs={mdUp ? 0.9 : 12} md={mdUp ? 0 : 2}>
          <Button
            variant="contained"
            color="error"
            style={{ marginLeft: mdUp ? 8 : 0 }}
            fullWidth={mdUp ? false : true}
            onClick={handleReset}
          >
            Reset
          </Button>
        </Grid>
      </Grid>

      {/* Staff List */}
      {loading ? (
        <Box mt={4}>
          <CustomLoader loading={loading} />
        </Box>
      ) : (
        <Grid container spacing={2} sx={{ mt: 3 }}>
          {staffLists.map((row) => {
            const staffAttendance = attendance.find(
              (a) => a.staffId === row._id
            );

            return (
              <Grid item xs={12} sm={6} md={4} lg={2.3} key={row._id}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "10px",
                    marginLeft: mdUp ? "0px" : "20px",
                    backgroundColor: "#fff",
                    border: `1px solid ${theme.palette.grey[200]}`,
                    borderRadius: "10%",
                    height: "100%",
                    width: mdUp ? "100%" : "90%",
                    boxShadow: theme.customShadows.z8,
                  }}
                >
                  <Avatar
                    sx={{
                      width: mdUp ? "100px" : "185px",
                      height: mdUp ? "100px" : "185px",
                      border: "2px solid #fff",
                      mb: 1,
                    }}
                    src={row.profileUrl || DEFAULT_USER_PROFILE}
                    alt="profile"
                  />
                  <Typography>{row.name}</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      gap: 2,
                      mt: 1,
                    }}
                  >
                    <Avatar
                      sx={{
                        backgroundColor:
                          staffAttendance?.status === "A" ? "red" : "#fff",
                      }}
                    >
                      <Button
                        variant="text"
                        onClick={() => handleAttendance(row._id, "A")}
                        sx={{
                          color:
                            staffAttendance?.status === "A" ? "#fff" : "black",
                        }}
                      >
                        A
                      </Button>
                    </Avatar>
                    <Avatar
                      sx={{
                        backgroundColor:
                          staffAttendance?.status === "P" ? "green" : "#fff",
                      }}
                    >
                      <Button
                        variant="text"
                        onClick={() => handleAttendance(row._id, "P")}
                        sx={{
                          color:
                            staffAttendance?.status === "P" ? "#fff" : "black",
                        }}
                      >
                        P
                      </Button>
                    </Avatar>
                    <Avatar
                      sx={{
                        backgroundColor:
                          staffAttendance?.status === "L" ? "#FFC72C" : "#fff",
                      }}
                    >
                      <Button
                        variant="text"
                        onClick={() => handleAttendance(row._id, "L")}
                        sx={{
                          color:
                            staffAttendance?.status === "L" ? "#fff" : "black",
                        }}
                      >
                        L
                      </Button>
                    </Avatar>
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      )}
    </Box>
  );
};

export default StaffAttendence;
