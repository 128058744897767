import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Box,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Iconify, ErrorMsg } from "components";
import { resetPasswordSchema } from "./testSchema";
import { ApiCaller } from "utils";
import { useToaster } from "hooks";
import { useState } from "react";
import { MuiOtpInput } from "mui-one-time-password-input";

export default function ResetPasswordForm() {
  const navigate = useNavigate();
  const triggerToaster = useToaster();
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showPassword, setShowPassword] = useState(false);

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(resetPasswordSchema),
  });

  const handleReset = () => {
    setLoading(true);
    const body = {
      email: searchParams.get("email"),
      ...getValues(),
      otpId: searchParams.get("otpId"),
      otp: +getValues("otp"),
      userId: searchParams.get("userId"),
    };
    ApiCaller("/resetPassword", "post", body).then((response) => {
      if (response?.success) {
        triggerToaster("Password reset successfully. Please login!", "success");
        navigate("/login", { replace: true });
      } else {
        triggerToaster(response.data || "Invalid data", "error");
      }
      setLoading(false);
    });
  };

  return (
    <Box as="form" onSubmit={handleSubmit(handleReset)}>
      <Stack spacing={3} pb={4} pt={2}>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography sx={{ marginRight: 4 }}>OTP</Typography>
          <Controller
            control={control}
            name="otp"
            render={({ field: { onChange, value } }) => (
              <MuiOtpInput value={value} onChange={onChange} />
            )}
          />
          <ErrorMsg msg={errors?.otp?.message} />
        </Box>
        <Box>
          <Controller
            control={control}
            name="password"
            render={({ field: { onChange, value } }) => (
              <TextField
                label="New password *"
                sx={{ width: "100%" }}
                type={showPassword ? "text" : "password"}
                onChange={onChange}
                value={value}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        <Iconify
                          icon={
                            showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <ErrorMsg msg={errors?.password?.message} />
        </Box>
        <Box>
          <Controller
            control={control}
            name="confirmPassword"
            render={({ field: { onChange, value } }) => (
              <TextField
                label="Confirm password *"
                sx={{ width: "100%" }}
                type={showPassword ? "text" : "password"}
                onChange={onChange}
                value={value}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        <Iconify
                          icon={
                            showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <ErrorMsg msg={errors?.password?.message} />
        </Box>
      </Stack>
      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        disabled={!isDirty}
        loading={loading}
      >
        Change password
      </LoadingButton>
    </Box>
  );
}
