import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Input,
  IconButton,
  useTheme,
  CircularProgress,
  Avatar,
  Tooltip,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import useResponsive from "hooks/useResponsive";
import { ApiCaller } from "utils";
import { CustomButton } from "components";
import { useToaster } from "hooks";
import { Download } from "@mui/icons-material";
import fetch from "cross-fetch";
import { URL as RootUrl } from "utils/axiosApiCaller";

const ChatGPTIntegration = () => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [chatId, setChatId] = useState(null);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const chatEndRef = useRef(null);
  const fileInputRef = useRef(null);
  const mdUp = useResponsive("up", "md");
  const triggerToaster = useToaster();

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    e.target.value = "";
  };

  const formatMessage = (message) => {
    if (message) {
      const formattedText = message
        .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>") // Bold text
        .replace(/```([\s\S]*?)```/g, (match, p1) => {
          const codeWithLineBreaks = p1.replace(/<br\s*\/?>/g, "\n");
          return `<pre style="white-space: pre-wrap; background-color: #000; color: #fff; padding: 10px; border-radius: 5px;"><code>${codeWithLineBreaks
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")}</code></pre>`;
        })
        .replace(/##\s*(.*?)(\n|$)/g, "<h3>$1</h3>$2")
        .replace(/\n/g, "<br />");

      return { __html: formattedText };
    }
    return { __html: message };
  };

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    triggerToaster("Copied to clipboard!", "success");
  };

  const sendMessage = async () => {
    if (!inputMessage && !selectedFile) return;
    setLoading(true);

    if (selectedFile) {
      const imageMessage = {
        role: "user",
        content: URL.createObjectURL(selectedFile),
        type: "image",
      };
      setMessages((prev) => [...prev, imageMessage]);
    }
    setInputMessage("");

    try {
      const formData = new FormData();
      if (selectedFile) formData.append("file", selectedFile);
      formData.append("message", inputMessage);
      formData.append("chatId", chatId);

      const response = await ApiCaller(
        "/text-extraction",
        "post",
        formData,
        true
      );

      if (response?.message) {
        setMessages((prev) => [
          ...prev,
          { role: "assistant", content: response.message },
        ]);
      } else {
        setMessages((prev) => [
          ...prev,
          { role: "assistant", content: "Opps, Something went wrong!" },
        ]);
      }

      if (!chatId) setChatId(response.chatId);
    } catch (error) {
      console.error("Error:", error);
    }

    setSelectedFile(null);
    setLoading(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Function to get MIME type based on file extension
  const getFileTypeMime = (fileType) => {
    switch (fileType) {
      case "pdf":
        return "application/pdf";
      case "word":
        return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      case "txt":
        return "text/plain";
      default:
        return "";
    }
  };

  const downloadFile = async (text, fileType) => {
    try {
      const store = JSON.parse(localStorage.getItem("persist:root"));
      const loginData = JSON.parse(store?.loginData);

      const token = loginData?.data?.token;

      const response = await fetch(`${RootUrl}/text-to-file`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
        body: JSON.stringify({ text, fileType }),
      });

      if (response.ok) {
        const buffer = await response.arrayBuffer(); // Get the file buffer

        if (fileType === "word") {
          // Convert buffer to normal text for Word
          const textBlob = new Blob([buffer], {
            type: "application/msword", // MIME type for Word documents
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(textBlob);
          link.download = `output.${fileType === "word" ? "doc" : fileType}`;
          link.click();
          URL.revokeObjectURL(link.href);
        } else {
          const blob = new Blob([buffer], { type: getFileTypeMime(fileType) });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `output.${fileType}`;
          link.click();
          URL.revokeObjectURL(link.href);
        }
      } else {
        console.error("Failed to download the file:", response.statusText);
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleTextFieldFocus = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Simulate a click to open the file selection dialog
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: mdUp ? "83vh" : "90vh",
        width: mdUp ? "80%" : "100%", // Ensure the input is pinned to the bottom
        position: "fixed",
      }}
    >
      {/* Chat Window */}
      <Box
        sx={{
          flex: 1,
          overflowY: "auto",
          backgroundColor: "#fff",
          border: `1px dashed ${theme.palette.grey[400]}`,
          padding: 2,
          borderRadius: 2,
          width: !mdUp ? "95%" : "100%",
          marginLeft: !mdUp && 1,
        }}
      >
        {messages.map((message, index) => (
          <Box
            key={index}
            style={{
              display: "flex",
              justifyContent:
                message.role === "user" ? "flex-end" : "flex-start",
            }}
          >
            <Paper
              sx={{
                marginBottom: 2,
                padding: 2,
                backgroundColor:
                  message.role === "user" ? "#d1e7ff" : "#f1f1f1",
                alignSelf: message.role === "user" ? "flex-end" : "flex-start",
                maxWidth: "90%",
                minWidth: mdUp ? "40%" : "90%",
                display: "flex",
                position: "relative",
                paddingBottom: 5,
              }}
            >
              {/* Copy Icon */}
              {message.role !== "user" && (
                <Box>
                  <Tooltip title="Copy to clipboard">
                    <IconButton
                      size="small"
                      onClick={() => copyToClipboard(message.content)}
                      sx={{ position: "absolute", top: 4, right: 4 }}
                    >
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  {/* Add Buttons for downloading files */}
                </Box>
              )}

              {message.type === "image" ? (
                <Avatar
                  variant="square"
                  src={message.content}
                  alt="User Uploaded"
                  sx={{ width: 100, height: 100 }}
                />
              ) : (
                <Typography
                  dangerouslySetInnerHTML={formatMessage(message.content)}
                  maxWidth={"90%"}
                  sx={{
                    whiteSpace: "normal", // Ensures proper text wrapping
                  }}
                />
              )}
              {message.role !== "user" && (
                <Box sx={{ position: "absolute", bottom: 0, right: 12 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Download />}
                    onClick={() => downloadFile(message.content, "txt")}
                  >
                    Text
                  </Button>

                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<Download />}
                    style={{ marginLeft: 5 }}
                    onClick={() => downloadFile(message.content, "word")}
                  >
                    Word
                  </Button>

                  <Button
                    variant="contained"
                    color="error"
                    startIcon={<Download />}
                    style={{ marginLeft: 5 }}
                    onClick={() => downloadFile(message.content, "pdf")}
                  >
                    PDF
                  </Button>
                </Box>
              )}
            </Paper>
          </Box>
        ))}
        <div ref={chatEndRef} />
      </Box>

      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", margin: 1 }}>
          <CircularProgress size={24} loading={loading} />
        </Box>
      )}

      {/* Input Area */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          paddingTop: 2,
          flexDirection: mdUp ? "row" : "column",
          width: "100%",
          position: "sticky",
          bottom: 0, // Keep it at the bottom
          marginTop: !mdUp && 2,
        }}
      >
        <TextField
          fullWidth
          multiline
          maxRows={4}
          value={inputMessage}
          onKeyPress={handleKeyPress}
          placeholder="Attach File"
          variant="outlined"
          size="small"
          onFocus={handleTextFieldFocus} // Trigger file selection on focus
          style={{ backgroundColor: "white", width: !mdUp && "97%" }}
          sx={{ flexGrow: 1 }}
          InputProps={{
            endAdornment: (
              <>
                {selectedFile && (
                  <Avatar
                    variant="square"
                    src={URL.createObjectURL(selectedFile)}
                    alt="Preview"
                    sx={{ width: 50, height: 50, marginRight: 1 }}
                  />
                )}
                <IconButton color="primary" component="label">
                  <UploadFileIcon />
                  <Input
                    type="file"
                    accept=".jpeg,.jpg,.png" // Only allow JPEG, JPG, PNG file types
                    ref={fileInputRef} // Ref for the file input
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file) {
                        const fileType = file.type;
                        const fileSize = file.size;
                        // Validate file size
                        if (fileSize > 2 * 1024 * 1024) {
                          triggerToaster(
                            "File size should not be greater than 2MB.",
                            "errro"
                          );
                          return;
                        }
                        // Validate file type
                        if (
                          !["image/jpeg", "image/png", "image/jpg"].includes(
                            fileType
                          )
                        ) {
                          triggerToaster(
                            "Only JPEG, JPG, and PNG files are allowed.",
                            "error"
                          );
                          return;
                        }
                        handleFileChange(e); // Call your existing file change handler
                      }
                    }}
                    sx={{ display: "none" }} // Hide the input
                  />
                </IconButton>
              </>
            ),
          }}
        />
        <CustomButton
          variant="contained"
          color="primary"
          onClick={sendMessage}
          endIcon={<SendIcon />}
          style={{
            marginTop: !mdUp && 10,
            width: !mdUp ? "97%" : "10%",
            marginLeft: mdUp && 8,
          }}
          title={"Send"}
          size="medium"
          loading={loading}
        />

        <Button
          variant="outlined"
          color="secondary"
          onClick={() => setMessages([])}
          style={{ marginLeft: 4, minWidth: 100 }}
          sx={{
            marginTop: !mdUp && 1,
            width: !mdUp && "97%",
            marginBottom: !mdUp && 1,
          }}
        >
          New Chat
        </Button>
      </Box>
    </Box>
  );
};

export default ChatGPTIntegration;
