import { useDispatch, useSelector } from "react-redux";
import { setCurrentUserAction } from "redux/currentUser";
import { ApiCaller } from "utils";
import { useSetting } from "./useSetting";

export const useCurrentUser = () => {
  const dispatch = useDispatch();
  const triggerSetting = useSetting();

  const triggerCurrentuser = () => {
    ApiCaller(`/currentuser`, "get").then((response) => {
      if (response) {
        dispatch(setCurrentUserAction({ data: response, value: true }));
        triggerSetting(response?._id);
      }
    });
  };
  return triggerCurrentuser;
};
