import * as Yup from "yup";

const addStaffSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  mobile: Yup.number()
    .required("Mobile is required")
    .typeError("Enter a valid mobile number")
    .test(
      "len",
      "Enter a valid mobile number",
      (val) => val?.toString().length >= 10
    ),
  adharNo: Yup.string()
    .typeError("Enter a valid adhar number")
    .test(
      "len",
      "Enter a valid adhar number",
      (val) => val?.toString().length === 12 || val?.toString().length === 0
    ),
  email: Yup.string()
    .email("Please enter valid email")
    .required("Email is required")
    .test("Validate Email", "Please enter valid email", (value) => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(value).toLowerCase());
    }),
  joiningDate: Yup.date()
    .typeError("Invalid date")
    .required("Joining date is required"),
  role: Yup.string().required("Role is required"),
});

export { addStaffSchema };
