import PropTypes from "prop-types";
import { NavLink as RouterLink } from "react-router-dom";
import {
  Box,
  Divider,
  List,
  ListItemText,
  Collapse,
  useTheme,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useState } from "react";

import { StyledNavItem, StyledNavItemIcon } from "./styles";
import { getBgColor } from "utils";

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item, index) => (
          <NavItem key={item.title} item={item} index={index} />
        ))}
      </List>
    </Box>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item, index }) {
  const [open, setOpen] = useState(false);
  const { title, path, icon, children } = item;
  const theme = useTheme();

  const handleClick = () => {
    if (children) {
      setOpen(!open); // Toggle the expand/collapse state
    }
  };

  // If the item has children, it's a collapsible section (e.g., "Administration")
  const isExpandable = Boolean(children);

  return (
    <Box>
      {(path === "/login" || path === "/dashboard/app") && (
        <Divider sx={{ borderStyle: "dashed" }} />
      )}

      {/* For expandable items like "Administration" */}
      {isExpandable ? (
        <StyledNavItem
          onClick={handleClick}
          sx={{
            cursor: "pointer",
            "&.active": {
              color: "text.white",
              backgroundImage:
                "linear-gradient(90deg, #ff7b00 0%, #ffbb00 100%)",
              fontWeight: "fontWeightBold",
            },
            backgroundColor: index % 2 === 0 ? "#fff" : "#fdfdff",
          }}
        >
          <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
          <ListItemText disableTypography primary={title} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </StyledNavItem>
      ) : (
        /* For regular navigation items */
        <StyledNavItem
          component={RouterLink}
          to={path}
          sx={{
            "&.active": {
              color: "text.white",
              backgroundImage: getBgColor(theme.palette.primary.main, 0.9, 0.1),
              fontWeight: "fontWeightBold",
            },
            backgroundColor: index % 2 === 0 ? "#fff" : "#fdfdff",
          }}
        >
          <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
          <ListItemText disableTypography primary={title} />
        </StyledNavItem>
      )}

      {/* Nested items rendering */}
      {isExpandable && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((child, childIndex) => (
              <Box ml={2}>
                <NavItem key={child.title} item={child} index={childIndex} />
              </Box>
            ))}
          </List>
        </Collapse>
      )}
    </Box>
  );
}
