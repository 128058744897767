import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { ErrorMsg } from "components";
import useResponsive from "hooks/useResponsive";
import { useSelector } from "react-redux";
import { DEFAULT_USER_PROFILE } from "utils/constants";
import EditIcon from "@mui/icons-material/Edit";
import { useCurrentUser, useToaster, useUploadFile } from "hooks";
import { ApiCaller } from "utils";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const editProfileSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Please enter valid email")
    .required("Email is required")
    .test("Validate Email", "Please enter valid email", (value) => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(value).toLowerCase());
    }),
  mobile: Yup.number()
    .required("Mobile is required")
    .typeError("Invalid Mobile")
    .test("len", "Enter a valid mobile number", (val) => {
      return val?.toString()?.length === 10;
    }),
  pinCode: Yup.string()
    .nullable()
    .typeError("Invalid Pin Code")
    .test("len", "Pin Code should be 6 numbers", (val) => {
      return val?.length === 6;
    }),
});

const EditProfile = () => {
  const [loading, setLoading] = useState(false);

  const currentuser = useSelector((state) => state.currentUser);

  const { handleUploadFile, uploadedFileUrl, progress } = useUploadFile();
  const triggerCurrentuser = useCurrentUser();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const theme = useTheme();
  const triggerToaster = useToaster();

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(editProfileSchema),
  });

  const mdUp = useResponsive("up", "md");

  const handleUpdate = () => {
    setLoading(true);

    const body = {
      ...getValues(),
      profileUrl: uploadedFileUrl
        ? uploadedFileUrl
        : currentuser?.data?.profileUrl,
    };
    ApiCaller(`/user/${currentuser?.data?._id}`, "put", body).then(
      (response) => {
        if (response?.success) {
          triggerCurrentuser();
          triggerToaster(t("profileIsUpdatedSuccessfully"), t("success"));
          navigate("/dashboard/app", { replace: true });
        } else {
          triggerToaster(response.data || t("invalidData"), t("error"));
        }
        setLoading(false);
      }
    );
  };

  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      handleUploadFile(file);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(handleUpdate)}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: 800,
        mx: "auto",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        border: `1px solid ${theme.palette.grey[100]}`,
        padding: 2,
        borderRadius: 2,
      }}
    >
      <Typography variant="h3" sx={{ mb: 2 }}>
        {t("updateProfile")}
      </Typography>
      {progress > 0 && (
        <LinearProgress
          variant="determinate"
          value={progress}
          style={{ marginTop: 10, marginBottom: 10 }}
        />
      )}
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={6} display="flex" justifyContent="center">
          <Box sx={{ position: "relative", textAlign: "center" }}>
            <img
              style={{
                height: 170,
                width: 170,
                borderRadius: 85,
              }}
              alt="Staff profile"
              src={
                uploadedFileUrl
                  ? uploadedFileUrl
                  : currentuser?.data?.profileUrl
                  ? currentuser?.data?.profileUrl
                  : DEFAULT_USER_PROFILE
              }
            />
            <input
              accept="image/*"
              id="profile-image-upload"
              type="file"
              style={{ display: "none" }}
              onChange={handleProfileImageChange}
            />
            <label htmlFor="profile-image-upload">
              <IconButton
                component="span"
                sx={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  backgroundColor: "white",
                  borderRadius: "50%",
                  p: 1,
                }}
              >
                <EditIcon />
              </IconButton>
            </label>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography>{`${t("name")} *`}</Typography>
            <Controller
              control={control}
              name="name"
              defaultValue={currentuser?.data?.name}
              render={({ field: { onChange, value } }) => (
                <TextField
                  sx={{ width: "100%" }}
                  placeholder={t("enterYourName")}
                  onChange={onChange}
                  value={value}
                  error={!!errors?.name}
                />
              )}
            />
            <ErrorMsg msg={errors?.name?.message} />
          </Box>
          <Box mt={2}>
            <Typography>{`${t("mobileNo")} *`}</Typography>
            <Controller
              control={control}
              name="mobile"
              defaultValue={currentuser?.data?.mobile}
              render={({ field: { onChange, value } }) => (
                <TextField
                  sx={{ width: "100%" }}
                  placeholder={t("enterYourMobileNo")}
                  onChange={onChange}
                  type="number"
                  value={value}
                  error={!!errors?.mobile}
                />
              )}
            />
            <ErrorMsg msg={errors?.mobile?.message} />
          </Box>
          <Box mt={2}>
            <Typography>{`${t("email")} *`}</Typography>
            <Controller
              control={control}
              name="email"
              defaultValue={currentuser?.data?.email}
              render={({ field: { onChange, value } }) => (
                <TextField
                  sx={{ width: "100%" }}
                  placeholder={t("enterYourEmail")}
                  onChange={onChange}
                  value={value}
                  disabled
                />
              )}
            />
            <ErrorMsg msg={errors?.email?.message} />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box>
            <Typography>{t("gender")}</Typography>
            <Controller
              control={control}
              name="gender"
              defaultValue={currentuser?.data?.gender}
              render={({ field: { onChange, value } }) => (
                <RadioGroup
                  name="controlled-radio-buttons-group"
                  value={value}
                  size="small"
                  onChange={onChange}
                  sx={{ display: "flex", flexDirection: "row" }}
                >
                  <FormControlLabel
                    value={"Male"}
                    control={<Radio />}
                    label={t("male")}
                  />
                  <FormControlLabel
                    value={"Female"}
                    control={<Radio />}
                    label={t("female")}
                  />
                </RadioGroup>
              )}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography>{t("qualification")}</Typography>
            <Controller
              control={control}
              name="qualification"
              defaultValue={currentuser?.data?.qualification}
              render={({ field: { onChange, value } }) => (
                <TextField
                  sx={{ width: "100%" }}
                  placeholder={t("enterYourQualification")}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} mt={mdUp ? -2 : 0}>
          <Box>
            <Typography>{t("pinCode")} </Typography>
            <Controller
              control={control}
              name="pinCode"
              defaultValue={currentuser?.data?.pinCode}
              render={({ field: { onChange, value } }) => (
                <TextField
                  sx={{ width: "100%" }}
                  placeholder={t("enterYourPinCode")}
                  onChange={onChange}
                  type={"number"}
                  value={value}
                />
              )}
            />
            <ErrorMsg msg={errors?.pinCode?.message} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography>{t("address")} </Typography>
            <Controller
              control={control}
              name="address"
              defaultValue={currentuser?.data?.address}
              render={({ field: { onChange, value } }) => (
                <TextField
                  sx={{ width: "100%", mb: 2 }}
                  placeholder={t("enterYourAddress")}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Box>
        </Grid>
      </Grid>
      {/* <Button
        variant="contained"
        sx={{
          width: "100%",
          mt: 1,
          py: 2,
          fontSize: 16,
        }}
        type="submit"
        disabled={!isDirty}
        loading={true}
      >
        Update
      </Button> */}

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        disabled={!isDirty && !uploadedFileUrl}
        loading={loading}
      >
        {t("update")}
      </LoadingButton>
    </Box>
  );
};

export default EditProfile;
