import PropTypes from "prop-types";
import { alpha, styled } from "@mui/material/styles";
import { Card, Divider, Stack, Typography } from "@mui/material";
import Iconify from "components/Iconify";
import { getBgColor, moneyFormat } from "utils";

const StyledIcon = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(6),
  height: theme.spacing(6),
  justifyContent: "center",
  marginBottom: theme.spacing(1.5),
}));

AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  sx: PropTypes.object,
};

export default function AppWidgetSummary({
  title,
  total,
  icon,
  color = "primary",
  sx,
  title1,
  title2,
  value1,
  value2,
  ...other
}) {
  return (
    <Card
      sx={{
        py: 2,
        height: "100%",
        boxShadow: 0,
        textAlign: "center",
        backgroundImage: getBgColor(color),
        ...sx,
      }}
      {...other}
    >
      <StyledIcon>
        <img src={`/assets/icons/${icon}.png`} alt={title} />
      </StyledIcon>
      <Typography variant="h5">{moneyFormat(total)}</Typography>

      <Typography variant="subtitle1" sx={{ opacity: 0.72 }}>
        {title}
      </Typography>
      {title1 && (
        <>
          <Divider style={{ marginTop: 4 }} />
          <Stack mt={1} direction={"row"} justifyContent={"center"}>
            <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
              {title1}
            </Typography>
            <Typography
              fontWeight={"bold"}
              sx={{ opacity: 0.72, marginLeft: 1 }}
            >
              {moneyFormat(value1)}
            </Typography>
          </Stack>
          {title2 && (
            <Stack direction={"row"} justifyContent={"center"}>
              <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                {title2}
              </Typography>
              <Typography
                fontWeight={"bold"}
                sx={{ opacity: 0.72, marginLeft: 1 }}
              >
                {moneyFormat(value2)}
              </Typography>
            </Stack>
          )}
        </>
      )}
    </Card>
  );
}
