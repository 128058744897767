import React from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import {
  Modal,
  TextField,
  Button,
  IconButton,
  Typography,
  Box,
  Stack,
} from "@mui/material";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import useResponsive from "hooks/useResponsive";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMsg } from "components";

const additionalSalarySchema = yup.object().shape({
  salaryData: yup.array().of(
    yup.object().shape({
      key: yup.string().required("Reason is required"),
      value: yup
        .number()
        .typeError("Amount must be a number")
        .positive("Amount must be positive")
        .required("Amount is required"),
    })
  ),
});

const AdditionalSalaryDeductionModal = ({
  isOpen,
  onClose,
  passAdditionalData,
  title = "Add Additional Bonus",
}) => {
  const mdUp = useResponsive("up", "md");

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      salaryData: [{ key: "", value: "" }],
    },
    resolver: yupResolver(additionalSalarySchema),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "salaryData",
  });

  const onSubmit = (data) => {
    data?.salaryData?.length && passAdditionalData(data?.salaryData);
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: !mdUp ? "90%" : "50%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h5" mb={2}>
          {title}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          {fields.map((field, index) => (
            <Box
              key={field.id}
              display="flex"
              alignItems="center"
              mb={3}
              gap={2}
            >
              <Box>
                <Controller
                  name={`salaryData[${index}].key`}
                  control={control}
                  defaultValue={field.key}
                  render={({ field }) => (
                    <TextField label="Reason" {...field} fullWidth />
                  )}
                />
                <ErrorMsg msg={errors?.salaryData?.[index]?.key?.message} />
              </Box>
              <Box>
                <Controller
                  name={`salaryData[${index}].value`}
                  control={control}
                  defaultValue={field.value}
                  render={({ field }) => (
                    <TextField
                      label="Amount"
                      {...field}
                      fullWidth
                      type="number"
                    />
                  )}
                />
                <ErrorMsg msg={errors?.salaryData?.[index]?.value?.message} />
              </Box>
              <Stack direction={"row"}>
                <IconButton
                  onClick={() => remove(index)}
                  sx={{ visibility: fields.length === 1 && "hidden" }}
                >
                  <RemoveCircleOutline />
                </IconButton>
                <IconButton
                  onClick={() => append({ key: "", value: "" })}
                  sx={{ visibility: index !== fields.length - 1 && "hidden" }}
                >
                  <AddCircleOutline />
                </IconButton>
              </Stack>
            </Box>
          ))}
          <Box gap={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button variant="outlined" color="error" onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              mt={2}
              disabled={!isDirty}
            >
              Save
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default AdditionalSalaryDeductionModal;
