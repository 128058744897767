import { useEffect, useState } from "react";
import { Stack, TextField, Box, Typography, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useToaster } from "hooks";
import { ErrorMsg, AutoCompleteSearch } from "components";
import { linkInstituteSchema } from "./testSchema";
import { setLoginDataAction } from "redux/authentication";
import { ApiCaller } from "utils";
import useResponsive from "hooks/useResponsive";

export default function LinkInstitute() {
  const [logoUrl, setLogoUrl] = useState("");
  const [signatureUrl, setSignatureUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [instituteData, setInstituteData] = useState({});
  const [isDirtyFile, setIsDirtyFile] = useState(false);
  const userData = useSelector((state) => state?.loginData);
  const mdUp = useResponsive("up", "md");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const triggerToaster = useToaster();
  const isUpdate = !!userData?.data?.instituteId;

  const {
    handleSubmit,
    control,
    getValues,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(linkInstituteSchema),
  });

  useEffect(() => {
    if (userData?.data?.instituteId) {
      ApiCaller(`/institute/${userData?.data?.instituteId}`, "get").then(
        (response) => {
          if (response.success) {
            setInstituteData(response.data);
            setValue("name", response.data?.name);
            setValue("address", response.data?.address);
            setValue("city", response.data?.city);
            setValue("state", response.data?.state);
            setValue("country", response.data?.country);
            setValue("pinCode", response.data?.pinCode);
            setLogoUrl(response?.data?.logo);
            setSignatureUrl(response?.data?.sign);
          }
        }
      );
    }
  }, []);

  const handleAddUpdateInstitute = () => {
    setLoading(true);
    const body = {
      ...getValues(),
      sign: signatureUrl,
      logo: logoUrl,
      userId: userData?.data?._id,
    };

    if (isUpdate) {
      // Update institue API call
      ApiCaller(`/institute/${userData?.data?.instituteId}`, "put", body).then(
        (response) => {
          if (response?.success) {
            triggerToaster("Institute is updated successfully", "success");
          } else {
            triggerToaster(response.data || "Invalid data", "error");
          }
          setLoading(false);
        }
      );
    } else {
      // Add Institute API call
      ApiCaller("/institute", "post", body).then((response) => {
        if (response?.success) {
          const newUserData = { ...userData?.data };
          newUserData.instituteId = response?.data?._id;
          newUserData.instituteNo = response?.data?.id;
          dispatch(setLoginDataAction({ value: true, data: newUserData }));
          triggerToaster("Institue is added successfully", "success");
          navigate("/dashboard/app");
        } else {
          triggerToaster(response.data || "Invalid data", "error");
        }
        setLoading(false);
      });
    }
  };

  return (
    <Stack spacing={3} direction={"row"}>
      <Box
        as="form"
        onSubmit={handleSubmit(handleAddUpdateInstitute)}
        p={4}
        pt={0}
        width="60%"
      >
        <Typography variant="h3" mt={-2}>
          {`${isUpdate ? "Update institute details" : "Link institute"}`}
        </Typography>
        <Stack pb={4} pt={2}>
          <Box mb={4}>
            <Controller
              control={control}
              name="name"
              defaultValue={instituteData?.name || ""}
              render={({ field: { onChange, value } }) => (
                <AutoCompleteSearch />
              )}
            />
            <ErrorMsg msg={errors?.name?.message} />
          </Box>
          <Box mb={4}>
            <Controller
              control={control}
              name="address"
              size="small"
              defaultValue={instituteData?.address || ""}
              render={({ field: { onChange, value } }) => (
                <TextField
                  sx={{ width: "100%" }}
                  label="Roll no *"
                  size="small"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            <ErrorMsg msg={errors?.address?.message} />
          </Box>
        </Stack>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          disabled={!isDirty && !isDirtyFile}
          loading={loading}
        >
          {`${isUpdate ? "Update Institute" : "Link Institute"}`}
        </LoadingButton>
      </Box>
      {mdUp && (
        <Box>
          <img
            src="/assets/images/study.png"
            alt="institite"
            width={"100%"}
            style={{ marginTop: 50 }}
          />
        </Box>
      )}
    </Stack>
  );
}
