import * as Yup from "yup";

const addExpenseSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  usedByName: Yup.string().required("Used by name is required"),
  amount: Yup.number()
    .typeError("Invalid amount")
    .required("Amount is required"),
});

export { addExpenseSchema };
