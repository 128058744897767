import { Popover } from "@mui/material";
import React from "react";

const Tooltip = ({ open, handlePopoverClose, anchorEl, children }) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      {children}
    </Popover>
  );
};

export default Tooltip;
