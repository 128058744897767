import * as Yup from "yup";

const linkInstituteSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .test(
      "len",
      "Name should be greater than 5 letter",
      (val) => val.length >= 6
    ),
  address: Yup.string()
    .required("Address is required")
    .test(
      "len",
      "Address should be greater than 10 letter",
      (val) => val.length >= 6
    ),
  city: Yup.string()
    .required("City is required")
    .test(
      "len",
      "City should be greater than 3 letter",
      (val) => val.length >= 3
    ),
  state: Yup.string().required("State is required"),
  country: Yup.string().required("Country is required"),
  pinCode: Yup.string()
    .required("Pin Code is required")
    .typeError("Invalid Pin Code")
    .test(
      "len",
      "Pin Code should be greater than 5 letter",
      (val) => val.length >= 6
    ),
});

export { linkInstituteSchema };
