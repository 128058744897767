import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  useTheme,
  Avatar,
} from "@mui/material";
import {
  ListHead,
  ListToolbar,
  TablePaginationActions,
  Ribbon,
  LoaderWrapper,
} from "components";
import Iconify from "components/Iconify";
import { ApiCaller, handleCheckBox, validateData } from "utils";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConfirmationBox from "components/ConfirmationBox";
import { DEFAULT_USER_PROFILE } from "utils/constants";
import { useDelay } from "hooks";
import { useTranslation } from "react-i18next";



export default function Staff() {
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [deletedToken, setDeletedToken] = useState("");
  const [isBulkDelete, setIsBulkDelete] = useState(false);
  const [deletedIds, setDeletedIds] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [staff, setStaff] = useState([]);
  const userData = useSelector((state) => state?.loginData);
  const currentUser = useSelector((state) => state?.currentUser);
  const navigation = useNavigate();
  const theme = useTheme();
  const staffList = staff?.data || [];

  const { t } = useTranslation();
  const TABLE_HEAD = [
    { id: "profileUrl", label: t("profile"), alignRight: false },
    { id: "name", label: t("name"), alignRight: false },
    { id: "staffNo", label: t("staffNo"), alignRight: false },
    { id: "mobile", label: t("mobile"), alignRight: false },
    { id: "email", label: t("email"), alignRight: false },
    { id: "role", label: t("role"), alignRight: false },
    { id: "", label: t("action"), alignRight: false },
  ];

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = staffList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleSearchChange = (event) => {
    setPage(0);
    setSearchValue(event.target.value);
  };

  const isNotFound = !staffList.length;

  const searchedValue = useDelay(searchValue, 1000);
  useEffect(() => {
    setLoading(true);
    ApiCaller(
      `/staffs?search=${searchedValue}&pageNo=${page + 1}&size=${rowsPerPage}`,
      "get"
    ).then((response) => {
      if (response.success) {
        setStaff(response);
        setLoading(false);
      }
    });
  }, [deletedToken, page, rowsPerPage, searchedValue]);

  const handleAddBatch = () => {
    navigation("/dashboard/addStaff");
  };

  const handleDelete = () => {
    const selectedStaff = staffList.filter(
      (staff) => selected.includes(staff.name) && staff._id
    );
    const ids = selectedStaff.map((staff) => staff._id);
    onDeleteBatch(ids);
  };

  const onDeleteBatch = (ids) => {
    setDeletedIds(ids);
    setIsBulkDelete(true);
    ApiCaller(`/staff`, "delete", { ids }).then((response) => {
      if (response.success) {
        setTimeout(() => {
          setIsBulkDelete(false);
          setDeletedToken(new Date().getTime().toString());
          setSelected([]);
          setIsModalOpen(false);
        }, 2000);
      }
    });
  };

  const handleEdit = (id, event) => {
    event.stopPropagation();
    navigation(`/dashboard/addStaff?staffId=${id}`);
  };

  const isLoading = (id) => deletedIds.includes(id) && isBulkDelete;

  const handleRow = (id) => {
    navigation(`/dashboard/staff-details?staffId=${id}`);
  };

  return (
    <>
      <Helmet>
        <title> Batch | GurukulGuru.com </title>
      </Helmet>
      <Ribbon message={"Please add institute first to add staff"} />
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
        >
          <Typography variant="h4" gutterBottom>
            {t("staff")}
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={handleAddBatch}
            disabled={!currentUser?.data?.instituteId}
          >
            {t("newStaff")}
          </Button>
        </Stack>

        <Card>
          <ListToolbar
            numSelected={selected.length}
            value={searchValue}
            onChange={handleSearchChange}
            handleDelete={() => {
              setIsModalOpen(true);
            }}
            isBulkDelete={isBulkDelete}
            placeholder={t("searchStaffs")}
          >
            {/*
              Other filter would be here
            */}
          </ListToolbar>

          <TableContainer style={{ overflow: "auto" }}>
            <Table>
              <ListHead
                headLabel={TABLE_HEAD}
                rowCount={staffList.length}
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {staffList.map((row) => {
                  const {
                    name,
                    mobile,
                    email,
                    role,
                    profileUrl,
                    staffNo,
                    _id,
                  } = row;
                  const selectedUser = selected.indexOf(name) !== -1;

                  return (
                    <TableRow
                      hover
                      key={staffNo}
                      tabIndex={-1}
                      role="checkbox"
                      selected={selectedUser}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleRow(_id)}
                    >
                      <TableCell
                        padding="checkbox"
                        p={0.5}
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      >
                        <Checkbox
                          checked={selectedUser}
                          onChange={(event) =>
                            handleCheckBox(event, name, selected, setSelected)
                          }
                        />
                      </TableCell>
                      <TableCell p={0.5}>
                        {profileUrl ? (
                          <Avatar src={row.profileUrl} alt="photoURL" />
                        ) : (
                          <Avatar src={DEFAULT_USER_PROFILE} alt="photoURL" />
                        )}
                      </TableCell>
                      <TableCell align="left" p={0.5}>
                        {validateData(name)}
                      </TableCell>
                      <TableCell align="left" p={0.5}>
                        {validateData(staffNo)}
                      </TableCell>
                      <TableCell align="left" p={0.5}>
                        {validateData(mobile)}
                      </TableCell>
                      <TableCell align="left" p={0.5}>
                        {validateData(email)}
                      </TableCell>
                      <TableCell align="left" p={0.5}>
                        {validateData(role)}
                      </TableCell>

                      <TableCell>
                        <Box>
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={(event) => handleEdit(row?._id, event)}
                          >
                            <Iconify icon={"eva:edit-fill"} />
                          </IconButton>
                          {row?.email !== userData?.data?.email && (
                            <>
                              {!isLoading(row?._id) ? (
                                <IconButton
                                  size="large"
                                  color="inherit"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    setIsModalOpen(true);
                                    setSelected([row?.name]);
                                  }}
                                >
                                  <Iconify
                                    icon={"eva:trash-2-outline"}
                                    sx={{ color: theme.palette.error.dark }}
                                  />
                                </IconButton>
                              ) : (
                                <IconButton size="large" color="inherit">
                                  <Iconify icon="eos-icons:bubble-loading" />
                                </IconButton>
                              )}
                            </>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              {isNotFound && !loading && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          No result found
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              <LoaderWrapper loading={loading} />
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={rowsPerPage}
            component="div"
            count={staff.total ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Card>
        <ConfirmationBox
          handleClose={() => setIsModalOpen(false)}
          handleConfirm={() => {
            handleDelete();
          }}
          loading={isBulkDelete}
          open={isModalOpen}
        />
      </Container>
    </>
  );
}
