import { useEffect, useState } from "react";
import {
  Box,
  useTheme,
  Typography,
  Grid,
  TextField,
  Container,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  TableContainer,
  Card,
  Checkbox,
  IconButton,
  Paper,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

import {
  ErrorMsg,
  Iconify,
  ListHead,
  ListToolbar,
  LoaderWrapper,
  TablePaginationActions,
} from "components";
import { ApiCaller, handleCheckBox, validateData, validateError } from "utils";
import ConfirmationBox from "components/ConfirmationBox";
import { fDate } from "utils/formatTime";
import { useDelay, useToaster } from "hooks";
import { addSchoolKitSchema } from "./testSchema";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";



const SchoolKit = () => {
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deletedToken, setDeletedToken] = useState("");
  const [isBulkDelete, setIsBulkDelete] = useState(false);
  const [deletedIds, setDeletedIds] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [schoolKit, setSchoolKit] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const { t } = useTranslation();

  const TABLE_HEAD = [
    { id: "name", label: t("name"), alignRight: false },
    { id: "amount", label: t("amount"), alignRight: false },
    { id: "quantity", label: t("quantity"), alignRight: false },
    { id: "availableQuantity", label: t("availableQuantity"), alignRight: false },
    { id: "detail", label: t("detail"), alignRight: false },
    { id: "date", label: t("date"), alignRight: false },
    { id: "", label: t("action"), alignRight: false },
  ];

  const triggerToaster = useToaster();

  const theme = useTheme();
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(addSchoolKitSchema),
  });

  const schoolKitList = schoolKit?.data || [];

  const handleDelete = () => {
    const selectedKit = schoolKitList.filter(
      (kit) => selected.includes(kit?.name) && kit._id
    );
    const ids = selectedKit.map((kit) => kit._id);
    onDeleteBatch(ids);
  };

  const onDeleteBatch = (ids) => {
    setDeletedIds(ids);
    setIsBulkDelete(true);
    ApiCaller(`/school-kit`, "delete", { ids }).then((response) => {
      if (response.success) {
        handleDeleteResponse(t("schoolKitIsDeletedSuccessfully"), t("success"));
      } else {
        handleDeleteResponse(t("somethingWentWrong"), t("error"));
      }
    });
  };

  const handleDeleteResponse = (msg, msgType) => {
    setTimeout(() => {
      setIsBulkDelete(false);
      setDeletedToken(new Date().getTime().toString());
      setSelected([]);
      setIsModalOpen(false);
      triggerToaster(msg, msgType);
    }, 2000);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = schoolKitList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const isNotFound = !schoolKitList.length;

  const handleSearchChange = (event) => {
    setPage(0);
    setSearchValue(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const fetchSchoolKitData = () => {
    setLoading(true);
    ApiCaller(
      `/school-kit?search=${searchedValue}&pageNo=${
        page + 1
      }&size=${rowsPerPage}`,
      "get"
    ).then((response) => {
      if (response.success) {
        setSchoolKit(response);
        setLoading(false);
      }
    });
  };

  const searchedValue = useDelay(searchValue, 1000);
  useEffect(fetchSchoolKitData, [
    deletedToken,
    page,
    rowsPerPage,
    searchedValue,
  ]);

  const schoolKitId = selectedData?._id;

  const handleAddUpdateExpense = () => {
    setLoading(true);

    const body = {
      ...getValues(),
    };

    if (schoolKitId) {
      // Update school kit API call
      ApiCaller(`/school-kit/${schoolKitId}`, "put", body).then((response) => {
        if (response?.success) {
          triggerToaster(t("schoolKitIsUpdatedSuccessfully"), t("success"));
          handleAddUpdateRes();
        } else {
          triggerToaster(
            validateError(response.data) || "Invalid data",
            "error"
          );
        }
        setSelectedData({});
        setLoading(false);
      });
    } else {
      // Add schoolkit API call
      ApiCaller("/school-kit", "post", {
        ...body,
        availableQuantity: getValues("quantity"),
      }).then((response) => {
        if (!!response?.success) {
          triggerToaster(t("schoolKitIsAddedSuccessfully"), t("success"));
          handleAddUpdateRes();
        } else {
          triggerToaster(
            response?.message || validateError(response?.data),
            "error"
          );
        }
        setLoading(false);
      });
    }
  };

  const handleAddUpdateRes = () => {
    reset();
    fetchSchoolKitData();
  };

  const isLoading = (id) => deletedIds.includes(id) && isBulkDelete;

  return (
    <>
      <Container>
        <Typography variant="h5">{t("instituteKit")}</Typography>
        <Box
          as="form"
          onSubmit={handleSubmit(handleAddUpdateExpense)}
          style={{
            backgroundColor: theme.palette.common.white,
            padding: 18,
            borderRadius: 10,
            border: `1px solid ${theme.palette.grey[300]}`,
          }}
        >
          <Grid container gap={2} direction={{ xs: "column", md: "row" }}>
            <Grid item xs={1.8}>
              <Controller
                control={control}
                name="name"
                defaultValue={""}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    size="small"
                    sx={{ width: "100%" }}
                    label={`${t("name")} *`}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              <ErrorMsg msg={errors?.name?.message} />
            </Grid>
            <Grid item xs={1.8}>
              <Controller
                control={control}
                name="amount"
                defaultValue={""}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    size="small"
                    sx={{ width: "100%" }}
                    label={`${t("amount")} *`}
                    onChange={onChange}
                    value={value}
                    type="number"
                  />
                )}
              />
              <ErrorMsg msg={errors?.amount?.message} />
            </Grid>
            <Grid item xs={1.8}>
              <Controller
                control={control}
                name="quantity"
                defaultValue={""}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    size="small"
                    sx={{ width: "100%" }}
                    label={t("quantity")}
                    onChange={onChange}
                    value={value}
                    type="number"
                  />
                )}
              />
            </Grid>
            {schoolKitId && (
              <Grid item xs={1.8}>
                <Controller
                  control={control}
                  name="availableQuantity"
                  defaultValue={""}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      size="small"
                      sx={{ width: "100%" }}
                      label={t("availableQuantity")}
                      onChange={onChange}
                      value={value}
                      type="number"
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={1.8}>
              <Controller
                control={control}
                name="detail"
                defaultValue={""}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    size="small"
                    sx={{ width: "100%" }}
                    label={t("detail")}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <LoadingButton
                variant="contained"
                type="submit"
                style={{ alignContent: "right", width: "100%" }}
                loading={loading}
              >

                {schoolKitId ? t("updateInstituteKit") : t("addInstituteKit")}
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
        <Box mt={2}>
          <Card>
            <ListToolbar
              numSelected={selected.length}
              value={searchValue}
              onChange={handleSearchChange}
              handleDelete={() => {
                setIsModalOpen(true);
              }}
              isBulkDelete={isBulkDelete}
              placeholder={t("searchInstituteKit")}
            >
              {/*
              Other filter would be here
            */}
            </ListToolbar>

            <TableContainer sx={{ overflow: "auto" }}>
              <Table>
                <ListHead
                  headLabel={TABLE_HEAD}
                  rowCount={schoolKitList.length}
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {schoolKitList.map((row) => {
                    const {
                      detail,
                      amount,
                      createdAt,
                      name,
                      _id,
                      quantity,
                      availableQuantity,
                    } = row;
                    const selectedUser = selected.indexOf(name) !== -1;

                    return (
                      <TableRow
                        hover
                        key={_id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={selectedUser}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedUser}
                            onChange={(event) =>
                              handleCheckBox(event, name, selected, setSelected)
                            }
                          />
                        </TableCell>
                        <TableCell align="left">{validateData(name)}</TableCell>
                        <TableCell align="left">
                          {validateData(amount)}
                        </TableCell>
                        <TableCell align="left">
                          {validateData(quantity)}
                        </TableCell>
                        <TableCell align="left">
                          {validateData(availableQuantity)}
                        </TableCell>
                        <TableCell align="left">
                          {validateData(detail)}
                        </TableCell>
                        <TableCell align="left">
                          {validateData(fDate(createdAt))}
                        </TableCell>
                        <TableCell>
                          <Box sx={{ width: 70 }}>
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={() => {
                                setSelectedData(row);
                                setValue("name", row?.name);
                                setValue("amount", row?.amount);
                                setValue("detail", row?.detail);
                                setValue("quantity", row?.quantity);
                                setValue(
                                  "availableQuantity",
                                  row.availableQuantity
                                );
                              }}
                              sx={{ padding: 0.6 }}
                            >
                              <Iconify icon={"eva:edit-fill"} />
                            </IconButton>
                            {!isLoading(row?._id) ? (
                              <IconButton
                                size="large"
                                color="inherit"
                                onClick={() => {
                                  setIsModalOpen(true);
                                  setSelected([row?.name]);
                                }}
                                sx={{ padding: 0.6 }}
                              >
                                <Iconify
                                  icon={"eva:trash-2-outline"}
                                  sx={{ color: theme.palette.error.dark }}
                                />
                              </IconButton>
                            ) : (
                              <IconButton size="large" color="inherit">
                                <Iconify icon="eos-icons:bubble-loading" />
                              </IconButton>
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {isNotFound && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            No result found
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                <LoaderWrapper loading={loading} />
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={rowsPerPage}
              component="div"
              count={schoolKit.total ?? 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </Card>
          <ConfirmationBox
            handleClose={() => setIsModalOpen(false)}
            handleConfirm={() => {
              handleDelete();
            }}
            loading={isBulkDelete}
            open={isModalOpen}
          />
        </Box>
      </Container>
    </>
  );
};
export default SchoolKit;
