import { createSlice } from "@reduxjs/toolkit";

export const authentication = createSlice({
  name: "loginData",
  initialState: {
    value: false,
    data: {},
  },
  reducers: {
    verifyUserLoginAction: (state, action) => {
      return { ...state, ...action.payload };
    },
    setLoginDataAction: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { verifyUserLoginAction, setLoginDataAction } =
  authentication.actions;

export default authentication.reducer;
