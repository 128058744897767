/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import {
  Stack,
  TextField,
  Box,
  Typography,
  Grid,
  useTheme,
  Button,
  IconButton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Info } from "@mui/icons-material";

import { useToaster } from "hooks";
import {
  ErrorMsg,
  Dropdown,
  AutoCompleteSearch,
  DatePicker,
  Tooltip,
  ViewOnly,
  CollapseView,
} from "components";
import AdditionalSalaryDeductionModal from "./AdditionalSalaryDeductionModal";
import { addSalarySchema } from "./testSchema";
import {
  getFormattedSalaryBreakUp,
  getFormattedAdditionalPaidBreakUp,
} from "./makeData";
import {
  ApiCaller,
  formatDate,
  getMonthsBetweenDatesAndFees,
  moneyFormat,
} from "utils";
import useResponsive from "hooks/useResponsive";
import SalaryConfirmation from "./SalaryConfirmation";
import { useSelector } from "react-redux";

export default function AddSalary() {
  const [loading, setLoading] = useState(false);
  const [isBonusModalOpen, setIsBonusModalOpen] = useState(false);
  const [isDeductionModalOpen, setIsDeductionModalOpen] = useState(false);
  const [additionalBonus, setAdditionalBonus] = useState([]);
  const [deductionData, setDeductionData] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState({});
  const [totalPayableData, setTotalPayableData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);

  const [isSalaryConfirmation, setIsSalaryConfirmation] = useState(false);
  const userData = useSelector((state) => state?.loginData);

  // const masterData = useSelector((state) => state?.masterData);
  const [searchParams] = useSearchParams();

  const studentId = searchParams.get("studentId");
  const mdUp = useResponsive("up", "md");
  const navigate = useNavigate();
  const triggerToaster = useToaster();
  const theme = useTheme();

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const openDeductionToolTip = Boolean(anchorE2);

  const {
    handleSubmit,
    control,
    getValues,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(addSalarySchema),
  });

  // Get fees duration
  useEffect(() => {
    if (selectedStaff?._id) {
      ApiCaller(`/salary-duration/${selectedStaff?._id}`, "get").then(
        (response) => {
          const { fromDate, toDate } = response?.data;
          setValue("fromDate", new Date(fromDate));
          setValue("toDate", new Date(toDate));
        }
      );
    }
  }, [selectedStaff]);

  // Calculate total due
  useEffect(() => {
    if (selectedStaff?._id) {
      ApiCaller(
        `/get-due-salary/${selectedStaff?._id}?fromDate=${watch(
          "fromDate"
        )?.toISOString()}&toDate=${watch("toDate")?.toISOString()}`,
        "get"
      ).then((response) => {
        if (response.success) {
          setValue("totalCurrentSalaryDue", response.data.dueSalary);
          setTotalPayableData(response.data.dueSalaryBreakUp);
        }
      });
    }
  }, [selectedStaff, watch("fromDate"), watch("toDate")]);

  useEffect(() => {
    if (selectedStaff?._id) {
      ApiCaller(
        `/get-staff-salary-details/${selectedStaff?._id}?fromDate=${watch(
          "fromDate"
        )?.toISOString()}&toDate=${watch("toDate")?.toISOString()}`,
        "get"
      ).then((response) => {
        if (response.success) {
          setValue("staffSalaryDetails", response?.data);
        }
      });
    }
  }, [selectedStaff]);

  // Find total a
  const totalPayableAmount = [...additionalBonus, ...totalPayableData].reduce(
    (acc, additionalFee) => acc + +additionalFee?.value,
    0
  );

  const totalDeductionAmount = deductionData?.reduce(
    (acc, deduction) => acc + deduction?.value,
    0
  );

  const getBonus = () => {
    return additionalBonus?.reduce((acc, bonus) => acc + bonus?.value, 0);
  };

  const handleAddUpdateStudent = () => {
    try {
      setLoading(true);

      const body = {
        fromDate: getValues("fromDate"),
        toDate: getValues("toDate"),
        staffId: selectedStaff?._id,
        paid: getValues("paidAmount"),
        paymentMode: getValues("paymentMode"),
        deduction: getValues("totalDeductionAmount"),
        bonus: getBonus() || 0,
        additionalBonus,
        remainingSalary: getValues("remainingAmount"),
        totalDueBreakup: totalPayableData,
        deductionBreakUp: deductionData,
        paidBy: userData?.data?.name || "",
        remark: getValues("remark"),
      };

      if (selectedStaff?._id) {
        // Submit fees for student
        ApiCaller(`/salary`, "post", body).then((response) => {
          if (response?.success) {
            triggerToaster("Salary is submitted successfully", "success");
            navigate(
              `/dashboard/salaryReceipt?salary=${JSON.stringify(
                response?.data
              )}&staff=${JSON.stringify(selectedStaff)}`
            );
          } else {
            triggerToaster("Something went wrong!, Please try again", "error");
          }
          setLoading(false);
        });
      }
    } catch (error) {
    } finally {
      setIsSalaryConfirmation(false);
    }
  };

  const handleSubmitFees = () => {
    setIsSalaryConfirmation(true);
  };

  const handleSelectedData = useCallback((data) => {
    setSelectedStaff(data);
  }, []);

  const getAdditionalData = (data) => {
    setAdditionalBonus(data);
  };

  const getDeductionData = (data) => {
    setDeductionData(data);
  };

  // Set total due amount
  useEffect(() => {
    const totalCurrentSalaryDue =
      totalPayableAmount - +(totalDeductionAmount || 0);
    setValue("totalCurrentSalaryDue", totalCurrentSalaryDue);
  }, [totalPayableAmount, totalDeductionAmount]);

  // Set total remaining balance
  useEffect(() => {
    const remainingAmount =
      +watch("totalCurrentSalaryDue") - +watch("paidAmount");
    setValue("remainingAmount", remainingAmount);
  }, [+watch("totalCurrentSalaryDue"), +watch("paidAmount")]);

  // Set total totalDeductionAmount when totalDeductionAmount changed
  useEffect(() => {
    setValue("totalDeductionAmount", totalDeductionAmount);
  }, [totalDeductionAmount]);

  // Disable to date if selected date is less than from date and after batch end date
  const shouldDisableDate = (date) => {
    return (
      date !== null &&
      (date < watch("fromDate") ||
        date > new Date(selectedStaff?.batchId?.endDate))
    );
  };

  const getDueMonths = () => {
    const months = getMonthsBetweenDatesAndFees(
      watch("fromDate"),
      watch("toDate")
    );
    return months.map((month) => month?.key).join(", ") ?? "";
  };

  const staffSalaryDetails = watch("staffSalaryDetails");

  return (
    <Stack
      spacing={4}
      direction={"row"}
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box
        as="form"
        onSubmit={handleSubmit(handleSubmitFees)}
        p={4}
        pt={0}
        sx={{
          backgroundColor: theme.palette.common.white,
          width: "100%",
        }}
      >
        <Typography variant="h3" mt={1} mb={1}>
          {`${studentId ? "Update salary details" : "Add Salary"}`}
        </Typography>
        <Stack
          direction={mdUp ? "row" : "column"}
          justifyContent={"space-between"}
          gap={2}
          marginBottom={2}
        >
          <AutoCompleteSearch
            label="Search staff by Name, Staff No."
            passSelectedValue={handleSelectedData}
            sx={{ width: mdUp && selectedStaff ? "60%" : "100%" }}
            searchType="staff"
          />
          {watch("fromDate") && selectedStaff && (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Controller
                  control={control}
                  name="fromDate"
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      fullWidth={false}
                      label="From date *"
                      value={value}
                      onChange={onChange}
                      disabled={true}
                    />
                  )}
                />
                <Box ml={2}>
                  <Controller
                    control={control}
                    name="toDate"
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        fullWidth={false}
                        label="To date *"
                        value={value}
                        onChange={onChange}
                        shouldDisableDate={shouldDisableDate}
                      />
                    )}
                  />
                </Box>
              </Box>
              <Box sx={{ display: "flex", textAlign: "center" }}>
                <Typography
                  sx={{
                    fontSize: 12,
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {getDueMonths()}
                </Typography>
              </Box>
            </Box>
          )}
        </Stack>
        {!!selectedStaff?._id && (
          <>
            <CollapseView
              summary={
                <Grid
                  container
                  sx={{ width: "100%", marginTop: 0 }}
                  direction={{ xs: "column", md: "row" }}
                  spacing={mdUp ? 0 : 1}
                >
                  <Grid item xs={3}>
                    <ViewOnly label={"Name"} value={selectedStaff?.name} />
                  </Grid>
                  <Grid item xs={3}>
                    <ViewOnly
                      label={"Staff No."}
                      value={selectedStaff?.staffNo}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <ViewOnly label={"Mobile"} value={selectedStaff?.mobile} />
                  </Grid>
                  <Grid item xs={3}>
                    <ViewOnly label={"Email"} value={selectedStaff?.email} />
                  </Grid>
                </Grid>
              }
              details={
                <Box>
                  <Grid
                    container
                    direction={{ xs: "column", md: "row" }}
                    spacing={2}
                    mb={2}
                    sx={{ width: "100%" }}
                  >
                    <Grid item xs={3}>
                      <ViewOnly
                        label={"Adhar No."}
                        value={selectedStaff?.adharNo}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <ViewOnly label={"Role"} value={selectedStaff?.role} />
                    </Grid>
                    <Grid item xs={3}>
                      <ViewOnly
                        label={"Joining Date"}
                        value={formatDate(selectedStaff?.joiningDate)}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <ViewOnly
                        label={"Salary Type"}
                        value={selectedStaff?.salaryType}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction={{ xs: "column", md: "row" }}
                    spacing={2}
                    mt={0}
                    sx={{ width: "100%" }}
                  >
                    <Grid item xs={3}>
                      <ViewOnly
                        label={"Salary paid history"}
                        value={moneyFormat(staffSalaryDetails?.totalPaidSalary)}
                        isModalView={true}
                        breakUpData={getFormattedSalaryBreakUp(
                          staffSalaryDetails?.salaries
                        )}
                        headers={[
                          "Date",
                          "Amount",
                          "Deductions",
                          "Additional/Bonus",
                        ]}
                        columnsKeys={[
                          "createdAt",
                          "paidAmount",
                          "deduction",
                          "bonus",
                        ]}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <ViewOnly
                        label={"Additional/Bonus history"}
                        value={moneyFormat(staffSalaryDetails?.totalBonus)}
                        breakUpData={getFormattedAdditionalPaidBreakUp(
                          staffSalaryDetails?.addtionalBonusBreakUp
                        )}
                        headers={["Reason", "Amount"]}
                        columnsKeys={["reason", "amount"]}
                        isModalView={true}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <ViewOnly
                        label={"Deducation"}
                        value={moneyFormat(staffSalaryDetails?.totalDeduction)}
                        breakUpData={getFormattedAdditionalPaidBreakUp(
                          staffSalaryDetails?.totalDeductionBreakUp
                        )}
                        headers={["Reason", "Amount"]}
                        columnsKeys={["reason", "amount"]}
                        isModalView={true}
                      />
                    </Grid>
                  </Grid>
                </Box>
              }
            />
            <Stack pb={2}>
              <Box
                sx={{
                  paddingX: 2,
                  paddingBottom: 2,
                  border: `1px solid ${theme.palette.grey[200]}`,
                }}
              >
                <Grid
                  container
                  spacing={2}
                  mt={1}
                  mb={2}
                  sx={{ width: "100%" }}
                  direction={{ xs: "column", md: "row" }}
                >
                  <Grid item xs={3}>
                    <Controller
                      control={control}
                      name="totalCurrentSalaryDue"
                      defaultValue={0}
                      render={({ field: { _, value } }) => (
                        <TextField
                          sx={{ width: "100%" }}
                          label="Current salary due*"
                          size="small"
                          disabled={true}
                          value={value}
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                aria-label="information"
                                onMouseEnter={handlePopoverOpen}
                              >
                                <Info />
                              </IconButton>
                            ),
                          }}
                        />
                      )}
                    />
                    <Tooltip
                      open={open}
                      anchorEl={anchorEl}
                      handlePopoverClose={handlePopoverClose}
                    >
                      <Grid container spacing={1} width={250} p={2}>
                        {[...totalPayableData, ...additionalBonus]?.map(
                          (row) => (
                            <>
                              <Grid item xs={6}>
                                {row?.key}
                              </Grid>
                              <Grid item xs={6}>
                                {moneyFormat(row?.value)}
                              </Grid>
                            </>
                          )
                        )}
                      </Grid>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      variant="text"
                      onClick={() => setIsBonusModalOpen(true)}
                    >
                      Add additional salary
                    </Button>
                  </Grid>

                  <Grid item xs={3}>
                    <Controller
                      control={control}
                      name="totalDeductionAmount"
                      defaultValue={totalDeductionAmount || 0}
                      render={({ field: { _, value } }) => (
                        <TextField
                          sx={{ width: "100%" }}
                          label="Deduction amount*"
                          size="small"
                          disabled={true}
                          value={value}
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                aria-label="information"
                                onMouseEnter={(event) =>
                                  setAnchorE2(event.currentTarget)
                                }
                              >
                                <Info />
                              </IconButton>
                            ),
                          }}
                        />
                      )}
                    />
                    <Tooltip
                      open={openDeductionToolTip}
                      anchorEl={anchorE2}
                      handlePopoverClose={() => setAnchorE2(null)}
                    >
                      <Grid container spacing={1} width={250} p={2}>
                        {[...deductionData]?.map((row) => (
                          <>
                            <Grid item xs={6}>
                              {row?.key}
                            </Grid>
                            <Grid item xs={6}>
                              {moneyFormat(row?.value)}
                            </Grid>
                          </>
                        ))}
                      </Grid>
                    </Tooltip>
                  </Grid>

                  <Grid item xs={3}>
                    <Button
                      variant="text"
                      onClick={() => setIsDeductionModalOpen(true)}
                    >
                      Add salary deduction
                    </Button>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  mt={1}
                  direction={{ xs: "column", md: "row" }}
                >
                  <Grid item xs={3}>
                    <Controller
                      control={control}
                      name="paidAmount"
                      defaultValue={"0"}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          sx={{ width: "100%" }}
                          label="Pay amount *"
                          size="small"
                          type="number"
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                    <ErrorMsg msg={errors?.paidAmount?.message} />
                  </Grid>
                  <Grid item xs={3}>
                    <Controller
                      control={control}
                      name="remainingAmount"
                      defaultValue={"0"}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          sx={{ width: "100%" }}
                          label="Remaining amount *"
                          size="small"
                          type="number"
                          onChange={onChange}
                          value={value ?? "0"}
                          disabled={true}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Controller
                      control={control}
                      name="paymentMode"
                      defaultValue={"Cash"}
                      render={({ field: { onChange, value } }) => (
                        <Dropdown
                          itemList={[
                            "Cash",
                            "UPI",
                            "Internet banking",
                            "Cheque",
                          ]}
                          label="Mode of salary *"
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Controller
                      control={control}
                      name="remark"
                      defaultValue={""}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          sx={{ width: "100%" }}
                          label="Remark"
                          size="small"
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Stack>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              disabled={!isDirty}
            >
              {`${studentId ? "Update Salary" : "Submit Salary"}`}
            </LoadingButton>
          </>
        )}
        <SalaryConfirmation
          open={isSalaryConfirmation}
          onClose={() => setIsSalaryConfirmation(false)}
          loading={loading}
          onSubmit={handleAddUpdateStudent}
          salaryDetails={getValues()}
          bonus={additionalBonus}
        />
      </Box>
      <AdditionalSalaryDeductionModal
        isOpen={isBonusModalOpen}
        onClose={() => setIsBonusModalOpen(false)}
        passAdditionalData={getAdditionalData}
      />
      <AdditionalSalaryDeductionModal
        isOpen={isDeductionModalOpen}
        title="Add deductions"
        onClose={() => setIsDeductionModalOpen(false)}
        passAdditionalData={getDeductionData}
      />
    </Stack>
  );
}
