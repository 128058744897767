/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import {
  Stack,
  TextField,
  Box,
  Typography,
  Grid,
  useTheme,
  Button,
  IconButton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Info } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import { useToaster } from "hooks";
import {
  ErrorMsg,
  Dropdown,
  AutoCompleteSearch,
  DatePicker,
  Tooltip,
  ViewOnly,
  CollapseView,
  Ribbon,
} from "components";
import AdditionalFeesModal from "./AdditionalFeesModal";
import { addFeesSchema } from "./testSchema";
import {
  getFormattedPaidBreakUp,
  getFormattedAdditionalPaidBreakUp,
  getCourseDueBalance,
} from "./makeData";
import { ApiCaller, getMonthsBetweenDatesAndFees, moneyFormat } from "utils";
import useResponsive from "hooks/useResponsive";
import FeesConfirmation from "./FeesConfirmation";
import { useSelector } from "react-redux";

export default function AddFees() {
  const [loading, setLoading] = useState(false);
  const [isFeesModalOpen, setIsFeesModalOpen] = useState(false);
  const [additionalFees, setAdditionalFees] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState({});
  const [totalPayableData, setTotalPayableData] = useState([]);
  const [institute, setInstitute] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [isFeesConfirmation, setIsFeesConfirmation] = useState(false);
  const userData = useSelector((state) => state?.loginData);

  // const masterData = useSelector((state) => state?.masterData);
  const [searchParams] = useSearchParams();

  const studentId = searchParams.get("studentId");
  const mdUp = useResponsive("up", "md");
  const navigate = useNavigate();
  const triggerToaster = useToaster();
  const theme = useTheme();
  const { t } = useTranslation();

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const {
    handleSubmit,
    control,
    getValues,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(addFeesSchema),
  });

  // Get fees duration
  useEffect(() => {
    if (selectedStudent?._id) {
      ApiCaller(
        `/fees-duration/${selectedStudent?._id}?batchId=${selectedStudent?.batchId?._id}`,
        "get"
      ).then((response) => {
        const { fromDate, toDate } = response?.data;
        setValue("fromDate", new Date(fromDate));
        setValue("toDate", new Date(toDate));
      });
    }
  }, [selectedStudent]);

  // Calculate total due
  useEffect(() => {
    if (selectedStudent?._id) {
      ApiCaller(
        `/get-due-amount/${selectedStudent?._id}?batchId=${
          selectedStudent?.batchId?._id
        }&fromDate=${watch("fromDate")}&toDate=${watch("toDate")}`,
        "get"
      ).then((response) => {
        if (response.success) {
          setValue("totalDue", response.data.dueFees);
          setTotalPayableData(response.data.dueBreakUp);
        }
      });
      setTotalPayableData(totalPayableData);
    }
  }, [
    selectedStudent?.batchId?.defaultFee,
    watch("fromDate"),
    watch("toDate"),
  ]);

  useEffect(() => {
    if (selectedStudent?._id) {
      ApiCaller(
        `/get-student-fees-details/${selectedStudent?._id}?batchId=${selectedStudent?.batchId?._id}`,
        "get"
      ).then((response) => {
        if (response.success) {
          setValue("studentFeesDetails", response?.data);
        }
      });
    }
  }, [selectedStudent]);

  useEffect(() => {
    ApiCaller(`/institute/${userData?.data?.instituteId}`, "get").then(
      (response) => {
        setInstitute(response?.data);
      }
    );
  }, [userData]);

  // Find total a
  const totalPayableAmount = [...additionalFees, ...totalPayableData].reduce(
    (acc, additionalFee) => acc + +additionalFee?.value,
    0
  );

  const handleAddUpdateStudent = () => {
    try {
      setLoading(true);
      const body = {
        ...getValues(),
        studentId: selectedStudent?._id,
        additionalFees: additionalFees,
        batchId: selectedStudent?.batchId?._id,
        totalDueBreakup: [...additionalFees, ...totalPayableData],
        receiptNumber: new Date().getTime(),
      };

      if (selectedStudent?._id) {
        // Submit fees for student
        ApiCaller(`/fees`, "post", body).then((response) => {
          if (response?.success) {
            triggerToaster("Fees is submitted successfully", "success");
            navigate(
              `/dashboard/feeReceipt?fee=${JSON.stringify(
                response?.data
              )}&student=${JSON.stringify(
                selectedStudent
              )}&institute=${JSON.stringify(institute)}`
            );
          } else {
            triggerToaster(response.data || "Invalid data", "error");
          }
          setLoading(false);
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsFeesConfirmation(false);
    }
  };

  const handleSubmitFees = () => {
    setIsFeesConfirmation(true);
  };

  const handleSelectedData = useCallback((data) => {
    setSelectedStudent(data);
  }, []);

  const getAdditionalFees = (additionalFees) => {
    setAdditionalFees(additionalFees);
  };

  // Set total due amount
  useEffect(() => {
    const totalDue = totalPayableAmount - +watch("discountAmount");
    setValue("totalDue", totalDue);
  }, [totalPayableAmount, watch("discountAmount")]);

  // Set total remaining balance
  useEffect(() => {
    const remainingAmount = +watch("totalDue") - +watch("paidAmount");
    setValue("remainingAmount", remainingAmount);
  }, [+watch("totalDue"), +watch("paidAmount")]);

  // Disable to date if selected date is less than from date and after batch end date
  const shouldDisableDate = (date) => {
    return (
      date !== null &&
      (date < watch("fromDate") ||
        date > new Date(selectedStudent?.batchId?.endDate))
    );
  };

  const getDueMonths = () => {
    const months = getMonthsBetweenDatesAndFees(
      watch("fromDate"),
      watch("toDate")
    );
    return months.map((month) => month?.key).join(", ") ?? "";
  };

  const studentFeesDetails = watch("studentFeesDetails");
  const couseDueFees = getCourseDueBalance(studentFeesDetails);
  return (
    <Stack
      spacing={4}
      direction={"row"}
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box
        as="form"
        onSubmit={handleSubmit(handleSubmitFees)}
        p={4}
        pt={0}
        sx={{
          backgroundColor: theme.palette.common.white,
          width: "100%",
        }}
      >
        <Typography variant="h3" mt={1} mb={1}>
          {`${studentId ? t("updateFeesDetails") : t("addFees")}`}
        </Typography>
        <Stack
          direction={mdUp ? "row" : "column"}
          justifyContent={"space-between"}
          gap={2}
          marginBottom={2}
        >
          <AutoCompleteSearch
            label={t("searchStudentByNameRollNo")}
            passSelectedValue={handleSelectedData}
            sx={{ width: mdUp && selectedStudent ? "60%" : "100%" }}
          />
          {watch("fromDate") && selectedStudent && (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Controller
                  control={control}
                  name="fromDate"
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      fullWidth={false}
                      label={`${t("from")} *`}
                      value={value}
                      onChange={onChange}
                      disabled={true}
                    />
                  )}
                />
                <Box ml={2}>
                  <Controller
                    control={control}
                    name="toDate"
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        fullWidth={false}
                        label={`${t("to")} *`}
                        value={value}
                        onChange={onChange}
                        shouldDisableDate={shouldDisableDate}
                      />
                    )}
                  />
                </Box>
              </Box>
              <Box sx={{ display: "flex", textAlign: "center" }}>
                <Typography
                  sx={{
                    fontSize: 12,
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {getDueMonths()}
                </Typography>
              </Box>
            </Box>
          )}
        </Stack>
        {selectedStudent && !couseDueFees && (
          <Ribbon
            message={`Student (${selectedStudent?.name}) course fee is already paid.`}
            isVisible={true}
          />
        )}
        {!!selectedStudent?._id && (
          <>
            <CollapseView
              summary={
                <Grid
                  container
                  sx={{ width: "100%", marginTop: 0 }}
                  direction={{ xs: "column", md: "row" }}
                  spacing={mdUp ? 0 : 1}
                >
                  <Grid item xs={3}>
                    <ViewOnly label={t("name")} value={selectedStudent?.name} />
                  </Grid>
                  <Grid item xs={3}>
                    <ViewOnly
                      label={t("father")}
                      value={selectedStudent?.fatherName}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <ViewOnly
                      label={t("rollNo")}
                      value={selectedStudent?.rollNo}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <ViewOnly
                      label={t("batch")}
                      value={selectedStudent?.batchId?.name}
                    />
                  </Grid>
                </Grid>
              }
              details={
                <Box>
                  <Grid
                    container
                    direction={{ xs: "column", md: "row" }}
                    spacing={2}
                    mb={2}
                    sx={{ width: "100%" }}
                  >
                    <Grid item xs={3}>
                      <ViewOnly
                        label={t("mobile")}
                        value={selectedStudent?.mobile}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <ViewOnly
                        label={t("email")}
                        value={selectedStudent?.email}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction={{ xs: "column", md: "row" }}
                    spacing={2}
                    mt={0}
                    sx={{ width: "100%" }}
                  >
                    <Grid item xs={3}>
                      <ViewOnly
                        label={t("courseFees")}
                        value={moneyFormat(studentFeesDetails?.totalCourseFees)}
                        breakUpData={studentFeesDetails?.totalAmountBreakUp}
                        isPopUpView={true}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <ViewOnly
                        label={t("receivedFeesHistory")}
                        value={moneyFormat(
                          studentFeesDetails?.totalPaidAmounts
                        )}
                        isModalView={true}
                        breakUpData={getFormattedPaidBreakUp(
                          studentFeesDetails?.fees
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <ViewOnly
                        label={t("receivedAdditionalFeesHistory")}
                        value={moneyFormat(
                          studentFeesDetails?.totalAdditionFees
                        )}
                        breakUpData={getFormattedAdditionalPaidBreakUp(
                          studentFeesDetails?.addtionalFeesBreakUp
                        )}
                        headers={[t("reason"), t("amount")]}
                        columnsKeys={["reason", "amount"]}
                        isModalView={true}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <ViewOnly
                        label={t("discountsHistory")}
                        value={moneyFormat(studentFeesDetails?.totalDiscounts)}
                        breakUpData={getFormattedPaidBreakUp(
                          studentFeesDetails?.fees
                        )}
                        isModalView={true}
                      />
                    </Grid>
                  </Grid>
                </Box>
              }
            />
            <Stack pb={2}>
              <Box
                sx={{
                  paddingX: 2,
                  paddingBottom: 2,
                  border: `1px solid ${theme.palette.grey[200]}`,
                }}
              >
                <Grid
                  container
                  spacing={2}
                  mt={1}
                  mb={2}
                  sx={{ width: "100%" }}
                  direction={{ xs: "column", md: "row" }}
                >
                  <Grid item xs={3}>
                    <ViewOnly
                      label={t("courseDueBalance")}
                      value={moneyFormat(couseDueFees)}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Controller
                      control={control}
                      name="totalDue"
                      defaultValue={0}
                      render={({ field: { _, value } }) => (
                        <TextField
                          sx={{ width: "100%" }}
                          label={`${t("totalDue")} *`}
                          size="small"
                          disabled={true}
                          value={value}
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                aria-label="information"
                                onMouseEnter={handlePopoverOpen}
                              >
                                <Info />
                              </IconButton>
                            ),
                          }}
                        />
                      )}
                    />
                    <Tooltip
                      open={open}
                      anchorEl={anchorEl}
                      handlePopoverClose={handlePopoverClose}
                    >
                      <Grid container spacing={1} width={250} p={2}>
                        {[...totalPayableData, ...additionalFees]?.map(
                          (row) => (
                            <>
                              <Grid item xs={8}>
                                {row?.key}
                              </Grid>
                              <Grid item xs={4}>
                                {moneyFormat(row?.value)}
                              </Grid>
                            </>
                          )
                        )}
                      </Grid>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      variant="text"
                      onClick={() => setIsFeesModalOpen(true)}
                    >
                      {t("addAdditionalFees")}
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Controller
                      control={control}
                      name="discountAmount"
                      defaultValue={0}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          sx={{ width: "100%" }}
                          label={`${t("discountAmount")} *`}
                          size="small"
                          type="number"
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  mt={1}
                  direction={{ xs: "column", md: "row" }}
                >
                  <Grid item xs={3}>
                    <Controller
                      control={control}
                      name="paidAmount"
                      defaultValue={"0"}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          sx={{ width: "100%" }}
                          label={t("payAmount")}
                          size="small"
                          type="number"
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                    <ErrorMsg msg={errors?.paidAmount?.message} />
                  </Grid>
                  <Grid item xs={3}>
                    <Controller
                      control={control}
                      name="remainingAmount"
                      defaultValue={"0"}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          sx={{ width: "100%" }}
                          label={`${t("remainingAmount")} *`}
                          size="small"
                          type="number"
                          onChange={onChange}
                          value={value ?? "0"}
                          disabled={true}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Controller
                      control={control}
                      name="paymentMode"
                      defaultValue={"Cash"}
                      render={({ field: { onChange, value } }) => (
                        <Dropdown
                          itemList={[
                            t("cash"),
                            t("upi"),
                            t("internetBanking"),
                            t("cheque"),
                          ]}
                          label={`${t("modeOfFees")} *`}
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Controller
                      control={control}
                      name="receivedFrom"
                      defaultValue={selectedStudent?.fatherName}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          sx={{ width: "100%" }}
                          label={`${t("receivedFrom")} *`}
                          size="small"
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                    <ErrorMsg msg={errors?.receivedFrom?.message} />
                  </Grid>
                </Grid>
              </Box>
            </Stack>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              disabled={!isDirty}
            >
              {`${studentId ? t("updateFees") : t("submitFees")}`}
            </LoadingButton>
          </>
        )}
        <FeesConfirmation
          open={isFeesConfirmation}
          onClose={() => setIsFeesConfirmation(false)}
          loading={loading}
          onSubmit={handleAddUpdateStudent}
          feesDetails={getValues()}
        />
      </Box>
      <AdditionalFeesModal
        isOpen={isFeesModalOpen}
        onClose={() => setIsFeesModalOpen(false)}
        passAdditionalFees={getAdditionalFees}
      />
    </Stack>
  );
}
