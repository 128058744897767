import { formatDate, moneyFormat } from "utils";

export const getFormattedSalaryBreakUp = (breakUpData) => {
  const formattedData = breakUpData?.map((item) => {
    return {
      createdAt: formatDate(item.createdAt),
      paidAmount: moneyFormat(item.paid),
      deduction: moneyFormat(item.deduction),
      bonus: moneyFormat(
        item?.additionalBonus?.reduce((sum, item) => sum + item.value, 0) || 0
      ),
    };
  });
  return formattedData;
};

export const getFormattedAdditionalPaidBreakUp = (addtionalFeesBreakUp) => {
  const formattedData = addtionalFeesBreakUp?.map((item) => {
    return {
      reason: item?.key,
      amount: moneyFormat(item.value),
    };
  });
  return formattedData;
};
