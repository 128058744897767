import { useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Box, TextField, Typography, useTheme } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";

import { ErrorMsg } from "components";
import { ApiCaller, validateError } from "utils";
import { useToaster } from "hooks";
import useResponsive from "hooks/useResponsive";
import { useTranslation } from "react-i18next";

// Validation Schema
const changePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().required("Current password is required"),
  newPassword: Yup.string()
    .required("Password is required")
    .test(
      "len",
      "Password should be greater than 5 characters",
      (val) => val.length >= 6
    ),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
});

// Main Component
const Setting = () => {
  const [loading, setLoading] = useState();
  const triggerToaster = useToaster();
  const navigate = useNavigate();
  const currentuser = useSelector((state) => state.currentUser);
  const { t } = useTranslation();

  const theme = useTheme();
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(changePasswordSchema),
  });

  const handleUpdate = () => {
    const body = {
      newPassword: getValues("newPassword"),
      email: currentuser?.data?.email,
      oldPassword: getValues("currentPassword"),
    };
    ApiCaller("/changePassword", "post", body).then((response) => {
      if (!!response?.success) {
        triggerToaster("Password is changed successfully", "success");
        navigate("/login", { replace: true });
      } else {
        triggerToaster(validateError(response?.data), "error");
      }
      setLoading(false);
    });
  };

  const mdUp = useResponsive("up", "md");

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(handleUpdate)}
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        backgroundColor: "#ffffff",
        border: `1px solid ${theme.palette.grey[100]}`,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: mdUp ? 3 : 2,
      }}
    >
      <Typography variant="h3" padding={3}>
        {t("changePassword")}
      </Typography>
      <Box
        sx={{
          width: mdUp ? "50%" : "90%",
          margin: mdUp ? 3 : 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box mb={2}>
          <Typography>{`${t("currentPassword")} *`}</Typography>
          <Controller
            control={control}
            name="currentPassword"
            render={({ field: { onChange, value } }) => (
              <TextField
                sx={{ width: "100%" }}
                placeholder={t("enterYourCurrentPassword")}
                onChange={onChange}
                value={value}
                type="password"
                error={!!errors.currentPassword}
              />
            )}
          />
          <ErrorMsg msg={errors?.currentPassword?.message} />
        </Box>
        <Box mb={2}>
          <Typography>{`${t("newPassword")} *`} </Typography>
          <Controller
            control={control}
            name="newPassword"
            render={({ field: { onChange, value } }) => (
              <TextField
                sx={{ width: "100%" }}
                placeholder={t("enterYourNewPassword")}
                onChange={onChange}
                value={value}
                type="password"
                error={!!errors.newPassword}
              />
            )}
          />
          <ErrorMsg msg={errors?.newPassword?.message} />
        </Box>
        <Box mb={2}>
          <Typography>{`${t("confirmPassword")} *`} </Typography>
          <Controller
            control={control}
            name="confirmPassword"
            render={({ field: { onChange, value } }) => (
              <TextField
                sx={{ width: "100%" }}
                placeholder={t("confirmYourNewPassword")}
                onChange={onChange}
                value={value}
                type="password"
                error={!!errors.confirmPassword}
              />
            )}
          />
          <ErrorMsg msg={errors?.confirmPassword?.message} />
        </Box>
        <LoadingButton
          variant="contained"
          size="large"
          type="submit"
          disabled={!isDirty}
          loading={loading}
        >
          {t("update")}
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default Setting;
