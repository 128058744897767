import { createSlice } from "@reduxjs/toolkit";

export const userSetting = createSlice({
  name: "userSetting",
  initialState: {
    value: false,
    data: {},
  },
  reducers: {
    setUserSettingAction: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setUserSettingAction } = userSetting.actions;

export default userSetting.reducer;
