import { Grid, Typography, useTheme } from "@mui/material";

const GridSkeleton = ({ label, value }) => {
    const theme = useTheme();
    return (
      <Grid container>
        <Grid
          item
          xs={6}
          sx={{ border: `1px solid ${theme.palette.grey[300]}`, padding: 1 }}
        >
          <Typography>{label}</Typography>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{ border: `1px solid ${theme.palette.grey[300]}`, padding: 1 }}
        >
          <Typography sx={{ fontWeight: "bold" }}>{value}</Typography>
        </Grid>
      </Grid>
    );
  };
  export default GridSkeleton;