import { useDispatch } from "react-redux";
import { setUserSettingAction } from "redux/userSetting";
import { ApiCaller } from "utils";

export const useSetting = () => {
  const dispatch = useDispatch();

  const triggerSetting = (userId) => {
    if (userId) {
      ApiCaller(`/get-setting?userId=${userId}`, "get").then((response) => {
        if (response.success) {
          dispatch(setUserSettingAction({ data: response?.data, value: true }));
        }
      });
    }
  };
  return triggerSetting;
};
