import { MenuItem, FormControl, Select, InputLabel } from "@mui/material";
import PropTypes from "prop-types";

const Dropdown = ({
  placeholder = "",
  itemList,
  displayKey,
  valueKey,
  value,
  label,
  sx,
  disabled,
  type = "flatList",
  ...rest
}) => {
  return (
    <FormControl fullWidth sx={{ padding: 0, ...sx }} disabled={disabled}>
      {label && (
        <InputLabel
          sx={{
            "&.MuiInputLabel-root": {
              top: -8,
            },
            "&.MuiInputLabel-shrink": {
              top: "unset",
            },
          }}
        >
          {label}
        </InputLabel>
      )}
      <Select
        value={value ?? ""}
        displayEmpty={false}
        size="small"
        id="demo-simple-select"
        label={label}
        renderValue={(val) => (val?.length ? val : placeholder)}
        MenuProps={{
          style: {
            maxHeight: 300,
          },
        }}
        sx={{
          "& .MuiSelect-outlined": {
            padding: 1,
          },
        }}
        {...rest}
      >
        {type === "flatList"
          ? !!itemList &&
            itemList.map((item) => (
              <MenuItem value={item} key={item}>
                {item}
              </MenuItem>
            ))
          : itemList.map((item) => (
              <MenuItem value={item[valueKey]} key={item[displayKey]}>
                {item[displayKey]}
              </MenuItem>
            ))}
      </Select>
    </FormControl>
  );
};

Dropdown.propTypes = {
  placeholder: PropTypes.string,
  listType: PropTypes.string,
};
export default Dropdown;
