// LoadingScreen.js
import React from "react";
import "./LoadingScreen.css";

const LoadingScreen = () => {
  return (
    <div id="loading-screen">
      <img
        src="/assets/app_logo.png"
        alt="app-logo"
        className="loading-image"
      />
      <div className="loading-text" style={{ marginTop: 5 }}>
        GurukulGuru
      </div>
      <div className="tagline-text" style={{ marginTop: 5 }}>
        Innovative Solutions for Schools
      </div>
    </div>
  );
};

export default LoadingScreen;
