import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  useTheme,
} from "@mui/material";
import {
  ListHead,
  ListToolbar,
  LoaderWrapper,
  Ribbon,
  TablePaginationActions,
} from "components";
import Iconify from "components/Iconify";
import { ApiCaller, handleCheckBox, validateData } from "utils";
import { useSelector } from "react-redux";
import { fDate } from "utils/formatTime";
import { useNavigate } from "react-router-dom";
import ConfirmationBox from "components/ConfirmationBox";
import { useDelay } from "hooks";
import { useTranslation } from "react-i18next";



export default function Expenses() {
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deletedToken, setDeletedToken] = useState("");
  const [isBulkDelete, setIsBulkDelete] = useState(false);
  const [deletedIds, setDeletedIds] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [expense, setExpense] = useState([]);
  const currentUser = useSelector((state) => state?.currentUser);
  const { t } = useTranslation();


  const TABLE_HEAD = [
    { id: "title", label: t("title"), alignRight: false },
    { id: "detail", label: t("detail"), alignRight: false },
    { id: "amount", label: t("amount"), alignRight: false },
    { id: "date", label: t("date"), alignRight: false },
    { id: "usedBy", label: t("usedBy"), alignRight: false },
    { id: "", label: t("action"), alignRight: false },
  ];

  const navigation = useNavigate();
  const theme = useTheme();
  const expenseList = expense?.data || [];

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = expenseList.map((n) => n.title);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleSearchChange = (event) => {
    setPage(0);
    setSearchValue(event.target.value);
  };

  const isNotFound = !expenseList.length;

  const searchedValue = useDelay(searchValue, 1000);
  useEffect(() => {
    setLoading(true);
    ApiCaller(
      `/expenses?search=${searchedValue}&pageNo=${
        page + 1
      }&size=${rowsPerPage}`,
      "get"
    ).then((response) => {
      if (response.success) {
        setExpense(response);
        setLoading(false);
      }
    });
  }, [deletedToken, page, rowsPerPage, searchedValue]);

  const handleAddBatch = () => {
    navigation("/dashboard/add-expense");
  };

  const handleDelete = () => {
    const selectedBatch = expenseList.filter(
      (expense) => selected.includes(expense.title) && expense._id
    );
    const ids = selectedBatch.map((expense) => expense._id);
    onDeleteBatch(ids);
  };

  const onDeleteBatch = (ids) => {
    setDeletedIds(ids);
    setIsBulkDelete(true);
    ApiCaller(`/expenses`, "delete", { ids }).then((response) => {
      if (response.success) {
        setTimeout(() => {
          setIsBulkDelete(false);
          setDeletedToken(new Date().getTime().toString());
          setSelected([]);
          setIsModalOpen(false);
        }, 2000);
      }
    });
  };

  const handleEdit = (id) => {
    navigation(`/dashboard/add-expense?id=${id}`);
  };

  const isLoading = (id) => deletedIds.includes(id) && isBulkDelete;

  return (
    <>
      <Helmet>
        <title> Expense | GurukulGuru.com </title>
      </Helmet>
      <Ribbon message={t("pleaseAddInstituteFirstToAddExpenses")} />

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
        >
          <Typography variant="h4" gutterBottom>
            {t("expenses")}
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={handleAddBatch}
            disabled={!currentUser?.data?.instituteId}
          >
            {t("addNewExpense")}
          </Button>
        </Stack>

        <Card>
          <ListToolbar
            numSelected={selected.length}
            value={searchValue}
            onChange={handleSearchChange}
            handleDelete={() => {
              setIsModalOpen(true);
            }}
            isBulkDelete={isBulkDelete}
            placeholder={t("searchExpense")}
          >
            {/*
              Other filter would be here
            */}
          </ListToolbar>

          <TableContainer
            sx={{ overflow: "auto" }}
            style={{
              boxShadow: `0 8px 16px 0 rgba(145, 158, 171, 0.16)`,
              backgroundColor: "rgba(249, 250, 251, 0.8)",
            }}
          >
            <Table>
              <ListHead
                headLabel={TABLE_HEAD}
                rowCount={expenseList.length}
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {expenseList.map((row) => {
                  const { title, detail, amount, createdAt, usedByName, _id } =
                    row;
                  const selectedUser = selected.indexOf(title) !== -1;

                  return (
                    <TableRow
                      hover
                      key={_id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={selectedUser}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedUser}
                          onChange={(event) =>
                            handleCheckBox(event, title, selected, setSelected)
                          }
                        />
                      </TableCell>
                      <TableCell align="left">{validateData(title)}</TableCell>
                      <TableCell align="left">{validateData(detail)}</TableCell>
                      <TableCell align="left">{validateData(amount)}</TableCell>
                      <TableCell align="left">
                        {validateData(fDate(createdAt))}
                      </TableCell>
                      <TableCell align="left">
                        {validateData(usedByName)}
                      </TableCell>
                      <TableCell>
                        <Box sx={{ width: 70 }}>
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={() => handleEdit(row?._id)}
                            sx={{ padding: 0.6 }}
                          >
                            <Iconify icon={"eva:edit-fill"} />
                          </IconButton>
                          {!isLoading(row?._id) ? (
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={() => {
                                setIsModalOpen(true);
                                setSelected([row?.title]);
                              }}
                              sx={{ padding: 0.6 }}
                            >
                              <Iconify
                                icon={"eva:trash-2-outline"}
                                sx={{ color: theme.palette.error.dark }}
                              />
                            </IconButton>
                          ) : (
                            <IconButton size="large" color="inherit">
                              <Iconify icon="eos-icons:bubble-loading" />
                            </IconButton>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              {isNotFound && !loading && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          No result found
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              <LoaderWrapper loading={loading} />
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={rowsPerPage}
            component="div"
            count={expense.total ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Card>
        <ConfirmationBox
          handleClose={() => setIsModalOpen(false)}
          handleConfirm={() => {
            handleDelete();
          }}
          loading={isBulkDelete}
          open={isModalOpen}
        />
      </Container>
    </>
  );
}
