import { Helmet } from "react-helmet-async";
import { Container, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useTheme } from "@mui/material";

import { ImageView, StyledRoot, StyledContent } from "components";
import VerifyOtpForm from "./VerifyOtpForm";

export default function VerifyOtp() {
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();

  return (
    <>
      <Helmet>
        <title> Verify Otp | GurukulGuru.com </title>
      </Helmet>
      <StyledRoot>
        <ImageView title={"Hi, Welcome"} />
        <Container sx={{ padding: 0 }}>
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              Verify your Otp which has been sent to your email
              <span style={{ color: theme.palette.primary.main }}>
                {" "}
                {searchParams.get("email")}
              </span>
            </Typography>
            <VerifyOtpForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
