import * as Yup from "yup";

const loginSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  mobile: Yup.number()
    .required("Mobile is required")
    .typeError("Enter a valid mobile number")
    .test(
      "len",
      "Enter a valid mobile number",
      (val) => val.toString().length >= 10
    ),
  email: Yup.string()
    .email("Please enter valid email")
    .required("Email is required")
    .test("Validate Email", "Please enter valid email", (value) => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(value).toLowerCase());
    }),
  password: Yup.string()
    .required("Password is required")
    .test(
      "len",
      "Password should be greater than 5 letter",
      (val) => val.length >= 6
    ),
});

export { loginSchema };
