import {
  Box,
  Button,
  Checkbox,
  LinearProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import {
  CustomButton,
  Dropdown,
  LoaderWrapper,
  TablePaginationActions,
  Upload,
} from "components";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import {
  ApiCaller,
  capitalizeFirstLetter,
  changeDateFormat,
  downloadCsv,
  formatDate,
  getCsvDataIntoArray,
  validateError,
} from "utils";
import { useToaster } from "hooks";
import ConfirmationBox from "components/ConfirmationBox";
import useResponsive from "hooks/useResponsive";

const StudentBulkUpload = () => {
  const [selectedBatch, setSelectedBatch] = useState("");
  const [isBulkDelete, setIsBulkDelete] = useState(false);
  const [isBulkUpload, setIsBulkUpload] = useState(false);
  const [deletedToken, setDeletedToken] = useState("");
  const [students, setStudents] = useState({ data: [] });
  const [loading, setLoading] = useState(false);
  const [isBulkSave, setIsBulkSave] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const masterData = useSelector((state) => state?.masterData);
  const batchList = masterData?.data?.batches || [];
  const triggerToaster = useToaster();
  const mdUp = useResponsive("up", "md");

  useEffect(() => {
    setLoading(true);
    ApiCaller(
      `/bulk-uploaded-students?pageNo=${page + 1}&size=${rowsPerPage}`,
      "get"
    ).then((response) => {
      if (response?.success) {
        setStudents(response);
      }
      setLoading(false);
    });
  }, [rowsPerPage, page, deletedToken]);

  const onDropdownChange = (event) => {
    setSelectedBatch(event?.target?.value);
  };

  const handleFileUpload = (fileContent) => {
    setIsBulkUpload(true);
    const csvDataIntoArray = getCsvDataIntoArray(fileContent);
    let formattedData = [];

    if (csvDataIntoArray?.length) {
      let hasError = false;

      formattedData = csvDataIntoArray?.map((item, index) => {
        const formattedAddmissionDate = changeDateFormat(
          item?.["ADMISSION DATE"]
        );
        const formattedDob = changeDateFormat(item?.["DOB"]);

        const data = {
          name: item?.["NAME"],
          fatherName: item?.["FATHER NAME"],
          motherName: item?.["MOTHER NAME"],
          dob: formattedDob,
          admissionDate: formattedAddmissionDate,
          gender: capitalizeFirstLetter(item?.["GENDER"]),
          email: item?.["EMAIL"],
          mobile: item?.["MOBILE"],
        };

        for (const [key, value] of Object.entries(data)) {
          if (!value) {
            triggerToaster(
              `Field ${key} is empty in row ${index + 1}`,
              "error"
            );
            hasError = true;
            setIsBulkUpload(false);
          }
        }

        return data;
      });

      if (hasError) return;

      ApiCaller("/students-bulk-upload", "post", formattedData).then(
        (response) => {
          if (response?.success) {
            triggerToaster("Students are added successfully", "success");
            setDeletedToken(new Date().getTime().toString());
          } else {
            triggerToaster(validateError(response?.data), "error");
          }
          setIsBulkUpload(false);
        }
      );
    }
  };

  const { t } = useTranslation();
  const isNotFound = !students?.data?.length;

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSelectRow = (id) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSelectAll = () => {
    if (isSelectAll) {
      setSelectedRows([]);
    } else {
      const allIds = students?.data?.map((student) => student?._id);
      setSelectedRows(allIds);
    }
    setIsSelectAll(!isSelectAll);
  };

  const handleSave = () => {
    setIsBulkSave(true);
    if (!selectedBatch || selectedRows.length === 0) {
      triggerToaster("Please select a batch to save.", "error");
      setIsBulkSave(false);
      return;
    }

    const selecteBatchData = batchList?.find(
      (batch) => batch?.name === selectedBatch
    );

    const selectedStudentData = students?.data
      .filter((student) => selectedRows.includes(student?._id))
      .map((student) => ({
        _id: student._id,
        batchId: selecteBatchData?._id,
        batchNo: selecteBatchData?.batchNo,
      }));

    ApiCaller(`/activate-students`, "put", {
      students: selectedStudentData,
    }).then((response) => {
      if (response?.success) {
        setDeletedToken(new Date().getTime().toString());
        setSelectedRows([]);
        triggerToaster("Student is updated successfully", "success");
        setIsSelectAll(false);
        setSelectedBatch("");
      } else {
        triggerToaster(response.data || "Invalid data", "error");
      }
      setIsBulkSave(false);
    });
  };

  const handleDelete = () => {
    setIsBulkDelete(true);
    try {
      ApiCaller(`/delete-students`, "delete", { ids: selectedRows }).then(
        (response) => {
          if (response.success) {
            triggerToaster("Student deleted successfully", "success");
            setTimeout(() => {
              setIsBulkDelete(false);
              setDeletedToken(new Date().getTime().toString());
              setSelectedRows([]);
              setIsModalOpen(false);
              setIsSelectAll(false);
              setSelectedBatch("");
            }, 1000);
          }
        }
      );
    } catch (error) {
      triggerToaster("Something went wrong!", "error");
      setIsBulkDelete(false);
    }
  };

  return (
    <Box sx={{ backgroundColor: "#ffffff" }}>
      {isBulkUpload && <LinearProgress style={{ marginBottom: 10 }} />}

      <Upload
        padding={3}
        onFileUpload={handleFileUpload}
        acceptedFileType="csv"
      >
        <Box
          style={{
            display: "flex",
            flexDirection: mdUp ? "row" : "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" color="textSecondary">
            Header names should be the same as in the provided sample CSV file.
          </Typography>
          <Button
            onClick={() => downloadCsv("student_bulk_upload_sample_file")}
          >
            Click to download the student bulk upload sample file.
          </Button>
        </Box>
      </Upload>

      <Stack
        direction={mdUp ? "row" : "column"}
        style={{ justifyContent: "space-between" }}
        mt={4}
        mb={1}
      >
        <Typography variant="h6" mt={1} mb={1}>
          Uploaded data
        </Typography>
        <Box>
          <Dropdown
            itemList={batchList}
            label={t("selectBatch")}
            onChange={onDropdownChange}
            value={selectedBatch}
            valueKey={"name"}
            displayKey={"name"}
            size="small"
            type="arrayOfObjects"
            sx={{ maxWidth: 250, width: 140 }}
          />
          <CustomButton
            title={"Save"}
            size="medium"
            loading={isBulkSave}
            style={{ marginLeft: 20 }}
            disabled={selectedRows.length === 0}
            onClick={handleSave}
          />
          <CustomButton
            title={"Delete"}
            size="medium"
            color="error"
            style={{ marginLeft: 20 }}
            disabled={selectedRows.length === 0}
            onClick={() => setIsModalOpen(true)}
          />
        </Box>
      </Stack>

      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox checked={isSelectAll} onChange={handleSelectAll} />
              </TableCell>
              <TableCell>{t("name")}</TableCell>
              <TableCell>{t("father")}</TableCell>
              <TableCell>{t("mother")}</TableCell>
              <TableCell>{t("dob")}</TableCell>
              <TableCell>{t("gender")}</TableCell>
              <TableCell>{t("mobile")}</TableCell>
              <TableCell>{t("email")}</TableCell>
              <TableCell>{t("admissionDate")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {students?.data?.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedRows.includes(row?._id)}
                    onChange={() => handleSelectRow(row?._id)}
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell>{row.fatherName}</TableCell>
                <TableCell>{row.motherName}</TableCell>
                <TableCell>{formatDate(row.dob)}</TableCell>
                <TableCell>{row.gender}</TableCell>
                <TableCell>{row.mobile}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{formatDate(row.admissionDate)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          {isNotFound && !loading && (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                  <Paper sx={{ textAlign: "center" }}>
                    <Typography variant="h6" paragraph>
                      No result found
                    </Typography>
                  </Paper>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          <LoaderWrapper loading={loading || isBulkDelete || isBulkSave} />
        </Table>
        <TablePagination
          rowsPerPageOptions={rowsPerPage}
          component="div"
          count={students.total ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </TableContainer>
      <ConfirmationBox
        handleClose={() => setIsModalOpen(false)}
        handleConfirm={() => {
          handleDelete();
        }}
        loading={isBulkDelete}
        open={isModalOpen}
      />
    </Box>
  );
};

export default StudentBulkUpload;
