import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import theme from "theme";

const Field = styled(TextField)(() => ({
  "& label.Mui-focused": {
    color: theme.palette.secondary.main,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "yellow",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.secondary.main,
    },
  },
}));

const InputField = ({
  defaultValue,
  value,
  color = "secondary",
  id = "outlined-required",
  required = false,
  onChange,
  style,
  label,
  type,
}) => {
  return (
    <Field
      required={required}
      color="secondary"
      InputProps={{ inputProps: { style: { color: "#fff" } } }}
      id="outlined-required"
      label={label}
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      style={style}
      type={type}
    />
  );
};

export default InputField;
