import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { styled, alpha } from "@mui/material/styles";
import { Box, Link, Drawer, Typography, Avatar, useTheme } from "@mui/material";

import account from "_mock/account";
import useResponsive from "hooks/useResponsive";
import Logo from "components/Logo";
import Scrollbar from "components/Scrollbar";
import NavSection from "components/NavSection";

import useConfig from "./useConfig";
import { useSelector } from "react-redux";
import { getBgColor } from "utils";

const NAV_WIDTH = 250;

const StyledAccount = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1, 1.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  // backgroundColor: alpha(theme.palette.grey[500], 0.12),
  backgroundImage: getBgColor(theme.palette.primary.main, 0.8, 0.1),
}));

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();
  const userData = useSelector((state) => state?.currentUser);
  const theme = useTheme();
  const navConfig = useConfig();

  const isDesktop = useResponsive("up", "lg");

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 2.5, display: "inline-flex" }}>
        <Logo />
      </Box>

      <Box sx={{ mb: 1, mx: 1 }}>
        <Link underline="none">
          <StyledAccount>
            <Avatar
              src={
                userData?.data?.profileUrl
                  ? userData?.data?.profileUrl
                  : account.photoURL
              }
              alt="photoURL"
            />

            <Box sx={{ ml: 2, overflowWrap: "break-word", inlineSize: 155 }}>
              <Typography
                variant="subtitle1"
                sx={{ color: "text.white", fontWeight: "bold" }}
              >
                {userData?.data?.name ?? "User name"}
              </Typography>
              {/* <Typography
                variant="body2"
                sx={{ color: "text.secondary", fontSize: 12 }}
              >
                {userData?.data?.email ?? ""}
              </Typography> */}
            </Box>
          </StyledAccount>
        </Link>
      </Box>

      <NavSection data={navConfig} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              // bgcolor: "background.default",
              borderRightStyle: "dashed",
              backgroundColor: theme.palette.common.white,
              // boxShadow: `1px 2px 9px ${theme.palette.grey[500]}`,
              // borderRadius: 3,
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
