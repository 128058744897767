import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Router from "./routes";
import ThemeProvider from "./theme";
import { StyledChart } from "components/Chart";
import ScrollToTop from "components/ScrollToTop";
import React, { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { appStore, persistedStore } from "./redux";
import "./index.css";
import { CustomToaster } from "components";
import LoadingScreen from "pages/LoadingScreen";
import "./utils/i18n"; // Make sure this is correctly importing i18n

const queryClient = new QueryClient();

export default function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Simulate content loading with a timeout
    setTimeout(() => {
      setLoading(false);
    }, 1500); // Adjust the timeout as needed
  }, []);

  return loading ? (
    <LoadingScreen />
  ) : (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <Provider store={appStore}>
          <PersistGate loading={null} persistor={persistedStore}>
            <BrowserRouter>
              <ThemeProvider>
                <ScrollToTop />
                <StyledChart />
                <Router />
                <CustomToaster />
              </ThemeProvider>
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </QueryClientProvider>
    </HelmetProvider>
  );
}
