import * as Yup from "yup";

const addFeesSchema = Yup.object().shape({
  paidAmount: Yup.number()
    .typeError("Amount must be a number")
    .positive("Amount must be positive")
    .required("Amount is required"),
  receivedFrom: Yup.string()
    .required("Received from is required")
    .test("len", "Name should be atleast 5 letter", (val) => val?.length >= 5),
});

export { addFeesSchema };
