import { Snackbar, Alert } from "@mui/material";
import useResponsive from "hooks/useResponsive";
import { useDispatch, useSelector } from "react-redux";
import { toasterAction } from "redux/snackbar";

const Toaster = () => {
  const dispatch = useDispatch();
  const openToaster = useSelector((state) => state.snackbar);
  const mdUp = useResponsive("up", "md");

  const handleCloseToaster = () => {
    dispatch(toasterAction({ value: false }));
  };
  return (
    <Snackbar
      open={openToaster.value}
      autoHideDuration={3000}
      onClose={handleCloseToaster}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      sx={{ width: mdUp ? "30%" : "100%" }}
    >
      <Alert
        onClose={handleCloseToaster}
        severity={openToaster.variant}
        sx={{ width: "100%" }}
      >
        {openToaster.message}
      </Alert>
    </Snackbar>
  );
};

export default Toaster;
