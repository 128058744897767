import React from "react";
import { Typography, } from "@mui/material";
import { CustomeModal, GridSkeleton } from "components";
import useResponsive from "hooks/useResponsive";
import { LoadingButton } from "@mui/lab";
import { moneyFormat } from "utils";
import { useTranslation } from "react-i18next";


const FeesConfirmation = ({
  open,
  onClose,
  loading,
  onSubmit,
  feesDetails,
}) => {
  const mdUp = useResponsive("up", "md");
  const { t } = useTranslation();
  const { totalDue, paidAmount, discountAmount, remainingAmount } = feesDetails;
  return (
    <CustomeModal
      open={open}
      onClose={onClose}
      style={{ width: mdUp ? "40%" : "80%" }}
    >
      <Typography sx={{ fontWeight: "bold", fontSize: 16, marginBottom: 2 }}>
        {t("feesConfirmation")}
      </Typography>
      <Typography sx={{ fontSize: 16, marginBottom: 1 }}>
        {t("doYouWantToSubmitFees")}
      </Typography>
      <GridSkeleton label={t("totalDue")} value={moneyFormat(+totalDue || 0)} />
      <GridSkeleton
        label={t("totalPaid")}
        value={moneyFormat(+paidAmount || 0)}
      />
      <GridSkeleton
        label={t("discount")}
        value={moneyFormat(+discountAmount || 0)}
      />
      <GridSkeleton
        label={t("remainingBalance")}
        value={moneyFormat(+remainingAmount || 0)}
      />
      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={loading}
        sx={{ marginTop: 2 }}
        onClick={onSubmit}
      >
        {t("submit")}
      </LoadingButton>
    </CustomeModal>
  );
};

export default FeesConfirmation;
