import React from "react";
import Typewriter from "typewriter-effect";

function TypeWriter() {
  return (
    <div className="typeWriterDiv">
      <Typewriter
        cursorClassName="typewritterText"
        options={{
          strings: [
            "Artificial intelligence",
            "Art, Creativity",
            "Block chain, crypto, NFT",
            "Books, Magzine",
            "Documentaries",
            "Farming",
            "Fashion",
            "Fitness, Health",
            "Food, Cooking",
            "History",
            "Motivation",
            "News",
            "Science & Space",
            "Spirituality",
            "Sports",
            "Startup, Business, Career",
            "Stock Market",
            "Talk show, Podcast, Interviews",
            "Travel and exploration",
            "Entertainment",
          ],
          autoStart: true,
          loop: true,
          deleteSpeed: 50,
        }}
      />
    </div>
  );
}

export default TypeWriter;
